<script setup lang="ts">
import type { SerializedEditorState } from "lexical";
import type { Moment } from "moment";
import moment from "moment";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { createNewTaskNotification } from "~/components/notifications/notification";
import TitleEditor from "~/components/text/TitleEditor.vue";
import { convertToTask } from "~/components/text/utils";
import { InfoIcon } from "~/icons";
import { CommandId, EditorMode, SubtaskDisplayMode } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeEmptyLexicalState } from "~/utils/common";

const NLP_DESCRIPTION =
  "Use natural language to quickly fill out task properties. For example, type a teammate's name to assign them. To learn more, look in Help and resources > Shortcuts (or type ?) and look for Quick add.";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const titleEditor = ref<InstanceType<typeof TitleEditor> | null>(null);

const title = ref<SerializedEditorState>(appStore.cachedNewTaskInput ?? makeEmptyLexicalState());
const isTitleEmpty = computed(() => !convertToTask(title.value).title);
const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);

const focus = () => titleEditor.value?.focus();

const onChange = (newTitle: SerializedEditorState) => {
  title.value = newTitle;
  appStore.cachedNewTaskInput = newTitle;
};

const onPasteMultilineText = (text: string) => {
  const visualization = appStore.getActiveVisualization();
  const currentDartboard = appStore.currentDartboardOrDefault;
  if (!currentDartboard) {
    return;
  }

  const partialTask = convertToTask(title.value);
  const tasks = visualization.getAll();
  const topTaskOrder = tasks.length === 0 ? "" : tasks[0].order;

  actions.task.createTasksFromPaste(text, currentDartboard.duid, "", topTaskOrder, partialTask, { createAll: true });
};

const onSave = async () => {
  const partialTask = convertToTask(title.value);
  if (!partialTask.title) {
    focus();
    return;
  }

  const savedTask = await actions.visualization.addTask(partialTask.title, partialTask);
  focus();
  nextTick(() => {
    nextTick(() => {
      titleEditor.value?.clear();
      focus();
    });
  });

  if (!savedTask || savedTask.dartboardDuid === appStore.currentPage?.duid) {
    return;
  }

  const dartboard = dataStore.getDartboardByDuid(savedTask.dartboardDuid);
  if (!dartboard) {
    return;
  }

  createNewTaskNotification(
    [savedTask],
    dartboard,
    dataStore.getSpaceByDuid,
    actions.visualization.navigateToTask,
    router
  );
};

// TODO remove this hack when the grid doesn't randomly unmount the headers
let lastBlur: Moment | undefined;
const onBlur = () => {
  lastBlur = moment();
};

onMounted(() => {
  appStore.newTask = {
    focus,
  };
});

onUnmounted(() => {
  if (appStore.newTask?.focus === focus) {
    appStore.newTask = null;
  }
  if (lastBlur && lastBlur.isAfter(moment().subtract(100, "millisecond"))) {
    appStore.newTask?.focus();
  }
});
</script>

<template>
  <div
    id="new-task"
    class="relative flex size-full cursor-text items-center overflow-hidden pr-7 focus-within:bg-lt hover:bg-lt"
    :class="[notFlat ? 'ml-[35px]' : 'ml-[15px]', pageStore.hasTouch && (notFlat ? 'ml-[67px]' : 'ml-[47px]')]"
    @click.stop="focus"
    @keydown.enter.stop="focus"
    @keydown.arrow-up.stop
    @keydown.arrow-down.stop
    @keydown.arrow-left.stop
    @keydown.arrow-right.stop>
    <TitleEditor
      ref="titleEditor"
      :initial-title="title"
      :editor-mode="EditorMode.LIST"
      @change="onChange"
      @blur="onBlur"
      @enter="onSave"
      @paste-multiline-text="onPasteMultilineText" />
    <Tooltip v-if="!isTitleEmpty" :command-id="CommandId.SAVE_TASK" class="ml-1.5">
      <button
        type="button"
        aria-label="Save task"
        class="flex flex-none items-center justify-center rounded border text-sm border-oncolor focus-ring-none icon-md hover:bg-opposite/10 dark:hover:bg-white/10"
        @click.stop="onSave"
        @keydown.enter="onSave">
        <span class="select-none text-sm text-primary-base icon-sm">↵</span>
      </button>
    </Tooltip>
    <div class="flex-1" @click.stop="focus()" @keydown.enter.stop="focus()" />
    <Tooltip :text="NLP_DESCRIPTION" info class="absolute right-0 mr-2">
      <InfoIcon class="cursor-help rounded-full outline-none text-vlt icon-xs" />
    </Tooltip>
  </div>
</template>
