<script setup lang="ts">
import moment from "moment";
import { onUnmounted, ref } from "vue";

import { DartAnimation } from "~/icons";
import { usePageStore } from "~/stores";

const props = defineProps<{
  showTiming: boolean;
  transparentBg: boolean;
}>();

const pageStore = usePageStore();

const start = moment(pageStore.loadingStartDt);
const timingMsg = ref<string>("");
let interval: ReturnType<typeof setInterval> | undefined;
if (props.showTiming) {
  interval = setInterval(() => {
    const diffMs = moment().diff(start);
    timingMsg.value = `${Math.floor(diffMs / 1000)}.${(diffMs % 1000).toString().padStart(3, "0")}`;
  }, 20);
}

onUnmounted(() => clearInterval(interval));

const animationFinished = ref(false);
setTimeout(() => {
  animationFinished.value = true;
}, 2000);
</script>

<template>
  <div class="flex size-full flex-col items-center justify-evenly app-drag bg-std">
    <div class="flex flex-col items-center">
      <div class="relative flex flex-col items-center">
        <DartAnimation :class="{ 'animate-pulse': animationFinished }" class="size-96" />
        <span v-if="showTiming" class="pointer-events-none absolute bottom-16 m-4 select-none text-lt">
          <span class="tabular-nums">{{ timingMsg }}</span>
          s
        </span>
      </div>
    </div>
    <div />
  </div>
</template>
