<script setup lang="ts">
import { computed } from "vue";

import actions from "~/actions";
import Avatar from "~/components/dumb/Avatar.vue";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import UserDropdownItem from "~/components/dumb/UserDropdownItem.vue";
import { UNSET_USER_LABEL } from "~/components/visualization/constants";
import { colorsByTheme } from "~/constants/style";
import { UsersInviteIcon } from "~/icons";
import { EditorMode, Placement, UserRole } from "~/shared/enums";
import type { User } from "~/shared/types";
import { useDataStore, usePageStore, useTenantStore } from "~/stores";
import { validateEmail } from "~/utils/common";

const props = defineProps<{
  taskDuid: string;
  value: string[];
}>();

const emit = defineEmits<{
  change: [value: string[]];
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const task = computed(() => dataStore.getTaskByDuid(props.taskDuid));

const onAdd = (duid: string) => {
  dataStore.addSubscribers([props.taskDuid], [duid]);
  emit("change", [...props.value, duid]);
};

const onRemove = (duid: string) => {
  dataStore.removeSubscribers([props.taskDuid], [duid]);
  emit(
    "change",
    props.value.filter((e) => e !== duid)
  );
};

const inviteAndAdd = async (email: string) => {
  const invitedEmails = await actions.auth.inviteUsers(tenantStore.isPremium ? UserRole.MEMBER : UserRole.ADMIN, [
    email,
  ]);
  if (!invitedEmails.includes(email)) {
    return;
  }

  const user = dataStore.getUserByEmail(email);
  if (!user) {
    return;
  }

  onAdd(user.duid);
};

const generateItemForUser = (user: User) => {
  const value = user?.duid;
  const label = user.name || user.email;

  return {
    value,
    label,
    adtlSearchTerms: [user.email],
    selected: props.value.includes(value),
    component: UserDropdownItem,
    componentArgs: {
      label,
      replaceEnabled: false,
      icon: Avatar,
      iconArgs: {
        abrev: user?.abrev ?? "",
        circle: true,
        colorHex: user?.colorHex,
        hover: false,
        imageUrl: user?.imageUrl,
        imgBorder: true,
        class: "icon-lg",
      },
    },
  };
};

const items = computed(() => {
  if (!task.value) {
    return [];
  }
  return dataStore.getRelevantUsersByTasks([task.value]).map((user) => generateItemForUser(user));
});
</script>

<template>
  <MultiselectDropdownMenu
    :items="items"
    :placement="Placement.TOP_RIGHT"
    :disabled="task?.inTrash"
    placeholder="Subscribe..."
    :new-entry-icon="UsersInviteIcon"
    :new-entry-icon-args="{ class: 'icon-lg mr-0.5' }"
    :make-new-entry-text="(search) => `Invite ${search} by email`"
    allow-members-to-make-new-entries
    :validate="validateEmail"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    @add="onAdd"
    @create="inviteAndAdd"
    @remove="onRemove">
    <div
      class="flex size-full max-w-full items-center justify-center whitespace-nowrap rounded px-1 py-0.5 hover:bg-md"
      :class="task?.inTrash && 'pointer-events-none'">
      <span class="mr-2 select-none text-sm">Subscribers</span>
      <AvatarGroup :duids="value" :editor-mode="EditorMode.DETAIL" :limit="5" :unset-label="UNSET_USER_LABEL" />
    </div>
  </MultiselectDropdownMenu>
</template>
