<script setup lang="ts">
import { computed } from "vue";

import PageIcon from "~/components/dumb/PageIcon.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DEFAULT_DARTBOARD_LABEL } from "~/constants/dartboard";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import { DartboardIcon, SlashIcon, SwapIcon, XIcon } from "~/icons";
import { CommandId, EditorMode, IconSize } from "~/shared/enums";
import { useDataStore, usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";
import { getPageDisplayName } from "~/utils/common";

const props = defineProps<{
  selectedDartboardDuid?: string;
  editorMode: EditorMode;
  colorHex?: string;
  selected?: boolean;
  replaceEnabled?: boolean;
}>();

const emit = defineEmits<{
  remove: [];
  replace: [];
}>();

const dataStore = useDataStore();
const pageStore = usePageStore();

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isFilterMode = computed(() => props.editorMode === EditorMode.FILTER);

const page = computed(() =>
  props.selectedDartboardDuid ? dataStore.getDartboardByDuid(props.selectedDartboardDuid) : undefined
);
const isDefault = computed(() => isFormMode.value && !!props.selectedDartboardDuid && !page.value);
const title = computed(() =>
  isDefault.value ? DEFAULT_DARTBOARD_LABEL : getPageDisplayName(page.value, dataStore.getSpaceByDuid)
);

const space = computed(() => (page.value?.spaceDuid ? dataStore.getSpaceByDuid(page.value.spaceDuid) : undefined));
const spaceAbrev = computed(() => space.value?.abrev);

const colorSet = computed(() => getChipColorsHex(props.colorHex ?? DEFAULT_CHIP_COLOR, pageStore.theme));
</script>

<template>
  <div class="flex w-full justify-between gap-2">
    <component :is="isBoardMode ? Tooltip : Template" :text="isBoardMode ? 'Dartboard' : undefined" class="truncate">
      <div
        class="flex w-full select-none items-center gap-1 truncate rounded text-md"
        :style="isFilterMode && { color: colorSet.fore, backgroundColor: colorSet.back, borderColor: colorSet.border }"
        :class="{
          'h-5 !gap-0.5 px-1 py-0 text-xs border-oncolor': isBoardMode,
          '@container': isListMode,
          'py-0.5': !isListMode,
          'max-w-xs truncate px-1 border-oncolor': isChipMode,
          'pl-1.5 pr-7 text-sm': isFormMode,
          'w-full px-2 py-[3px] text-left': isTaskDetailMode,
          'border border-transparent': !isChipMode || !isBoardMode,
          'h-5 w-max max-w-48 !gap-0.5 px-1 text-xs': isFilterMode,
          'hover:bg-lt': isTaskDetailMode,
          'hover:bg-opposite/10': isChipMode,
        }">
        <PageIcon v-if="space" :page="space" :size="isBoardMode || isFilterMode ? IconSize.S : IconSize.M" />
        <span :class="isListMode && 'hidden @[150px]:flex'">{{ spaceAbrev }}</span>
        <SlashIcon :class="[isBoardMode || isFilterMode ? 'icon-xs' : 'icon-sm']" />
        <component
          :is="isDefault ? DartboardIcon : PageIcon"
          :page="page"
          class="shrink-0"
          :size="isBoardMode || isFilterMode ? IconSize.S : IconSize.M" />
        <span class="truncate">{{ title }}</span>
        <div
          v-if="selected"
          class="flex cursor-pointer items-center justify-center rounded hover:bg-gray-400/40 dark:hover:bg-zinc-600/30"
          @click.stop="() => emit('remove')"
          @keydown.enter.stop="() => emit('remove')">
          <XIcon class="icon-xs" />
        </div>
      </div>
    </component>
    <Tooltip v-if="replaceEnabled && !selected" :command-id="CommandId.SWAP">
      <div
        class="flex items-center justify-center rounded icon-md hover:bg-hvy"
        @click.stop="emit('replace')"
        @keydown.enter.stop="emit('replace')">
        <SwapIcon class="cursor-pointer text-md icon-xs" />
      </div>
    </Tooltip>
  </div>
</template>
