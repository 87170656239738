import { defineStore } from "pinia";

import type {
  Attachment,
  Brainstorm,
  Comment,
  Dartboard,
  Dashboard,
  Doc,
  Folder,
  Form,
  FormField,
  Layout,
  Notification,
  Option,
  Property,
  RelationshipKind,
  Space,
  Status,
  Task,
  TaskDocRelationship,
  TaskKind,
  User,
  View,
  Webhook,
} from "~/shared/types";

import type { PiniaStore, ReachableStore } from "../shared";
import * as attachment from "./attachment";
import * as brainstorm from "./brainstorm";
import * as comment from "./comment";
import * as dartboard from "./dartboard";
import * as doc from "./doc";
import * as form from "./form";
import * as notification from "./notification";
import * as option from "./option";
import * as property from "./property";
import * as relationship from "./relationship";
import * as space from "./space";
import * as status from "./status";
import * as task from "./task";
import * as taskKind from "./taskKind";
import * as user from "./user";
import * as view from "./view";
import * as webhook from "./webhook";

type State = {
  _duidsToAttachments: Map<string, Attachment>;
  _duidsToBrainstorms: Map<string, Brainstorm>;
  _duidsToComments: Map<string, Comment>;
  _duidsToDartboards: Map<string, Dartboard>;
  _duidsToDashboards: Map<string, Dashboard>;
  _duidsToDocs: Map<string, Doc>;
  _duidsToDocsIncremental: Map<string, Doc>;
  _duidsToFolders: Map<string, Folder>;
  _duidsToForms: Map<string, Form>;
  _duidsToFormFields: Map<string, FormField>;
  _duidsToLayouts: Map<string, Layout>;
  _duidsToUsers: Map<string, User>;
  _duidsToNotifications: Map<string, Notification>;
  _duidsToOptions: Map<string, Option>;
  _duidsToProperties: Map<string, Property>;
  _duidsToRelationshipKinds: Map<string, RelationshipKind>;
  _duidsToSpaces: Map<string, Space>;
  _duidsToStatuses: Map<string, Status>;
  _duidsToTasks: Map<string, Task>;
  _duidsToTaskDocRelationships: Map<string, TaskDocRelationship>;
  _duidsToTaskKinds: Map<string, TaskKind>;
  _duidsToViews: Map<string, View>;
  _duidsToWebhooks: Map<string, Webhook>;
  _docDuidsToTaskDuidsToRelationships: Map<string, Map<string, string>>;
  _taskDuidsToDocDuidsToRelationships: Map<string, Map<string, string>>;
  taskDraftDuid: string | null;
};

type Getters = attachment.Getters &
  brainstorm.Getters &
  comment.Getters &
  dartboard.Getters &
  doc.Getters &
  form.Getters &
  user.Getters &
  notification.Getters &
  option.Getters &
  property.Getters &
  relationship.Getters &
  space.Getters &
  status.Getters &
  task.Getters &
  taskKind.Getters &
  view.Getters &
  webhook.Getters;

type Actions = attachment.Actions &
  brainstorm.Actions &
  comment.Actions &
  dartboard.Actions &
  doc.Actions &
  form.Actions &
  user.Actions &
  notification.Actions &
  option.Actions &
  property.Actions &
  relationship.Actions &
  space.Actions &
  status.Actions &
  task.Actions &
  taskKind.Actions &
  view.Actions &
  webhook.Actions & { $setRelationshipKinds(relationshipKinds: RelationshipKind[]): void };

type Name = "DataStore";
export type DataStore = PiniaStore<Name, State, Getters, Actions>;

const useStore: ReachableStore<Name, State, Getters, Actions> = defineStore("DataStore", {
  state: (): State => ({
    _duidsToAttachments: new Map(),
    _duidsToBrainstorms: new Map(),
    _duidsToComments: new Map(),
    _duidsToDartboards: new Map(),
    _duidsToDashboards: new Map(),
    _duidsToDocs: new Map(),
    _duidsToDocsIncremental: new Map(),
    _duidsToForms: new Map(),
    _duidsToFormFields: new Map(),
    _duidsToFolders: new Map(),
    _duidsToLayouts: new Map(),
    _duidsToUsers: new Map(),
    _duidsToNotifications: new Map(),
    _duidsToOptions: new Map(),
    _duidsToProperties: new Map(),
    _duidsToRelationshipKinds: new Map(),
    _duidsToSpaces: new Map(),
    _duidsToStatuses: new Map(),
    _duidsToTasks: new Map(),
    _duidsToTaskKinds: new Map(),
    _duidsToTaskDocRelationships: new Map(),
    _duidsToViews: new Map(),
    _duidsToWebhooks: new Map(),
    _docDuidsToTaskDuidsToRelationships: new Map(),
    _taskDuidsToDocDuidsToRelationships: new Map(),
    taskDraftDuid: null,
  }),
  undo: true,
  getters: {
    ...attachment.getters,
    ...brainstorm.getters,
    ...comment.getters,
    ...dartboard.getters,
    ...doc.getters,
    ...form.getters,
    ...user.getters,
    ...notification.getters,
    ...option.getters,
    ...property.getters,
    ...relationship.getters,
    ...space.getters,
    ...status.getters,
    ...task.getters,
    ...taskKind.getters,
    ...view.getters,
    ...webhook.getters,
  },
  actions: {
    ...attachment.actions,
    ...brainstorm.actions,
    ...comment.actions,
    ...dartboard.actions,
    ...doc.actions,
    ...form.actions,
    ...user.actions,
    ...notification.actions,
    ...option.actions,
    ...property.actions,
    ...relationship.actions,
    ...space.actions,
    ...status.actions,
    ...relationship.actions,
    ...task.actions,
    ...taskKind.actions,
    ...view.actions,
    ...webhook.actions,
  },
});

export default useStore;
