<script setup lang="ts">
import equal from "deep-equal";
import { computed, onMounted, ref, watch } from "vue";

import { backendOld } from "~/api";
import CollapsibleSection from "~/components/dumb/CollapsibleSection.vue";
import { notify } from "~/components/notifications";
import Chips from "~/components/task/properties/Chips.vue";
import LargeChips from "~/components/task/properties/LargeChips.vue";
import { ALL_RECOMMENDABLE_FIELDS } from "~/constants/recommendation";
import { EditorMode, EventActor, FeedbackRating } from "~/shared/enums";
import type { Task, TaskField, TaskFieldRecommendation, TaskUpdate } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getRecommendationDefaultValuesForProperties } from "~/utils/recommendation";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  update: [field: keyof Task];
  toggleDescription: [];
  addSubtask: [];
  setSubtask: [];
  addAttachments: [files: File[]];
}>();

const dataStore = useDataStore();
const tenantStore = useTenantStore();

const chips = ref<InstanceType<typeof Chips> | null>(null);
const largeChips = ref<InstanceType<typeof LargeChips> | null>(null);
const taskDetailProperties = ref<InstanceType<typeof CollapsibleSection> | null>(null);
const generatingPropertyRecommendations = ref(false);
const recommendations = ref<TaskFieldRecommendation[]>([]);
const fieldsChangedByUser = ref<Set<TaskField>>(new Set());

const defaultValues = computed(() => new Map(getRecommendationDefaultValuesForProperties().values()));

const saveFieldRecommendations = (newRecommendations: TaskFieldRecommendation[]) => {
  const partialTask: TaskUpdate = { duid: props.task.duid };

  newRecommendations.forEach(({ field, value }) => {
    (partialTask[field as keyof TaskUpdate] as unknown) = value;
  });

  dataStore.updateTasks([partialTask], { noUndo: true });
};

const isFieldEnabled = (field: keyof Task) => {
  if (field === "assigneeDuids" && !tenantStore.assigneeEnabled) {
    return false;
  }
  if (field === "tagDuids" && !tenantStore.tagsEnabled) {
    return false;
  }
  if (field === "priority" && !tenantStore.priorityEnabled) {
    return false;
  }
  if (field === "size" && !tenantStore.sizeEnabled) {
    return false;
  }
  if (field === "dueAt" && !tenantStore.dueDateEnabled) {
    return false;
  }
  return true;
};

const generatePropertyRecommendations = async (recommendationButton: HTMLElement | null) => {
  if (!recommendationButton) {
    return;
  }
  const { task } = props;

  generatingPropertyRecommendations.value = true;

  const title = task.title?.trim() ?? "";

  let result;

  taskDetailProperties.value?.expand();

  try {
    result = await backendOld.recommendations.getTaskProperties(task.duid, ALL_RECOMMENDABLE_FIELDS, title);
  } catch (error) {
    return;
  } finally {
    generatingPropertyRecommendations.value = false;
  }

  // task changed (e.g. user saved the old one)
  if (task.duid !== props.task.duid) {
    return;
  }

  const allRecommendations: TaskFieldRecommendation[] = result.data.items;

  const oldRecommendations = recommendations.value;
  recommendations.value = allRecommendations.filter(
    ({ field, value }) =>
      !fieldsChangedByUser.value.has(field) &&
      isFieldEnabled(field) &&
      !equal(value, defaultValues.value.get(field), { strict: true })
  );

  // Set all default recommendations
  const newProperties = new Set(recommendations.value.map(({ field }) => field));
  if (newProperties.size === 0) {
    notify({
      message: `${EventActor.DART_AI} has no property recommendations for this task. Add more details and try again.`,
    });
    return;
  }

  const resetProperties = oldRecommendations.map(({ field }) => field).filter((field) => !newProperties.has(field));
  const resetRecommendations = resetProperties
    .map((e) => ({ duid: task.duid, field: e, value: defaultValues.value.get(e) }))
    .filter((e): e is TaskFieldRecommendation => e.value !== undefined);
  saveFieldRecommendations(recommendations.value.concat(resetRecommendations));

  if (props.editorMode === EditorMode.TCM) {
    chips.value?.animateChipRecommendations(recommendationButton, newProperties);
    return;
  }

  largeChips.value?.animateChipRecommendations(recommendationButton, newProperties);
};

const rejectRecommendation = (field: keyof Task) => {
  const recommendation = recommendations.value.find((e) => e.field === field);
  if (!recommendation) {
    return;
  }
  backendOld.recommendations.provideFeedback(recommendation.recommendationDuid, FeedbackRating.BAD);
  recommendations.value = recommendations.value.filter((e) => e.field !== field);
};

const reset = () => {
  defaultValues.value.forEach((defaultValue, field: TaskField) => {
    if (!equal(defaultValue, props.task[field], { strict: true })) {
      fieldsChangedByUser.value.add(field);
    }
  });
};

const acceptRecommendations = () => {
  if (recommendations.value.length > 0) {
    // TODO do in bulk
    recommendations.value.forEach(({ recommendationDuid }) => {
      backendOld.recommendations.provideFeedback(recommendationDuid, FeedbackRating.GOOD);
    });
  }

  recommendations.value = [];
  generatingPropertyRecommendations.value = false;
};

const finish = () => {
  acceptRecommendations();
  fieldsChangedByUser.value = new Set();
  reset();
};

const updateTask = (field: TaskField) => {
  fieldsChangedByUser.value.add(field);
  recommendations.value = recommendations.value.filter((r) => r.field !== field);
};

watch(
  () => props.task,
  (task, oldTask) => {
    if (task.duid === oldTask.duid) {
      return;
    }
    reset();
  }
);

const openFilePicker = () => chips.value?.openFilePicker();

onMounted(() => {
  reset();
});

defineExpose({
  generatePropertyRecommendations,
  generatingPropertyRecommendations,
  openFilePicker,
  reset: finish,
});
</script>

<template>
  <Chips
    v-if="editorMode === EditorMode.TCM"
    ref="chips"
    :task="task"
    :recommendations="recommendations"
    :default-values="defaultValues"
    :editor-mode="editorMode"
    @update="updateTask"
    @toggle-description="emit('toggleDescription')"
    @add-subtask="emit('addSubtask')"
    @set-subtask="emit('setSubtask')"
    @reject-recommendation="rejectRecommendation"
    @add-attachments="(files: File[]) => emit('addAttachments', files)" />
  <CollapsibleSection v-else ref="taskDetailProperties" title="Properties">
    <LargeChips
      ref="largeChips"
      :task="task"
      :recommendations="recommendations"
      :default-values="defaultValues"
      :editor-mode="editorMode"
      @update="updateTask"
      @accept-recommendations="acceptRecommendations"
      @reject-recommendation="rejectRecommendation" />
  </CollapsibleSection>
</template>
