<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import { computed } from "vue";

import { isCreateTaskRow, isGroupContainer, UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyConfig, getPropertyValueStr } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import CollapsedGroupHeader from "~/components/visualization/list/CollapsedGroupHeader.vue";
import GroupHeader from "~/components/visualization/list/GroupHeader.vue";
import { TaskCreateIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  CommandId,
  DartboardKind,
  EditorMode,
  IconSize,
  PageKind,
  SubtaskDisplayMode,
  TaskSourceType,
} from "~/shared/enums";
import type { RowItem } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  params: ICellRendererParams<RowItem>;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const group = computed(() => {
  const { data } = props.params;
  const groupId = data?.isRoot ? data.id : data?.group;
  return appStore.groupByDefinition.groups.find((g) => getPropertyValueStr(g.value) === groupId);
});

const isContainer = computed(() => group.value && isGroupContainer(getPropertyValueStr(group.value.value)));
const isCreateButton = computed(() => props.params.data && isCreateTaskRow(props.params.data.id));

const isGroupEmpty = computed(() => {
  if (appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY) {
    return false;
  }

  if (!group.value) {
    return true;
  }
  const tasks = appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value.value));
  return !tasks || tasks.length === 0;
});

const collapsedGroups = computed(() =>
  appStore.groupByDefinition.groups.filter((g) => appStore.collapsedGroups.includes(getPropertyValueStr(g.value)))
);

const createTask = async (bottom?: boolean) => {
  const groupNotFoundOrUngrouped = !group.value || appStore.groupBy === UNGROUPED_PSEUDO_GROUP_BY;

  const tasks = groupNotFoundOrUngrouped
    ? appStore.filteredAndSortedTasksInPage
    : appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value.value));

  const dartboard = appStore.currentDartboardOrDefault;
  if (!tasks || !dartboard) {
    return;
  }

  const visualization = appStore.getActiveVisualization();
  const { property } = appStore.groupByDefinition;
  const propertyConfig = getPropertyConfig(property.kind);

  const firstTaskOrder = tasks[0]?.order;
  const lastTaskOrder = tasks[tasks.length - 1]?.order;
  /* Assign the value of the group to the task */
  const partialTask = groupNotFoundOrUngrouped ? {} : propertyConfig.getPartialTask(property, {}, group.value.value);
  const task = await dataStore.createTask(
    "",
    dartboard.duid,
    getOrdersBetween(bottom ? lastTaskOrder : undefined, bottom ? undefined : firstTaskOrder)[0],
    TaskSourceType.APP_ENTER,
    partialTask
  );

  setTimeout(() => {
    visualization.selectAndScrollTo(task.duid);
    visualization.editTitle(task.duid);
  });
};

const collapseGroup = () => {
  if (!isContainer.value) {
    props.params.node.setExpanded(false);
  }
  props.params.node.setRowHeight(null);
  props.params.api.onRowHeightChanged();
};
</script>

<template>
  <div
    class="group/section flex w-full select-none"
    :class="isCreateButton ? 'h-full items-center' : 'mt-12 h-fit pl-[29px]'">
    <GroupHeader
      v-if="group && !isGroupContainer(group.id) && !isCreateTaskRow(params.data?.id ?? '')"
      :group="group"
      :editor-mode="EditorMode.LIST"
      :is-empty="isGroupEmpty"
      @collapse="collapseGroup"
      @create-task="createTask" />

    <Tooltip
      v-else-if="params.data && isCreateTaskRow(params.data.id) && !isGroupEmpty && canAddTasks"
      :command-id="CommandId.CREATE_TASK"
      class="flex size-full cursor-pointer items-center py-2 hover:bg-lt"
      :class="[notFlat ? 'pl-[43px]' : 'pl-[27px]', pageStore.hasTouch && (notFlat ? 'pl-[75px]' : 'pl-[59px]')]"
      @click="() => createTask(true)">
      <Button
        disable-hover
        :btn-style="ButtonStyle.SECONDARY"
        text="New task"
        :icon="TaskCreateIcon"
        height-block
        borderless
        :size="ButtonSize.CHIP"
        :icon-size="IconSize.S"
        text-style="text-xs" />
    </Tooltip>

    <div v-else class="flex w-full flex-wrap gap-3">
      <CollapsedGroupHeader
        v-for="collapsedGroup in collapsedGroups"
        :key="collapsedGroup.id"
        :group="collapsedGroup"
        :editor-mode="EditorMode.LIST" />
    </div>
  </div>
</template>
