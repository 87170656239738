<script setup lang="ts">
import { computed } from "vue";

import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import { EXPONENTIAL_SIZE, FIBONACCI_SIZE, LINEAR_SIZE, TSHIRT_SIZE } from "~/constants/size";
import { SizeFormat } from "~/shared/enums";
import type { PropertyConfig, PropertyDefaultSize } from "~/shared/types";
import { useDataStore } from "~/stores";

const SUBTITLE_MAP = {
  [SizeFormat.FIBONACCI]: FIBONACCI_SIZE.join(", "),
  [SizeFormat.EXPONENTIAL]: EXPONENTIAL_SIZE.join(", "),
  [SizeFormat.LINEAR]: LINEAR_SIZE.join(", "),
  [SizeFormat.TSHIRT]: TSHIRT_SIZE.join(", "),
  [SizeFormat.FREE_ENTRY]: "",
};

const props = defineProps<{
  property: PropertyDefaultSize;
  propertyConfig: PropertyConfig;
}>();

const dataStore = useDataStore();

const format = computed(() =>
  Object.values(SizeFormat).includes(props.property.adtl.format) ? props.property.adtl.format : SizeFormat.TSHIRT
);

const formatOptions = computed(() =>
  Object.values(SizeFormat).map((value) => ({
    title: value,
    subtitle: SUBTITLE_MAP[value],
    selected: value === format.value,
    onClick: () => {
      dataStore.updateProperty({ duid: props.property.duid, adtl: { format: value } });
    },
  }))
);
</script>

<template>
  <div class="flex items-center justify-between">
    <span class="flex grow select-none flex-col gap-1">
      <span class="text-sm font-normal text-lt">Size format</span>
      <span class="text-xs text-vlt">This changes the display format of the sizes for this property</span>
    </span>
    <DropdownMenuWithSelection :options="formatOptions" border>
      <div class="w-full select-none rounded px-3 py-1 text-left shadow-sm focus-ring-std hover:bg-lt">
        {{ format }}
      </div>
    </DropdownMenuWithSelection>
  </div>
</template>
