<script setup lang="ts" generic="T = string">
import type { Component } from "vue";

import Checkbox from "~/components/dumb/Checkbox.vue";

defineProps<{
  items: {
    title: string;
    description?: string;
    value: T;
    selected: boolean;
    icon?: Component;
    id?: string;
    dataTestid?: string;
  }[];
  vertical?: boolean;
  showCheckboxes?: boolean;
  showRadios?: boolean;
  smallGaps?: boolean;
  noHover?: boolean;
}>();

const emit = defineEmits<{
  select: [value: T];
}>();
</script>

<template>
  <form @submit.prevent>
    <fieldset
      class="flex w-full flex-col"
      :class="{
        'sm:flex-row': !vertical,
        'gap-1.5': smallGaps,
        'gap-2': !smallGaps && vertical,
        'gap-4': !smallGaps && !vertical,
      }">
      <button
        v-for="item in items"
        :key="item.title"
        type="button"
       
        class="flex flex-1 flex-col gap-3 rounded-md px-3 py-2.5 text-left shadow-sm text-md"
        :class="[
          item.selected ? 'border-2 border-primary-base' : 'm-px border border-md',
          noHover ? 'hover:bg-none' : 'hover:bg-lt',
        ]"
        @click="(showCheckboxes || !item.selected) && emit('select', item.value)">
        <div class="pointer-events-none flex w-full items-center gap-2">
          <div v-if="item.icon" class="rounded-full p-2" :class="item.selected ? 'bg-primary-base/10' : 'bg-lt'">
            <div class="rounded-full p-1" :class="item.selected && 'bg-primary-base/20'">
              <component :is="item.icon" class="icon-md" :class="item.selected ? 'text-primary-base' : 'text-lt'" />
            </div>
          </div>
          <span class="flex-1 select-none text-base text-md">{{ item.title }}</span>
          <Checkbox
            v-if="showCheckboxes || showRadios"
            :radio="showRadios"
            :value="item.selected"
            :label="item.title"
            locked />
        </div>
        <span
          v-if="item.description"
          class="select-none hyphens-auto px-1 text-left text-xs text-lt break-words sm:max-w-96">
          {{ item.description }}
        </span>
        <slot :name="item.id" />
      </button>
    </fieldset>
  </form>
</template>
