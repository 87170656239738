<script setup lang="ts">
import { computed, ref } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { UNSET_KIND_LABEL, UNSET_KIND_PSUEDO_DUID } from "~/constants/kind";
import { colorsByTheme } from "~/constants/style";
import { TaskTypeFieldIcon } from "~/icons";
import { ButtonStyle, DropdownMenuItemKind, EditorMode, IconSize, Placement } from "~/shared/enums";
import type { DropdownMenuItem, PropertyDefaultKind, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";

type Value = PropertyValueForKind<PropertyDefaultKind>;

const props = defineProps<{
  property: PropertyDefaultKind;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
  onAfterClose?: (value: Value | null) => void;
}>();

const emit = defineEmits<{
  update: [value: Value];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const dropdownMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const colors = computed(() => colorsByTheme[pageStore.theme]);

const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);
const isFormOrDefaultPropertyMode = computed(() => isFormMode.value || isPropertyDefaultMode.value);

const selectedTaskKindDuid = computed(() => {
  if (isFormOrDefaultPropertyMode.value) {
    return props.value ?? UNSET_KIND_PSUEDO_DUID;
  }

  const firstValue = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstValue) ? firstValue : null;
});
const selectedTaskKind = computed(() =>
  selectedTaskKindDuid.value ? dataStore.getTaskKindByDuid(selectedTaskKindDuid.value) : undefined
);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const updateSelectedTaskKind = (kindDuid: Value) =>
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, kindDuid) }))
  );

const dropdownSections = computed(() => {
  const firstValue = isFormOrDefaultPropertyMode.value
    ? props.value
    : getPropertyValueFromTask(props.property, props.tasks[0]);
  const kindDuidOfSelectedTasks = isFormOrDefaultPropertyMode.value
    ? (props.value ?? UNSET_KIND_PSUEDO_DUID)
    : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === firstValue)
      ? firstValue
      : undefined;

  const items: DropdownMenuItem[] = [];

  if (isFormOrDefaultPropertyMode.value) {
    items.push({
      title: UNSET_KIND_LABEL,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: kindDuidOfSelectedTasks === UNSET_KIND_PSUEDO_DUID,
      icon: TaskTypeFieldIcon,
      iconArgs: { class: "icon-md text-vlt" },
      onClick: () => {
        emit("update", UNSET_KIND_PSUEDO_DUID);
      },
    });
  }

  items.push(
    ...dataStore.taskKindList.map((kind) => ({
      title: kind.title,
      kind: DropdownMenuItemKind.BUTTON,
      disabled: kindDuidOfSelectedTasks === kind.duid,
      icon: PageIcon,
      iconArgs: { page: kind, size: IconSize.S },
      onClick: () => {
        if (isFormOrDefaultPropertyMode.value) {
          emit("update", kind.duid);
          return;
        }

        updateSelectedTaskKind(kind.duid);
        emit("update", kind.duid);
      },
    }))
  );

  return [{ title: props.property.title, items }];
});

const handleAfterClose = () => {
  props.onAfterClose?.(selectedTaskKindDuid.value);
};

defineExpose({
  open: () => dropdownMenu.value?.open(),
});
</script>

<template>
  <DropdownMenu
    ref="dropdownMenu"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :sections="dropdownSections"
    :block="isListMode || isTaskDetailMode || isFormOrDefaultPropertyMode"
    :height-block="isListMode || isFormOrDefaultPropertyMode"
    :skidding="isFormOrDefaultPropertyMode ? -1 : undefined"
    :distance="isFormOrDefaultPropertyMode ? 2 : undefined"
    :show-on-hover="isContextMenuMode"
    :cover="!isContextMenuMode && !isFormOrDefaultPropertyMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    :on-after-close="handleAfterClose"
    :prevent-close-on-select="isPropertyDefaultMode">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="TaskTypeFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isFormOrDefaultPropertyMode"
      :class="{ 'overflow-hidden': isFormOrDefaultPropertyMode }"
      :text="isBoardMode ? property.title : `Change ${property.title.toLowerCase()}`"
      :block="isListMode || isTaskDetailMode || isFormOrDefaultPropertyMode"
      :height-block="isListMode || isFormOrDefaultPropertyMode">
      <div
        v-if="isTaskDetailMode"
        class="flex w-full items-center justify-start gap-2 rounded py-1 pl-1.5 pr-2 text-left hover:bg-lt">
        <div v-if="!isListMode && !selectedTaskKind" class="text-vlt">
          {{ `Set ${property.title.toLowerCase()}` }}
        </div>
        <template v-else>
          <component
            :is="selectedTaskKind ? PageIcon : TaskTypeFieldIcon"
            :page="selectedTaskKind"
            :size="selectedTaskKind && isBoardMode ? IconSize.S : undefined"
            :class="!selectedTaskKind && 'icon-md'" />
          <span :title="selectedTaskKind?.title" class="flex-1 select-none hyphens-auto break-words">
            {{ selectedTaskKind?.title }}
          </span>
        </template>
      </div>
      <Button
        v-else
        :btn-style="ButtonStyle.CHIP"
        :icon="selectedTaskKind ? PageIcon : isFormOrDefaultPropertyMode ? undefined : TaskTypeFieldIcon"
        :icon-args="
          selectedTaskKind ? { page: selectedTaskKind, size: isBoardMode ? IconSize.S : undefined } : undefined
        "
        :icon-size="isBoardMode ? IconSize.XS : undefined"
        block
        :text="!selectedTaskKind && isFormOrDefaultPropertyMode ? 'Set type' : selectedTaskKind?.title"
        :disable-hover="isListMode || isFormOrDefaultPropertyMode || isBoardMode"
        :borderless="!isChipMode && !isBoardMode"
        :text-style="isFormOrDefaultPropertyMode && !selectedTaskKind ? 'text-vlt' : 'text-md'"
        :class="{
          rounded: !isListMode,
          'gap-2': !isChipMode && !isBoardMode,
          'px-1 py-0.5': !isListMode && !isFormOrDefaultPropertyMode && !isBoardMode,
          '!justify-start px-[7px]': isFormOrDefaultPropertyMode || isListMode,
          'max-w-xs truncate': isChipMode,
          'text-xs': isListMode || isBoardMode,
          'h-5 py-0 pl-1 pr-1.5': isBoardMode,
        }"
        a11y-label="Type" />
    </Tooltip>
  </DropdownMenu>
</template>
