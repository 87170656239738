import { NONNULL_PRIORITY_OPTIONS } from "~/constants/priority";
import { DartboardKind, Priority, SpaceKind, StatusKind, UserStatus } from "~/shared/enums";
import type {
  Attachment,
  Comparator,
  ComparatorResult,
  Dartboard,
  Dashboard,
  DateRange,
  Doc,
  Folder,
  Form,
  FormField,
  Notification,
  Option,
  Position,
  Property,
  Space,
  Status,
  Task,
  TaskDocRelationship,
  TaskKind,
  TimeTracking,
  TimeTrackingEntry,
  User,
  View,
  Webhook,
} from "~/shared/types";
import { simpleStrHash } from "~/utils/common";
import { getTimeTrackingDuration } from "~/utils/time";

const DARTBOARD_SORT_ORDER = Object.values(DartboardKind);
const SPACE_KIND_TO_INDEX = Object.fromEntries(
  [SpaceKind.WORKSPACE, SpaceKind.OTHER, SpaceKind.PERSONAL].map((e, i) => [e, i])
);
export const STATUS_SORT_ORDER = Object.values(StatusKind);
const PRIORITY_SORT_ORDER = [null, ...NONNULL_PRIORITY_OPTIONS.toReversed()];

const USER_STATUS_TO_INDEX = Object.fromEntries(
  [
    UserStatus.ACTIVE,
    UserStatus.PENDING_EMAIL_VERIFICATION,
    UserStatus.INVITED,
    UserStatus.PENDING_SUBSCRIPTION_UPGRADE,
    UserStatus.DEACTIVATED,
  ].map((e, i) => [e, i])
);

// TODO use in more of the other comparators below
export const makeWithUndefinedLast =
  <T>(comparator: Comparator<T>) =>
  (a: T | undefined, b: T | undefined) => {
    if (a === undefined || b === undefined) {
      return a === b ? 0 : b === undefined ? -1 : 1;
    }
    return comparator(a, b);
  };

export const sign = (n: number): ComparatorResult => (n === 0 ? 0 : n > 0 ? 1 : -1);

export const booleanComparator = (a: boolean, b: boolean): ComparatorResult => (a === b ? 0 : a ? -1 : 1);

export const numberComparator = (a: number, b: number): ComparatorResult => sign(a - b);

export const stringComparator = (a: string, b: string): ComparatorResult => (a > b ? 1 : b > a ? -1 : 0);

export const stringComparatorReversed = (a: string, b: string): ComparatorResult => (a > b ? -1 : b > a ? 1 : 0);

export const makeStringComparator = <T>(getStrFn: (object: T) => string, reverse = false) => {
  if (reverse) {
    return (a: T, b: T): ComparatorResult => stringComparatorReversed(getStrFn(a), getStrFn(b));
  }
  return (a: T, b: T): ComparatorResult => stringComparator(getStrFn(a), getStrFn(b));
};

export const makeListComparatorOrganizedBySize =
  <T>(comparator: (a: T, b: T) => ComparatorResult) =>
  (a: T[], b: T[]): ComparatorResult => {
    const aLen = a.length;
    const bLen = b.length;
    for (let i = 0; i < aLen; i += 1) {
      if (i >= bLen) {
        return 1;
      }
      const diff = comparator(a[i], b[i]);
      if (diff !== 0) {
        return diff;
      }
    }
    return aLen === bLen ? 0 : -1;
  };

export const stringListComparator = makeListComparatorOrganizedBySize(stringComparator);

export const notificationComparator = (a: Notification, b: Notification): ComparatorResult =>
  stringComparator(b.createdAt, a.createdAt);

export const spaceComparator = (a: Space | undefined, b: Space | undefined) => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  const kindDiff = SPACE_KIND_TO_INDEX[a.kind] - SPACE_KIND_TO_INDEX[b.kind];
  if (kindDiff !== 0) {
    return sign(kindDiff);
  }
  return stringComparator(a.order, b.order);
};

export const makeDartboardComparator =
  (
    getSpaceFn: (spaceDuid: string) => Space | undefined,
    options: { chronologicalOrder: boolean } = { chronologicalOrder: false }
  ) =>
  (a: Dartboard | undefined, b: Dartboard | undefined): ComparatorResult => {
    if (!a || !b) {
      return a === b ? 0 : a ? -1 : 1;
    }

    const spaceDiff = spaceComparator(getSpaceFn(a.spaceDuid), getSpaceFn(b.spaceDuid));
    if (spaceDiff !== 0) {
      return spaceDiff;
    }
    const sortOrder = options.chronologicalOrder
      ? DARTBOARD_SORT_ORDER.sort((c, d) => (c === DartboardKind.FINISHED ? -1 : d === DartboardKind.FINISHED ? 1 : 0))
      : DARTBOARD_SORT_ORDER;
    const indexDiff = sortOrder.indexOf(a.kind) - sortOrder.indexOf(b.kind);
    if (indexDiff !== 0) {
      return sign(indexDiff);
    }
    if (a.kind === DartboardKind.FINISHED) {
      return options.chronologicalOrder ? sign((a.index ?? 0) - (b.index ?? 0)) : sign((b.index ?? 0) - (a.index ?? 0));
    }
    return stringComparator(a.order, b.order);
  };

export const priorityComparator = (a: Priority | null, b: Priority | null): ComparatorResult => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  return sign(PRIORITY_SORT_ORDER.indexOf(a) - PRIORITY_SORT_ORDER.indexOf(b));
};
export const dateComparator = (a: string | null, b: string | null): ComparatorResult => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  return stringComparator(a, b);
};

export const datesComparator = (a: DateRange, b: DateRange): ComparatorResult => {
  const [aStart, aDue] = a;
  const [bStart, bDue] = b;
  if (!aStart || !bStart) {
    return aStart === bStart ? 0 : aStart ? -1 : 1;
  }
  if (aStart !== bStart) {
    return stringComparator(aStart, bStart);
  }
  if (!aDue || !bDue) {
    return aDue === bDue ? 0 : aDue ? -1 : 1;
  }
  return stringComparator(aDue, bDue);
};

export const timeTrackingEntryComparator = (a: TimeTrackingEntry, b: TimeTrackingEntry): ComparatorResult => {
  const aFin = a.finishedAt;
  const bFin = b.finishedAt;
  if ((!aFin || !bFin) && aFin !== bFin) {
    return aFin ? -1 : 1;
  }
  const startDiff = stringComparator(a.startedAt, b.startedAt);
  if (startDiff !== 0) {
    return startDiff;
  }
  if (!aFin || !bFin) {
    return 0;
  }
  return stringComparator(aFin, bFin);
};

export const timeTrackingComparator = (a: TimeTracking, b: TimeTracking): ComparatorResult => {
  const aMs = getTimeTrackingDuration(a).asMilliseconds();
  const bMs = getTimeTrackingDuration(b).asMilliseconds();
  return sign(aMs - bMs);
};

export const makeDateOrDatesComparator = (isRange: boolean) =>
  isRange ? datesComparator : (a: DateRange, b: DateRange) => dateComparator(a[1], b[1]);

export const sizeComparator = (a: number | null, b: number | null) => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  return sign(a - b);
};

export const attachmentComparator = (a: Attachment, b: Attachment): ComparatorResult =>
  stringComparator(a.order, b.order);

export const propertyComparator = (a: Property, b: Property): ComparatorResult => stringComparator(a.order, b.order);

export const taskKindComparator = (a: TaskKind, b: TaskKind): ComparatorResult => stringComparator(a.order, b.order);

export const checkboxComparator = booleanComparator;

export const statusComparator = (a: Status | undefined, b: Status | undefined): ComparatorResult => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  const diff = STATUS_SORT_ORDER.indexOf(a.kind) - STATUS_SORT_ORDER.indexOf(b.kind);
  if (diff !== 0) {
    return sign(diff);
  }

  return stringComparator(a.order, b.order);
};

export const numberMaybeComparator = (a: number | null, b: number | null) => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  return sign(a - b);
};

export const optionComparator = (a: Option | undefined, b: Option | undefined): ComparatorResult => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  return stringComparator(a.title, b.title);
};

export const optionsComparator = (a: Option[], b: Option[]) =>
  makeListComparatorOrganizedBySize(optionComparator)(a, b);

export const formComparator = (a: Form, b: Form): ComparatorResult => stringComparator(a.order, b.order);

export const formFieldComparator = (a: FormField, b: FormField): ComparatorResult => stringComparator(a.order, b.order);

export const makeTaskComparator =
  (
    getSpaceFn: (spaceDuid: string) => Space | undefined,
    getDartboardFn: (dartboardDuid: string) => Dartboard | undefined
  ) =>
  (a: Task | undefined, b: Task | undefined): ComparatorResult => {
    if (!a || !b) {
      return a === b ? 0 : a ? -1 : 1;
    }
    if (a.inTrash !== b.inTrash) {
      return a.inTrash ? 1 : -1;
    }
    const dartboardDiff = makeDartboardComparator(getSpaceFn)(
      getDartboardFn(a.dartboardDuid),
      getDartboardFn(b.dartboardDuid)
    );
    if (dartboardDiff !== 0) {
      return dartboardDiff;
    }

    return stringComparator(a.order, b.order);
  };

export const makeTaskComparatorWithDisabledAndCurrentDartboard = (
  getSpaceFn: (spaceDuid: string) => Space | undefined,
  getDartboardFn: (dartboardDuid: string) => Dartboard | undefined,
  getDisabledFn: (task: Task) => boolean,
  currentDartboard: Dartboard | undefined
) => {
  const standardTaskComparator = makeTaskComparator(getSpaceFn, getDartboardFn);
  return (a: Task, b: Task) => {
    const aDisabled = getDisabledFn(a);
    const bDisabled = getDisabledFn(b);
    if (aDisabled !== bDisabled) {
      return aDisabled ? 1 : -1;
    }

    const aInCurrentDartboard = a.dartboardDuid === currentDartboard?.duid;
    const bInCurrentDartboard = b.dartboardDuid === currentDartboard?.duid;
    if (aInCurrentDartboard !== bInCurrentDartboard) {
      return aInCurrentDartboard ? -1 : 1;
    }
    return standardTaskComparator(a, b);
  };
};

export const userComparator = (a: User | undefined, b: User | undefined): ComparatorResult => {
  if (!a || !b) {
    return a === b ? 0 : a ? -1 : 1;
  }
  // Sort by status, then whether they have a name, then alphabetical
  const statusDiff = USER_STATUS_TO_INDEX[a.status] - USER_STATUS_TO_INDEX[b.status];
  if (statusDiff !== 0) {
    return sign(statusDiff);
  }

  if (!a.name || !b.name) {
    return a.name === b.name ? stringComparator(a.email, b.email) : a.name ? -1 : 1;
  }
  return stringComparator(a.name, b.name);
};

export const usersComparator = (a: User[], b: User[]) => makeListComparatorOrganizedBySize(userComparator)(a, b);

export const viewComparator = (a: View, b: View): ComparatorResult => stringComparator(a.order, b.order);

export const webhookComparator = (a: Webhook, b: Webhook): ComparatorResult => stringComparator(a.order, b.order);

export const makeFolderComparator =
  (getSpaceFn: (spaceDuid: string) => Space | undefined) => (a: Folder | undefined, b: Folder | undefined) => {
    if (!a || !b) {
      return a === b ? 0 : a ? -1 : 1;
    }
    const spaceDiff = spaceComparator(getSpaceFn(a.spaceDuid), getSpaceFn(b.spaceDuid));
    if (spaceDiff !== 0) {
      return spaceDiff;
    }
    return stringComparator(a.order, b.order);
  };

export const makeDashboardComparator =
  () =>
  (a: Dashboard, b: Dashboard): ComparatorResult =>
    stringComparator(a.order, b.order);

export const makeDocComparator =
  (getSpaceFn: (spaceDuid: string) => Space | undefined, getFolderFn: (folderDuid: string) => Folder | undefined) =>
  (a: Doc | undefined, b: Doc | undefined): ComparatorResult => {
    if (!a || !b) {
      return a === b ? 0 : a ? -1 : 1;
    }
    const folderDiff = makeFolderComparator(getSpaceFn)(getFolderFn(a.folderDuid), getFolderFn(b.folderDuid));
    if (folderDiff !== 0) {
      return folderDiff;
    }
    return stringComparator(a.order, b.order);
  };

export const makeTaskDocRelationshipDocComparator =
  (
    getSpaceFn: (spaceDuid: string) => Space | undefined,
    getFolderFn: (folderDuid: string) => Folder | undefined,
    getDocFn: (docDuid: string) => Doc | undefined
  ) =>
  (a: TaskDocRelationship, b: TaskDocRelationship): ComparatorResult =>
    makeDocComparator(getSpaceFn, getFolderFn)(getDocFn(a.docDuid), getDocFn(b.docDuid));

export const makeTaskDocRelationshipTaskComparator =
  (
    getSpaceFn: (spaceDuid: string) => Space | undefined,
    getDartboardFn: (dartboardDuid: string) => Dartboard | undefined,
    getTaskFn: (taskDuid: string) => Task | undefined
  ) =>
  (a: TaskDocRelationship, b: TaskDocRelationship): ComparatorResult =>
    makeTaskComparator(getSpaceFn, getDartboardFn)(getTaskFn(a.taskDuid), getTaskFn(b.taskDuid));

export const sortArrayByHash = <T>(arr: T[], key: keyof T): T[] =>
  [...arr].sort((a, b) => stringComparator(simpleStrHash(a[key] as string), simpleStrHash(b[key] as string)));

export const makePropertyComparator = (propertyOrderDuids: string[]) =>
  makeWithUndefinedLast((a: string, b: string) => sign(propertyOrderDuids.indexOf(a) - propertyOrderDuids.indexOf(b)));

export const positionComparator = (a: Position | undefined, b: Position | undefined): ComparatorResult =>
  a === b ? 0 : a === "left" || b === "right" ? -1 : 1;
