import {
  type Awaitable,
  type MaybeRefOrGetter,
  tryOnMounted,
  tryOnUnmounted,
  useElementSize,
  useElementVisibility,
  useScroll,
} from "@vueuse/core";
import { computed, nextTick, reactive, type Ref, ref, toValue, watch } from "vue";

type ScrollContainer = HTMLElement | Window | Document | null | undefined;
type ScrollElement = HTMLElement | null | undefined;

const LOAD_MORE_DISTANCE_PX = 500;

// Document and Window cannot be observed by IntersectionObserver
const resolveElement = (el: ScrollContainer) => {
  if (typeof Window !== "undefined" && el instanceof Window) {
    return el.document.documentElement;
  }
  if (typeof Document !== "undefined" && el instanceof Document) {
    return el.documentElement;
  }
  return el as ScrollElement;
};

const usePaginatedList = <T extends ScrollContainer, Item>(
  scrollContainer: MaybeRefOrGetter<T>,
  alreadyLoadedItems: Ref<Item[]>,
  loadMore: (items: Item[]) => Awaitable<number>,
  // The amount of spacing after the last item, default matches extra-overscroll
  loadMoreOffset: number = 300,
  direction: "top" | "bottom" | "left" | "right" = "bottom"
) => {
  const state = reactive(
    useScroll(scrollContainer, { offset: { [direction]: loadMoreOffset + LOAD_MORE_DISTANCE_PX } })
  );
  const totalCount = ref<number | null>(null);

  const promise = ref<Promise<number> | null>(null);
  const isLoading = computed(
    () => !!promise.value || (totalCount.value === null && alreadyLoadedItems.value.length === 0)
  );
  const canLoadMore = computed(() => totalCount.value === null || alreadyLoadedItems.value.length < totalCount.value);

  const observedElement = computed<ScrollElement>(() => resolveElement(toValue(scrollContainer)));
  const isElementVisible = useElementVisibility(observedElement);
  const elementSize = useElementSize(observedElement);

  const checkAndLoad = async (force: boolean = false) => {
    state.measure();

    if (!observedElement.value || !isElementVisible.value || promise.value || !canLoadMore.value) {
      return;
    }

    const { scrollHeight, clientHeight, scrollWidth, clientWidth } = observedElement.value;
    const isWithinContainer =
      direction === "bottom" || direction === "top" ? scrollHeight <= clientHeight : scrollWidth <= clientWidth;

    if (state.arrivedState[direction] || isWithinContainer || force) {
      try {
        promise.value = Promise.resolve(loadMore(alreadyLoadedItems.value));
        totalCount.value = await promise.value;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        totalCount.value = totalCount.value ?? alreadyLoadedItems.value.length;
      } finally {
        promise.value = null;
        // Load more as needed, which happens when the current batch doesn't fill the element
        nextTick(() => checkAndLoad());
      }
    }
  };

  // Reset the state
  const reset = () => {
    totalCount.value = null;
    checkAndLoad(true);
  };

  // Initial load
  tryOnMounted(() => checkAndLoad(true));

  // Watch for changes
  const stop = watch(
    () => [state.arrivedState[direction], isElementVisible.value, elementSize.width.value, elementSize.height.value],
    () => checkAndLoad()
  );
  tryOnUnmounted(stop);

  return { isLoading, totalCount, canLoadMore, reset };
};

export default usePaginatedList;
