<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditorMode } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  value: string;
  property?: Property;
  editorMode: EditorMode;
}>();

const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
</script>

<template>
  <component
    :is="isBoardMode ? Tooltip : Template"
    v-if="value !== ''"
    :text="isBoardMode ? property?.title : undefined">
    <div
      class="flex min-h-5 items-center justify-center hyphens-auto rounded border px-1 text-xs text-md border-oncolor break-words">
      {{ value }}
    </div>
  </component>
</template>
