<script setup lang="ts">
import { computed, useSlots } from "vue";

import Template from "~/components/dumb/Template.vue";
import type { Option } from "~/shared/types";
import { useDataStore, usePageStore } from "~/stores";
import { getChipColorsHex } from "~/utils/color";
import { getAncestorDuids } from "~/utils/common";

const dataStore = useDataStore();
const pageStore = usePageStore();

const props = defineProps<{
  option: Option;
  showAncestors?: boolean;
  truncate?: boolean;
  itemClass?: string;
  inChipGroup?: boolean;
  isFirstAncestor?: boolean;
  showHiddenTruncatedOption?: boolean;
}>();

const hasSlot = computed(() => !!useSlots().default);

const ancestorDuids = computed(() => {
  const property = dataStore.getPropertyByDuid(props.option.propertyDuid);
  return property ? getAncestorDuids(dataStore.getOptionList(property), props.option) : [];
});

const shownOptions = computed(() =>
  (props.showAncestors
    ? ancestorDuids.value
        .map((e) => dataStore.getOptionByDuid(e))
        .filter((e): e is Option => !!e)
        .concat(props.option)
    : [props.option]
  ).map((e) => ({ ...e, colorSet: getChipColorsHex(e.colorHex, pageStore.theme) }))
);
</script>

<template>
  <component
    :is="shownOptions.length === 1 ? Template : 'div'"
    class="flex w-fit max-w-full select-none"
    :class="itemClass">
    <div
      v-for="(item, index) in shownOptions"
      :key="item.duid"
      class="flex min-h-5 w-fit max-w-full items-center justify-between whitespace-break-spaces border-y border-l py-px pr-1 text-xs font-normal"
      :class="[
        {
          'rounded-l pl-1.5': index === 0,
          '!w-28 truncate': showHiddenTruncatedOption,
          'pl-1': index !== 0,
          'rounded-r border-r': index === shownOptions.length - 1,
          truncate: shownOptions.length > 1 || isFirstAncestor,
        },
        shownOptions.length === 1 && itemClass,
      ]"
      :style="{ color: item.colorSet.fore, backgroundColor: item.colorSet.back, borderColor: item.colorSet.border }">
      <div
        :title="item.title"
        :class="[
          truncate ? 'truncate' : 'hyphens-auto break-words',
          hasSlot && index === shownOptions.length - 1 && !inChipGroup && 'max-w-full',
          index === shownOptions.length - 1 && 'mr-0.5',
          shownOptions.length > 1 && 'truncate',
        ]">
        {{ item.title }}
      </div>
      <slot v-if="index === shownOptions.length - 1" />
    </div>
  </component>
</template>
