<script setup lang="ts">
import { isAxiosError } from "axios";
import { computed, nextTick, ref } from "vue";
import { RouterLink, useRouter } from "vue-router";

import { backendOld } from "~/api";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import { GoogleIcon } from "~/icons";
import { finishLogin, getQueryParam } from "~/router/common";
import { AnimationDuration, ButtonSize, ButtonStyle, GoogleLoginAction } from "~/shared/enums";
import { useEnvironmentStore, usePageStore } from "~/stores";
import { getGoogleUrl, run, validateEmail, validatePasswordBasic } from "~/utils/common";

import AuthBaseView from "./AuthBaseView.vue";

enum Step {
  INITIAL,
  EMAIL,
}

const router = useRouter();
const pageStore = usePageStore();
const environmentStore = useEnvironmentStore();

const tokenDuid = ref(getQueryParam("t") ?? "");
const errorMsg = ref(getQueryParam("se") ?? null);
const query = { ...router.currentRoute.value.query };
delete query.t;
delete query.se;
router.replace({ query });

const emailInput = ref<InstanceType<typeof Input> | null>(null);
const passwordInput = ref<InstanceType<typeof Input> | null>(null);

const step = ref(errorMsg.value === null ? Step.INITIAL : Step.EMAIL);
const isSso = ref(!!errorMsg.value);

const submitting = ref(false);
const submitButtonEnabled = computed(
  () => step.value !== Step.INITIAL && emailInput.value?.isValid && (isSso.value || passwordInput.value?.isValid)
);

if (tokenDuid.value) {
  run(async () => {
    try {
      const { data } = await backendOld.auth.tokenLogin(tokenDuid.value);
      finishLogin(data);
    } catch {
      pageStore.pageLoaded = true;
    }
  });
} else {
  pageStore.pageLoaded = true;
}

const loginWithPassword = async () => {
  if (!emailInput.value || !passwordInput.value) {
    return;
  }

  submitting.value = true;

  try {
    const { data } = await backendOld.auth.login(emailInput.value.value, passwordInput.value.value);
    finishLogin(data);
  } catch (error) {
    if (isAxiosError<{ errors: string[] }>(error)) {
      submitting.value = false;
      const errorText = error.response?.data.errors[0] ?? "";
      if (errorText === "Authentication error") {
        errorMsg.value = `${environmentStore.isLocal ? "CSRF failure, p" : "P"}lease refresh the page and try again`;
      } else if (errorText === "No account found, try a different email") {
        setTimeout(() => {
          router.push({ name: "sign_up", query: { email: emailInput.value?.value ?? "" } });
        });
      } else {
        errorMsg.value = errorText;
      }
    }
  }
};

const loginWithSso = async () => {
  if (!emailInput.value) {
    return;
  }

  submitting.value = true;

  try {
    const { data } = await backendOld.auth.getSamlSsoRedirect(emailInput.value.value);
    window.location.href = data.redirectUrl;
  } catch (error) {
    if (isAxiosError<{ errors: string[] }>(error)) {
      submitting.value = false;
      errorMsg.value = error.response?.data.errors[0] ?? "Please try again";
    }
  }
};

const login = () => {
  if (isSso.value) {
    loginWithSso();
    return;
  }

  loginWithPassword();
};

const backToInitialStep = () => {
  step.value = Step.INITIAL;
  isSso.value = false;
  errorMsg.value = null;
};

const nextStep = () => {
  if (step.value === Step.INITIAL) {
    step.value = Step.EMAIL;
    nextTick(() => {
      emailInput.value?.focus();
    });
    return;
  }
  if (submitButtonEnabled.value) {
    login();
  }
};

const nextStepWithSso = () => {
  isSso.value = true;
  nextStep();
};

const googleUrl = computed(() =>
  getGoogleUrl(
    GoogleLoginAction.LOGIN,
    environmentStore.googleRedirectUri,
    pageStore.isDesktopApp,
    pageStore.isMobileApp,
    undefined,
    undefined,
    getQueryParam("next")
  )
);

const displayContinueInBrowser = ref(false);

const toggleContinueInBrowser = () => {
  if (!pageStore.isDesktopApp && !pageStore.isMobileApp) {
    return;
  }

  displayContinueInBrowser.value = !displayContinueInBrowser.value;
};
</script>

<template>
  <AuthBaseView>
    <template #heading>Log in to Dart</template>

    <template #default>
      <Animated v-if="!displayContinueInBrowser" :duration="AnimationDuration.LONG" class="flex w-full flex-col gap-4">
        <template v-if="step === Step.INITIAL">
          <a
            v-if="!environmentStore.isPreview && !environmentStore.hideForReviewer"
            :href="googleUrl"
            :target="pageStore.isDesktopApp ? '_blank' : undefined"
            :rel="pageStore.isDesktopApp ? 'noopener noreferrer' : undefined"
            @click="toggleContinueInBrowser"
            @keydown.enter="toggleContinueInBrowser">
            <span class="sr-only">Log in with Google</span>
            <Button :btn-style="ButtonStyle.PRIMARY" text="Log in with Google" :icon="GoogleIcon" block />
          </a>

          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :size="ButtonSize.LARGE"
            text="Log in with email"
            block
           
            @click="nextStep" />

          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :size="ButtonSize.LARGE"
            text="Log in with SSO"
            block
            class="mb-6"
            @click="nextStepWithSso" />
        </template>

        <form v-else class="flex flex-col gap-4" @submit.prevent>
          <Input
            ref="emailInput"
            input-type="email"
            required
            placeholder="Enter your email"
            label="Email"
           
            :disabled="submitting"
            :validate="validateEmail"
            @change="errorMsg = null"
            @enter="nextStep" />
          <div v-if="!isSso" class="relative">
            <Input
              ref="passwordInput"
              input-type="password"
              required
              placeholder="Enter your password"
              label="Password"
             
              :disabled="submitting"
              :validate="validatePasswordBasic"
              input-classes="pr-[86px]"
              @change="errorMsg = null"
              @enter="nextStep" />
            <div class="absolute right-7 top-[5px] z-10 m-1.5 flex items-center gap-1">
              <RouterLink :to="{ name: 'reset_password' }" class="select-none text-xs text-vlt hover:text-lt">
                Forgot?
              </RouterLink>
            </div>
          </div>
          <div class="-mb-5">
            <Button
              :btn-style="ButtonStyle.PRIMARY"
              text="Log in"
              :disabled="!submitButtonEnabled"
             
              :working="submitting"
              block
              class="mt-3"
              @click="nextStep" />
            <FormError :show="!!errorMsg" :msg="errorMsg" />
          </div>
        </form>
      </Animated>
      <p v-else class="text-center text-2xl text-md">Finish in your browser</p>
    </template>

    <template #footer>
      <template v-if="!displayContinueInBrowser">
        <template v-if="step === Step.INITIAL">
          <span class="select-none">Don't have a Dart account?</span>
          <span class="select-none text-primary-base hover:text-primary-hover-light dark:hover:text-primary-hover-dark">
            <RouterLink :to="{ name: 'sign_up' }" class="font-semibold">Sign up</RouterLink>
          </span>
        </template>
        <button v-else type="button" @click="backToInitialStep" @keydown.enter="backToInitialStep">← Back</button>
      </template>
      <button
        v-else
        type="button"
        class="underline"
        @click="toggleContinueInBrowser"
        @keydown.enter="toggleContinueInBrowser">
        ← Back
      </button>
    </template>
  </AuthBaseView>
</template>
