<script setup lang="ts">
import moment from "moment";
import { computed, ref } from "vue";

import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Input from "~/components/dumb/Input.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import RecurrenceEditor from "~/components/dumb/RecurrenceEditor.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { BlockedIcon, InfoIcon, SprintIcon, TrashIcon } from "~/icons";
import { ButtonSize, DialogMode, Placement, SprintMode } from "~/shared/enums";
import type { Recurrence, Space } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { validateIsNotEmpty } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";
import { getRecursNextAtStr, normalizeRecursNextAt } from "~/utils/time";

const DISABLE_SPRINTS_DIALOG_DESCRIPTION =
  "Disabling sprints will remove them for everyone in the workspace and move all the tasks in them to the trash. This can't be undone. Are you sure you want to proceed?";
const SPRINT_NAME_FORMAT_DESCRIPTION =
  "The format of the sprint name. Use {N} for the sprint number, {S} for the start date, and {F} for the finish date.";
const SPRINT_REPLICATE_ON_ROLLOVER_DESCRIPTION =
  "By default, unfinished tasks will be moved to the new sprint. Enabling replication will create copies of the tasks in the new sprint instead.";

const NO_SPRINTS_VALUE = "No sprints";
const SPRINTS_VALUE = "Sprints";

const props = defineProps<{
  space: Space;
}>();

const dataStore = useDataStore();
const tenantStore = useTenantStore();

const disableSprintsDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const sprintsDescription = computed(
  () =>
    `Sprints help organize work. Current tasks go in Active${
      tenantStore.backlogEnabled
        ? ", near-term ones in Next, and long-term ones in Backlog"
        : " and near-term ones in Next"
    }. When you complete the Active tasks, start the next sprint.`
);

const updateField = (spacePartial: Partial<Space>) => {
  dataStore.updateSpace({ duid: props.space.duid, ...spacePartial });
};

const sprintNameFmt = ref(props.space?.sprintNameFmt ?? "");

const saveSprintNameFmtManager = new ThrottleManager(() => {
  updateField({ sprintNameFmt: sprintNameFmt.value.trim() });
}, THROTTLE_MS);

const updateSprintNameFmt = (newValue: string) => {
  if (newValue === "" || newValue === sprintNameFmt.value) {
    return;
  }
  sprintNameFmt.value = newValue;
  saveSprintNameFmtManager.run();
};

const updateSprintMode = (newSprintMode: SprintMode, override = false) => {
  if (newSprintMode === SprintMode.NONE && !override) {
    disableSprintsDialog.value?.openModal();
    return;
  }

  dataStore.updateSpaceSprintMode(props.space.duid, newSprintMode);
};

const sprintOptions = computed(() => [
  {
    title: "No sprints (default)",
    description: "Manage tasks any way you like",
    value: NO_SPRINTS_VALUE,
    selected: props.space.sprintMode === SprintMode.NONE,
    icon: BlockedIcon,
    dataTestid: "no-sprints-option",
  },
  {
    title: "Sprints",
    description: "Enable sprints to manage tasks on a cadence",
    value: SPRINTS_VALUE,
    selected: props.space.sprintMode === SprintMode.ANBA,
    icon: SprintIcon,
    dataTestid: "sprints-option",
  },
]);

const updateRecurrence = (newRecurrence: Recurrence | null) => {
  updateField({
    rolloverRecurrence: newRecurrence,
    rolloverRecursNextAt: getRecursNextAtStr(newRecurrence, props.space.rolloverRecursNextAt),
  });
};

const updateRecursNextAt = (newRecursNextAt: string | null) => {
  const recurrence = props.space.rolloverRecurrence;
  if (!recurrence) {
    return;
  }

  const recursNextAt = newRecursNextAt
    ? normalizeRecursNextAt(moment(newRecursNextAt), recurrence).toISOString()
    : null;

  updateField({
    rolloverRecursNextAt: recursNextAt,
  });
};
</script>

<template>
  <ConfirmationDialog
    ref="disableSprintsDialog"
    title="Disable sprints"
    :description="DISABLE_SPRINTS_DIALOG_DESCRIPTION"
    confirm-text="Confirm"
    cancel-text="Cancel"
    :mode="DialogMode.DELETE"
    :icon="TrashIcon"
    @confirm="updateSprintMode(SprintMode.NONE, true)" />
  <div class="flex flex-col gap-2">
    <div class="flex items-center gap-1">
      <div class="font-medium text-md">Sprints</div>
      <Tooltip :text="sprintsDescription" :placement="Placement.RIGHT_TOP" info :skidding="-10">
        <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
      </Tooltip>
    </div>
    <RadioCardGroup
      :items="sprintOptions"
      @select="(value) => updateSprintMode(value === SPRINTS_VALUE ? SprintMode.ANBA : SprintMode.NONE)" />
    <div v-if="space.sprintMode === SprintMode.ANBA" class="flex flex-col gap-4 pt-2">
      <div v-if="space.sprintMode === SprintMode.ANBA" class="flex justify-between gap-2">
        <div class="flex h-fit items-center gap-1">
          <span class="text-sm text-md">Sprint name format</span>
          <Tooltip :text="SPRINT_NAME_FORMAT_DESCRIPTION" :placement="Placement.RIGHT_TOP" info :skidding="-10">
            <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
          </Tooltip>
        </div>
        <Input
          :init-value="space.sprintNameFmt"
          placeholder="Sprint {N}"
          label="Description"
          hide-label
          hide-error
          :validate="validateIsNotEmpty"
          @change="updateSprintNameFmt" />
      </div>
      <Toggle
        :value="space.sprintReplicateOnRollover"
        label="Replicate tasks when starting next sprint"
        :description="SPRINT_REPLICATE_ON_ROLLOVER_DESCRIPTION"
        description-in-tooltip
        :size="ButtonSize.SMALL"
        text-sm
        @update="(newValue) => updateField({ sprintReplicateOnRollover: newValue })" />
    </div>

    <div v-if="space.sprintMode === SprintMode.ANBA" class="mt-4 flex justify-between gap-2">
      <span class="pt-2 text-sm text-md">Automatically start sprints</span>
      <div class="flex w-72 flex-col gap-2">
        <RecurrenceEditor
          :recurrence="space.rolloverRecurrence"
          :recurs-next-at="space.rolloverRecursNextAt"
          show-time
          @change-recurrence="(e) => updateRecurrence(e)"
          @change-recurs-next-at="(e) => updateRecursNextAt(e)" />
      </div>
    </div>
  </div>
</template>
