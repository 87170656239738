<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { ChevronDownIcon, ChevronUpIcon } from "~/icons";
import { NumberFormat } from "~/shared/enums";
import type { PropertyDefaultSize, PropertyNumber } from "~/shared/types";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  value: number | null;
  property: PropertyNumber | PropertyDefaultSize;
}>();

const emit = defineEmits<{
  update: [value: number | null];
}>();

const textInput = ref<HTMLInputElement | null>(null);

const isDollars = computed(() => props.property.adtl.format === NumberFormat.DOLLARS);
const isPercentage = computed(() => props.property.adtl.format === NumberFormat.PERCENTAGE);

const onInput = (e: Event) => {
  const input = e.target as HTMLInputElement;
  const value = input.value ? parseInt(input.value, 10) : null;
  emit("update", value);
};

const increment = () => {
  emit("update", (props.value ?? 0) + 1);
};

const decrement = () => {
  emit("update", (props.value ?? 0) - 1);
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === "." || e.key === "e") {
    e.preventDefault();
  }
};

onMounted(() => {
  setTimeout(() => {
    textInput.value?.focus();
  }, 10);
});

const id = ref(`number-editor-input-${makeUuid()}`);
</script>

<template>
  <form class="relative flex items-center" autocomplete="off" @submit.prevent>
    <span v-if="isDollars && value !== null" class="pl-2">$</span>
    <label :for="id" class="sr-only">Value</label>
    <input
      :id="id"
      ref="textInput"
      type="number"
      class="size-full border-none bg-transparent py-1.5 pr-2.5 text-sm text-hvy border-md focus-ring-none placeholder:text-vlt focus-visible:ring-gray-200 focus-visible:border-md dark:focus-visible:ring-zinc-700"
      :class="isDollars ? 'pl-1' : 'pl-2.5'"
      placeholder="None"
      :value="props.value"
      @keydown.stop="onKeyDown"
      @input="onInput" />
    <div class="absolute inset-y-0 right-2.5 flex select-none items-center gap-1.5">
      <span v-if="isPercentage && value !== null">%</span>
      <div class="flex-col justify-center">
        <div class="cursor-pointer rounded hover:bg-md" @click="increment" @keydown.enter="increment">
          <ChevronUpIcon class="size-3" />
        </div>
        <div class="cursor-pointer rounded hover:bg-md" @click="decrement" @keydown.enter="decrement">
          <ChevronDownIcon class="size-3" />
        </div>
      </div>
    </div>
  </form>
</template>
