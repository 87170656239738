<script setup lang="ts">
import { computed } from "vue";

import { DartIcon } from "~/icons";
import { usePageStore } from "~/stores";

const pageStore = usePageStore();

const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");

const iconBg = computed(() =>
  pageStore.theme === "light"
    ? "linear-gradient(180deg, #ffffff 0%, #d1d5db 100%), #ffffff"
    : "linear-gradient(180deg, #1e1e21 0%, #32323F 100%), #1e1e21"
);
</script>

<template>
  <div
    class="flex size-full justify-center app-drag"
    :class="transparentBg ? 'bg-transparent' : 'bg-[#F9FAFB] dark:bg-zinc-950'">
    <div class="flex h-full w-11/12 flex-col pb-2 pt-5 sm:w-[400px]">
      <div class="flex w-full grow flex-col justify-evenly">
        <div
          class="flex flex-col items-center gap-6 rounded-2xl border px-10 py-8 shadow-lg app-drag-none bg-std border-md dark:shadow-zinc-50/10">
          <RouterLink :to="{ name: 'login' }">
            <div
              class="dart-icon-grad-bg flex shrink-0 items-center justify-center rounded-2xl border p-3 shadow-md border-md dark:shadow-zinc-50/10">
              <DartIcon class="size-8 shrink-0" />
            </div>
          </RouterLink>
          <div class="flex w-full flex-col gap-6">
            <h3
              v-if="$slots.heading"
              class="select-none self-center hyphens-auto text-center text-2xl font-semibold text-md break-words">
              <slot name="heading" />
            </h3>

            <slot name="default" />

            <span
              v-if="$slots.footer"
              class="flex w-full items-center justify-center gap-1 text-sm font-normal text-lt">
              <slot name="footer" />
            </span>
          </div>
        </div>
        <div />
      </div>
      <div class="flex shrink-0 select-none items-center gap-3 text-xs text-gray-900/20 dark:text-zinc-50/20">
        <span class="grow">© {{ new Date().getFullYear() }} Dart</span>
        <span
          class="underline decoration-gray-900/10 app-drag-none hover:text-gray-900/30 hover:decoration-gray-900/20 dark:decoration-zinc-50/10 dark:hover:text-zinc-50/30 dark:hover:decoration-zinc-50/20">
          <a href="mailto:support@itsdart.com?subject=Trouble%20logging%20in" target="_blank" rel="noopener noreferrer">
            Contact support
          </a>
        </span>
        <span
          class="underline decoration-gray-900/10 app-drag-none hover:text-gray-900/30 hover:decoration-gray-900/20 dark:decoration-zinc-50/10 dark:hover:text-zinc-50/30 dark:hover:decoration-zinc-50/20">
          <a href="https://app.itsdart.com/legal/terms-of-use" target="_blank" rel="noopener noreferrer">
            Terms of use
          </a>
        </span>
        <span
          class="underline decoration-gray-900/10 app-drag-none hover:text-gray-900/30 hover:decoration-gray-900/20 dark:decoration-zinc-50/10 dark:hover:text-zinc-50/30 dark:hover:decoration-zinc-50/20">
          <a href="https://app.itsdart.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy policy
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dart-icon-grad-bg {
  background: v-bind(iconBg);
}
</style>
