<script setup lang="ts">
import type { ICellRendererParams } from "ag-grid-community";
import moment from "moment";
import { computed } from "vue";

import { TaskCreateIcon } from "~/icons";
import { DartboardKind, PageKind, SubtaskDisplayMode, TaskSourceType } from "~/shared/enums";
import type { RowItem } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

import { DEFAULT_BLOCK_WIDTH_PX } from "./constants";

defineProps<{
  params: ICellRendererParams<RowItem>;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);

const createTask = async () => {
  const visualization = appStore.getBaseVisualization();
  const dartboard = appStore.currentDartboardOrDefault;
  const roadmapConfig = appStore.roadmap?.roadmapConfig;
  const tasks = appStore.roadmap?.roadmapValues?.tasks ?? [];
  if (!visualization || !dartboard || !roadmapConfig) {
    return undefined;
  }

  const range = Math.ceil(DEFAULT_BLOCK_WIDTH_PX / roadmapConfig.pxPerDay);

  const taskAboveDestOrder = tasks.length ? tasks[tasks.length - 1]?.order : undefined;
  const task = await dataStore.createTask(
    "",
    dartboard.duid,
    getOrdersBetween(taskAboveDestOrder, undefined)[0],
    TaskSourceType.APP_ROADMAP_LIST,
    { startAt: moment().toISOString(), dueAt: moment().add(range, "day").toISOString() }
  );

  setTimeout(() => {
    visualization.selectAndScrollTo(task.duid);
    visualization.editTitle(task.duid);
  }, 100);

  return task;
};
</script>

<template>
  <button
    v-if="canAddTasks"
    type="button"
    class="flex size-full select-none items-center gap-2 border-b pr-2 text-xs text-lt border-lt hover:bg-lt hover:border-md print:hidden"
    :class="[notFlat ? 'pl-[48px]' : 'pl-[32px]', pageStore.hasTouch && (notFlat ? 'pl-[80px]' : 'pl-[64px]')]"
    @click.stop="createTask">
    <TaskCreateIcon class="icon-sm" />
    New task
  </button>
</template>
