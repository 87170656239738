<script setup lang="ts">
import { OpenInternalLinkIcon, XIcon } from "~/icons";

defineProps<{
  label: string;
  selected: boolean;
  disabled: boolean;
  showOpen?: boolean;
  hideClose?: boolean;
  lineThrough?: boolean;
}>();

const emit = defineEmits<{
  open: [];
  remove: [];
}>();
</script>

<template>
  <div
    class="flex items-center overflow-hidden rounded"
    :class="selected ? 'min-h-[24px] py-0.5 pl-1.5 pr-1 bg-md' : 'min-h-[20px]'">
    <span
      :title="label"
      class="text-sm text-md"
      :class="{
        'hyphens-auto pr-0.5 break-words': selected,
        truncate: !selected,
        'line-through decoration-gray-700 dark:decoration-zinc-300': lineThrough,
        'opacity-50': disabled && !selected,
      }">
      {{ label }}
    </span>
    <div
      v-if="showOpen && selected"
      class="mx-1 flex items-center justify-center rounded hover:bg-hvy"
      @click.stop="emit('open')"
      @keydown.enter.stop="emit('open')">
      <OpenInternalLinkIcon class="cursor-pointer text-md icon-xs" />
    </div>
    <div
      v-if="!hideClose && selected"
      class="flex items-center justify-center rounded hover:bg-hvy"
      @click.stop="emit('remove')"
      @keydown.enter.stop="emit('remove')">
      <XIcon class="cursor-pointer text-md icon-xs" />
    </div>
  </div>
</template>
