<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { DotsHorizontalIcon, PlusIcon, PropertiesIcon, ShowIcon } from "~/icons";
import { makeLinkToPropertySettingsRef } from "~/router/common";
import { DropdownMenuItemKind, UserRole, ViewKind } from "~/shared/enums";
import { useAppStore, useUserStore } from "~/stores";

const appStore = useAppStore();
const userStore = useUserStore();

const configDropdownSections = computed(() => [
  {
    title: "Properties",
    items: [
      {
        title: "Show more properties",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ShowIcon,
        onClick: () => {
          appStore.topbar?.openLayoutEditor();
        },
      },
      {
        title: "Property settings",
        kind: DropdownMenuItemKind.INTERNAL_LINK,
        icon: PropertiesIcon,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN),
        navigate: { to: makeLinkToPropertySettingsRef(undefined).value },
      },
      {
        title: "Create a property",
        kind: DropdownMenuItemKind.INTERNAL_LINK,
        icon: PlusIcon,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.ADMIN),
        navigate: { to: makeLinkToPropertySettingsRef(undefined).value },
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu
    v-if="appStore.currentPage?.kind !== ViewKind.TRASH"
    :sections="configDropdownSections"
    :distance="2"
    class="px-1">
    <button type="button" class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md">
      <span class="sr-only">Property settings</span>
      <DotsHorizontalIcon class="text-vlt icon-sm" />
    </button>
  </DropdownMenu>
</template>
