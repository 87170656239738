import { FormKind, IconKind, PageKind, PropertyKind } from "~/shared/enums";
import type { Form, FormField, FormFieldUpdate, FormUpdate, Property } from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid, makeEmptyLexicalState, randomSample } from "~/utils/common";
import { formComparator, formFieldComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  formList: Form[];
  getFormByDuid: (duid: string) => Form | undefined;
  formFieldList: FormField[];
  getFormFieldByDuid: (duid: string) => FormField | undefined;
  getFormFieldRelatedToFormOrdered: (formDuid: string) => FormField[];
};

export type Actions = {
  /* Create a new form. */
  createForm: (order: string, partialForm?: Partial<Form>, options?: { awaitBackend?: boolean }) => Promise<Form>;
  /* Update an existing form. */
  updateForm: (update: FormUpdate, options?: { awaitBackend?: boolean }) => Promise<Form | undefined>;
  /** Delete a form. */
  deleteForm: (form: Form, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort forms.
   * @PRIVATE */
  $sortForms: () => void;
  /** Set forms internally.
   * @PRIVATE */
  $setForms: (forms: Form[]) => void;
  /** Create or update form from WS.
   * @PRIVATE */
  $createOrUpdateForm: (form: Form) => void;
  /** Delete form from WS.
   * @PRIVATE */
  $deleteForm: (form: Form) => void;
  /* Create a new form field. */
  createFormField: (
    form: Form,
    property: Property,
    order: string,
    options?: { awaitBackend?: boolean }
  ) => Promise<FormField>;
  /* Update an existing form field. */
  updateFormField: (update: FormFieldUpdate, options?: { awaitBackend?: boolean }) => Promise<FormField | undefined>;
  /** Delete a form field. */
  deleteFormField: (formField: FormField, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort form fields.
   * @PRIVATE */
  $sortFormFields: () => void;
  /** Set form fields internally.
   * @PRIVATE */
  $setFormFields: (formFields: FormField[]) => void;
  /** Create or update form field from WS.
   * @PRIVATE */
  $createOrUpdateFormField: (formField: FormField) => void;
  /** Delete form field from WS.
   * @PRIVATE */
  $deleteFormField: (formField: FormField) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  formList() {
    return Array.from(this._duidsToForms.values());
  },
  getFormByDuid() {
    return (duid) => this._duidsToForms.get(duid);
  },
  formFieldList() {
    return Array.from(this._duidsToFormFields.values());
  },
  getFormFieldByDuid() {
    return (duid) => this._duidsToFormFields.get(duid);
  },
  getFormFieldRelatedToFormOrdered() {
    return (formDuid) => this.formFieldList.filter((e) => e.formDuid === formDuid);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createForm(order, partialForm, options = {}) {
    const { awaitBackend = false } = options;
    const appStore = this.$useAppStore();

    const form: Form = {
      pageKind: PageKind.FORM,
      duid: makeDuid(),
      kind: FormKind.FORM,
      hidden: true,
      public: false,
      order,
      title: "Form",
      description: "",
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      ...partialForm,
    };

    this.$createOrUpdateForm(form);

    const defaultFormFields: FormField[] = (
      [
        [this.defaultTitleProperty, null],
        [this.defaultDartboardProperty, this.defaultDartboard?.duid ?? null],
        [this.defaultKindProperty, this.defaultTaskKind.duid],
        [this.defaultStatusProperty, this.defaultDefaultUnstartedStatus.duid],
      ] satisfies [Property, string | null][]
    ).map(([property, defaultValue], i) => ({
      duid: makeDuid(),
      formDuid: form.duid,
      propertyDuid: property.duid,
      locked: true,
      order: String.fromCharCode(i + 2),
      hidden: false,
      required: true,
      label: "",
      default: {
        value: defaultValue,
      },
    }));

    defaultFormFields.forEach((e) => this.$createOrUpdateFormField(e));

    const backendAction = this.$backend.form.create(form, defaultFormFields);
    if (awaitBackend) {
      await backendAction;
    }
    return form;
  },
  async updateForm(update, options = {}) {
    const { awaitBackend = false } = options;
    const form = this.getFormByDuid(update.duid);
    if (!form) {
      return undefined;
    }

    Object.assign(form, update);
    this.$sortForms();

    const backendAction = this.$backend.form.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return form;
  },
  async deleteForm(form, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteForm(form);

    const backendAction = this.$backend.form.delete(form.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortForms() {
    this._duidsToForms = new Map([...this._duidsToForms.entries()].sort((a, b) => formComparator(a[1], b[1])));
  },
  $setForms(forms) {
    this._duidsToForms = new Map(forms.map((e) => [e.duid, { ...e, pageKind: PageKind.FORM, kind: FormKind.FORM }]));

    this.$sortForms();
  },
  $createOrUpdateForm(form) {
    const currentForm = this.getFormByDuid(form.duid);

    if (!currentForm) {
      this._duidsToForms.set(form.duid, form);
    } else {
      Object.assign(currentForm, form);
    }

    this.$sortForms();
  },
  $deleteForm(form) {
    this._duidsToForms.delete(form.duid);
  },
  async createFormField(form, property, order, options = {}) {
    const { awaitBackend = false } = options;
    const formField: FormField = {
      duid: makeDuid(),
      formDuid: form.duid,
      propertyDuid: property.duid,
      locked: false,
      order,
      hidden: false,
      required: true,
      label: "",
      default: { value: null },
    };

    switch (property.kind) {
      case PropertyKind.DEFAULT_DESCRIPTION: {
        formField.default = { value: makeEmptyLexicalState() };
        break;
      }
      case PropertyKind.DEFAULT_ATTACHMENTS: {
        formField.required = false;
        formField.default = { value: [] };
        break;
      }
      case PropertyKind.DEFAULT_ASSIGNEES:
      case PropertyKind.DEFAULT_TAGS:
      case PropertyKind.MULTISELECT:
      case PropertyKind.USER: {
        formField.default = { value: [] };
        break;
      }
      default: {
        break;
      }
    }

    this.$createOrUpdateFormField(formField);

    const backendAction = this.$backend.formField.create(formField);
    if (awaitBackend) {
      await backendAction;
    }
    return formField;
  },
  async updateFormField(update, options = {}) {
    const { awaitBackend = false } = options;
    const formField = this.getFormFieldByDuid(update.duid);
    if (!formField) {
      return undefined;
    }

    Object.assign(formField, update);
    this.$sortFormFields();

    const backendAction = this.$backend.formField.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return formField;
  },
  async deleteFormField(formField, options = {}) {
    const { awaitBackend = false } = options;

    this.$deleteFormField(formField);

    const backendAction = this.$backend.formField.delete(formField.duid);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortFormFields() {
    this._duidsToFormFields = new Map(
      [...this._duidsToFormFields.entries()].sort((a, b) => formFieldComparator(a[1], b[1]))
    );
  },
  $setFormFields(formFields) {
    this._duidsToFormFields = new Map(formFields.map((e) => [e.duid, e]));
    this.$sortFormFields();
  },
  $createOrUpdateFormField(formField) {
    const currentFormField = this.getFormFieldByDuid(formField.duid);

    if (!currentFormField) {
      this._duidsToFormFields.set(formField.duid, formField);
    } else {
      Object.assign(currentFormField, formField);
    }

    this.$sortFormFields();
  },
  $deleteFormField(formField) {
    this._duidsToFormFields.delete(formField.duid);
  },
};

export { actions, getters };
