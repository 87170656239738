<script setup lang="ts">
import { useWebNotification } from "@vueuse/core";
import { useSound } from "@vueuse/sound";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import ProgressBar from "~/components/dumb/ProgressBar.vue";
import { ForwardIcon, ResetIcon, SettingsIcon } from "~/icons";
import { ButtonSize, ButtonStyle, IconSize, Theme } from "~/shared/enums";
import type { PomodoroBackground as PomodoroBackgroundType } from "~/shared/types";
import { usePageStore } from "~/stores";
import { lsGet, lsSet } from "~/utils/localStorageManager";
import { COLOR_BACKGROUNDS, GRADIENT_BACKGROUNDS, IMAGE_BACKGROUNDS } from "~/views/pomodoro/constants";
import PomodoroBackground from "~/views/pomodoro/PomodoroBackground.vue";
import PomodoroSettingsModal from "~/views/pomodoro/PomodoroSettingsModal.vue";

enum PomodoroStageKind {
  POMODORO = "POMODORO",
  SHORT_BREAK = "SHORT_BREAK",
  LONG_BREAK = "LONG_BREAK",
}

type PomodoroStageDefinition = {
  value: PomodoroStageKind;
  name: string;
  duration: number;
};

const pageStore = usePageStore();

pageStore.pageLoaded = true;

const settingsModal = ref<InstanceType<typeof PomodoroSettingsModal> | null>(null);

/* Configuration */
const pomodoroStageToDefinitionMap = new Map<PomodoroStageKind, PomodoroStageDefinition>([
  [
    PomodoroStageKind.POMODORO,
    {
      value: PomodoroStageKind.POMODORO,
      name: "Focus",
      duration: 25 * 60,
    },
  ],
  [PomodoroStageKind.SHORT_BREAK, { value: PomodoroStageKind.SHORT_BREAK, name: "Short break", duration: 5 * 60 }],
  [PomodoroStageKind.LONG_BREAK, { value: PomodoroStageKind.LONG_BREAK, name: "Long break", duration: 15 * 60 }],
]);
const longBreakInterval = 4;
const autoRunBreaks = false;
const autoRunPomodoros = false;
const soundVolume = 1;
const notificationEnabled = true;

const getStageDefinition = (stage: PomodoroStageKind) => pomodoroStageToDefinitionMap.get(stage)!;

/* State */
const stage = ref<PomodoroStageKind>(PomodoroStageKind.POMODORO);
const timerRunning = ref(false);
const timeRemaining = ref<number>(getStageDefinition(stage.value).duration);
const stageInterval = ref<number>(1);

const percentageRemaining = computed(() => (1 - timeRemaining.value / getStageDefinition(stage.value).duration) * 100);

const showResetButton = computed(
  () => timerRunning.value || timeRemaining.value !== getStageDefinition(stage.value).duration
);

const timerText = computed(() => {
  const minutes = Math.floor(timeRemaining.value / 60);
  const seconds = timeRemaining.value % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
});
watch(timerText, (value) => {
  pageStore.pageTitle = `${value} - ${getStageDefinition(stage.value).name}`;
  document.title = pageStore.pageTitle;
});

/* Sound & Notification */
const soundNotification = useSound("/sound/notification.wav", { volume: soundVolume });
const webNotification = useWebNotification({
  title: "Dart - Pomodoro",
  dir: "auto",
  lang: "en",
  renotify: false,
  tag: "test",
});

/* Timer */
let timeout: ReturnType<typeof setTimeout> | undefined;

const onTimerEnd = () => {
  clearTimeout(timeout);

  // Update stage and interval
  if (stage.value === PomodoroStageKind.POMODORO) {
    stageInterval.value += 1;
    if (stageInterval.value % longBreakInterval === 0) {
      stage.value = PomodoroStageKind.LONG_BREAK;
    } else {
      stage.value = PomodoroStageKind.SHORT_BREAK;
    }
  } else {
    stage.value = PomodoroStageKind.POMODORO;
  }

  timeRemaining.value = getStageDefinition(stage.value).duration;
  timerRunning.value = autoRunBreaks || (stage.value === PomodoroStageKind.POMODORO && autoRunPomodoros);
};

const onTimeout = () => {
  clearTimeout(timeout);

  timeRemaining.value = Math.max(0, timeRemaining.value - 1);

  if (timeRemaining.value === 0) {
    onTimerEnd();

    // Play sound and show notification
    if (soundVolume > 0) {
      soundNotification.play();
    }
    if (notificationEnabled && webNotification.isSupported.value) {
      webNotification.show({ body: "Time's up!" });
    }
    return;
  }

  timeout = setTimeout(onTimeout, 1000);
};

const toggle = () => {
  if (timerRunning.value) {
    timerRunning.value = false;
    clearTimeout(timeout);
  } else {
    timerRunning.value = true;
    timeout = setTimeout(onTimeout, 1000);
  }
};

const forward = () => {
  timeRemaining.value = 0;
  onTimerEnd();
};

const setStage = (newStage: PomodoroStageKind) => {
  stage.value = newStage;
  timerRunning.value = false;
  timeRemaining.value = getStageDefinition(stage.value).duration;
  clearTimeout(timeout);
};

const reset = () => setStage(stage.value);

const selectedBackground = ref<PomodoroBackgroundType>(
  IMAGE_BACKGROUNDS[Math.round(Math.random() * (IMAGE_BACKGROUNDS.length - 1))]
);

const setSelectedBackground = (bg: PomodoroBackgroundType) => {
  selectedBackground.value = bg;
  lsSet("pomodoroBackground", bg.fileNameOrColorHex);
};

const loadBackground = () => {
  const fileNameOrColorHex = lsGet("pomodoroBackground");
  if (fileNameOrColorHex) {
    selectedBackground.value =
      [...IMAGE_BACKGROUNDS, ...COLOR_BACKGROUNDS, ...GRADIENT_BACKGROUNDS].find(
        (bg) => bg.fileNameOrColorHex === fileNameOrColorHex
      ) ?? selectedBackground.value;
  }
};

onMounted(() => {
  loadBackground();
  pageStore.updateTheme(true, Theme.LIGHT);
});

onUnmounted(() => {
  pageStore.updateTheme();
  clearTimeout(timeout);
});
</script>

<template>
  <PomodoroBackground
    :bg="selectedBackground"
    disable-hover
    class="dart-font-nunito relative flex h-screen w-full select-none flex-col items-center justify-evenly gap-12 p-12 text-lt">
    <PomodoroSettingsModal ref="settingsModal" :selected-bg="selectedBackground" @select="setSelectedBackground" />
    <span
      class="absolute right-10 top-10 cursor-pointer rounded-full bg-white/30 p-2 text-gray-900 shadow-2xl backdrop-blur-xl hover:bg-white/40"
      @click="settingsModal?.open()"
      @keydown.enter="settingsModal?.open()">
      <SettingsIcon class="size-5" />
    </span>
    <div
      class="flex h-80 w-96 flex-col items-center justify-evenly rounded-xl border border-white/20 bg-white/30 shadow-2xl backdrop-blur-xl sm:w-[432px]">
      <div class="flex w-72 justify-evenly gap-1 rounded-full bg-white/40 sm:w-[352px]">
        <div
          v-for="stageDefinition in pomodoroStageToDefinitionMap.values()"
          :key="stageDefinition.value"
          class="w-full cursor-pointer rounded-full py-2 text-center text-sm text-gray-900"
          :class="stageDefinition.value === stage ? 'bg-white' : 'hover:bg-white/50'"
          @click="setStage(stageDefinition.value)"
          @keydown.enter="setStage(stageDefinition.value)">
          {{ stageDefinition.name }}
        </div>
      </div>

      <div class="text-8xl font-extrabold tabular-nums text-gray-900">
        {{ timerText }}
      </div>

      <div class="h-2 w-full px-16">
        <ProgressBar :value="percentageRemaining" no-content bg-color="bg-white" />
      </div>

      <div class="flex w-full items-center justify-center gap-4 px-12">
        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="ResetIcon"
          :icon-size="IconSize.M"
          a11y-label="Reset"
          borderless
          class="!rounded-full !bg-white/80 !p-1.5 !font-extrabold !text-gray-700 hover:!bg-white/70"
          :class="showResetButton ? 'opacity-100' : 'pointer-events-none opacity-0'"
          @click="reset" />

        <Button
          :btn-style="ButtonStyle.PRIMARY"
          :size="ButtonSize.LARGE"
          class="!w-32 !rounded-full !bg-white/80 !py-1.5 !font-extrabold !text-gray-700 hover:!bg-white/70"
          :text="timerRunning ? 'Pause' : 'Start'"
          block
          @click="toggle" />

        <Button
          :btn-style="ButtonStyle.CHIP"
          :icon="ForwardIcon"
          :icon-size="IconSize.M"
          borderless
          a11y-label="Forward"
          class="!rounded-full !bg-white/80 !p-1.5 !font-extrabold !text-gray-700 hover:!bg-white/70"
          :class="timerRunning ? 'opacity-100' : 'pointer-events-none opacity-0'"
          @click="forward" />
      </div>
    </div>

    <!-- Dummy div for centering -->
    <div />
  </PomodoroBackground>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

.dart-font-nunito {
  font-family: "Nunito", sans-serif;
}
</style>
