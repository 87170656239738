<script setup lang="ts">
import { computed, ref } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import OptionComponent from "~/components/options/Option.vue";
import OptionDropdownItem from "~/components/options/OptionDropdownItem.vue";
import { colorsByTheme } from "~/constants/style";
import { PlusIcon, SelectFieldIcon } from "~/icons";
import { EditorMode, Placement } from "~/shared/enums";
import type { Option, PropertySelect, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeValidateOptionTitle } from "~/utils/common";

type Value = PropertyValueForKind<PropertySelect>;

const props = defineProps<{
  property: PropertySelect;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
  onAfterClose?: (value: Value) => void;
}>();

const emit = defineEmits<{
  update: [value?: Value];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const dropdownMenu = ref<InstanceType<typeof MultiselectDropdownMenu> | null>(null);

const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isPropertyDefaultMode = computed(() => props.editorMode === EditorMode.PROPERTY_DEFAULT);
const isFormOrDefaultPropertyMode = computed(() => isFormMode.value || isPropertyDefaultMode.value);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const optionDuids = computed(() => {
  if (isFormOrDefaultPropertyMode.value) {
    return props.value ? [props.value] : [];
  }

  return [...new Set(props.tasks.map((e) => getPropertyValueFromTask(props.property, e)))].filter((e) => !!e);
});

const optionItems = computed(() => {
  const selectedOptionDuids = isFormOrDefaultPropertyMode.value
    ? props.value
      ? [props.value]
      : []
    : [...new Set(selectedTasks.value.map((e) => getPropertyValueFromTask(props.property, e)))];

  return dataStore
    .getOptionList(props.property)
    .filter((e) => !e.parentDuid || selectedOptionDuids.includes(e.duid))
    .map((option) => {
      const descendants = dataStore.getOptionList(props.property).filter((e) => e.parentDuid === option.duid);
      return {
        value: option.duid,
        label: option.title,
        selected: selectedOptionDuids.includes(option.duid),
        alwaysShowInList: descendants.some((descendant) => !selectedOptionDuids.includes(descendant.duid)),
        component: OptionDropdownItem,
        componentArgs: { option, selectedOptionDuids, isNested: false, showDescendants: true },
      };
    });
});

const activeOptions = computed(() =>
  optionDuids.value
    .filter((duid) => duid !== null)
    .map((duid) => duid && dataStore.getOptionByDuid(duid))
    .filter((option): option is Option => !!option)
);

const updateTasks = (value: Value) => {
  if (isFormOrDefaultPropertyMode.value) {
    emit("update", value);
    return;
  }

  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, value),
    }))
  );

  emit("update");
};

const onAdd = (optionDuid: Value) => {
  updateTasks(optionDuid);
};

const onRemove = () => {
  updateTasks(null);
};

// TODO transactionify
const onCreate = async (optionDuid: Exclude<Value, null>) => {
  const option = await dataStore.createOption(optionDuid, props.property);
  if (!option) {
    return;
  }

  updateTasks(option.duid);
};

const validateOptionTitle = computed(() =>
  makeValidateOptionTitle((e) => dataStore.getOptionByTitle(e, props.property))
);

const handleAfterClose = () => {
  props.onAfterClose?.(optionDuids.value[0]);
};

defineExpose({
  open: () => dropdownMenu.value?.open(),
});
</script>

<template>
  <MultiselectDropdownMenu
    ref="dropdownMenu"
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :items="optionItems"
    :placeholder="`Add an option...`"
    :block="isFormOrDefaultPropertyMode"
    :height-block="isFormOrDefaultPropertyMode"
    :show-on-hover="isContextMenuMode"
    :cover="!isContextMenuMode && !isFormOrDefaultPropertyMode"
    :skidding="isFormOrDefaultPropertyMode ? -1 : undefined"
    :distance="isFormOrDefaultPropertyMode ? 2 : undefined"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :validate="validateOptionTitle"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    :on-after-close="handleAfterClose"
    @remove="onRemove"
    @add="onAdd"
    @create="onCreate">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="SelectFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isFormOrDefaultPropertyMode"
      :text="`Change ${property.title.toLowerCase()}`"
      :block="isListMode || isFormOrDefaultPropertyMode"
      :height-block="isListMode || isFormOrDefaultPropertyMode"
      class="w-full">
      <div
        v-if="isChipMode"
        class="flex items-center gap-1 rounded border px-1 py-0.5 text-md border-oncolor hover:bg-md">
        <SelectFieldIcon
          :class="{
            'text-primary-base': isChipMode && optionDuids.length !== 0,
          }"
          class="icon-sm" />
        <span v-if="optionDuids.length === 0" class="max-w-xs select-none truncate">
          {{ property.title }}
        </span>
        <OptionComponent
          v-for="option in activeOptions"
          v-else
          :key="option.duid"
          :option="option"
          show-ancestors
          truncate
          class="max-w-xs" />
      </div>

      <div
        v-else
        class="group/select flex size-full cursor-pointer items-center gap-1"
        :class="{
          'overflow-x-hidden': isListMode,
          'flex-wrap rounded py-0.5': !isListMode,
          'hover:bg-md': !isListMode && !isTaskDetailMode && !isFormOrDefaultPropertyMode,
          'px-1': activeOptions.length !== 0,
          'justify-start px-2 py-1 hover:bg-lt': isTaskDetailMode,
          'truncate px-2 text-sm hover:bg-lt': isFormOrDefaultPropertyMode,
        }">
        <OptionComponent
          v-for="option in activeOptions"
          :key="option.duid"
          show-ancestors
          :option="option"
          :truncate="!isTaskDetailMode" />
        <template v-if="!isListMode && !activeOptions.length">
          <div v-if="isTaskDetailMode || isFormOrDefaultPropertyMode" class="select-none truncate text-left text-vlt">
            {{ isTaskDetailMode ? "None" : `Set ${property.title.toLowerCase()}` }}
          </div>
          <PlusIcon v-else class="icon-sm" />
        </template>

        <div
          v-if="isListMode && !activeOptions.length"
          class="flex size-full items-center justify-center text-transparent group-hover/select:text-vlt">
          <SelectFieldIcon class="icon-xs" />
        </div>
      </div>
    </Tooltip>
  </MultiselectDropdownMenu>
</template>
