<script setup lang="ts">
import { computed, onUnmounted, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { getPropertyConfig } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { NO_SETTINGS_PROPERTY_KIND_SET } from "~/constants/property";
import { PlusIcon } from "~/icons";
import { getQueryParam, makeLinkToPropertySettingsRef } from "~/router/common";
import { ButtonSize, ButtonStyle, DropdownMenuItemKind, Entitlement, Placement, PropertyKind } from "~/shared/enums";
import type { DropdownMenuSection, Property } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getItemCountText, getNextTitleInSequence } from "~/utils/common";
import { makePropertyComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";

import PropertiesSidebarItem from "./PropertiesSidebarItem.vue";

const emit = defineEmits<{
  createProperty: [];
}>();

const router = useRouter();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const allProperties = computed(() => {
  const comparator = makePropertyComparator([]);
  return dataStore.propertyList
    .map((p) => ({ ...p, config: getPropertyConfig(p.kind) }))
    .sort((a, b) => (a.hidden || b.hidden ? 1 : comparator(a.duid, b.duid)));
});

const defaultUnremovedProperties = computed(() =>
  allProperties.value.filter(
    (property) => property.config.isDefault && !NO_SETTINGS_PROPERTY_KIND_SET.has(property.kind)
  )
);

/* Custom properties */
const customProperties = computed(() => allProperties.value.filter((property) => !property.config.isDefault));
const maxCustomProperties = computed(() => tenantStore.getEntitlementValue(Entitlement.MAX_CUSTOM_PROPERTIES));
const upgradeRequired = computed(() => customProperties.value.length >= maxCustomProperties.value);

const getCustomPropertyProps = (property: Property) => ({ property });

const createCustomProperty = async (kind: PropertyKind, title: string) => {
  if (upgradeRequired.value) {
    return;
  }

  const indexOfLastDefaultProperty = allProperties.value.map((e) => e.config.isDefault).lastIndexOf(true);
  const bottomDefaultPropertyOrder = allProperties.value[indexOfLastDefaultProperty].order;
  const firstCustomPropertyOrder = allProperties.value[indexOfLastDefaultProperty + 1]?.order;
  const order = getOrdersBetween(bottomDefaultPropertyOrder, firstCustomPropertyOrder)[0];
  const property = await dataStore.createProperty(kind, order, {
    title: getNextTitleInSequence(
      title,
      customProperties.value.map((e) => e.title),
      { noCopy: true }
    ),
  });

  router.replace(makeLinkToPropertySettingsRef(property.duid).value);

  setTimeout(() => {
    emit("createProperty");
  }, 100);
};

const moveCustomProperty = (_: string, property: Property) => {
  dataStore.updateProperty({ duid: property.duid, order: property.order });
};

const customPropertiesSections = computed<DropdownMenuSection[]>(() => [
  {
    title: "Add custom property",
    items: Object.values(PropertyKind)
      .map((e) => getPropertyConfig(e))
      .filter((e) => !e.isDefault)
      .map((config) => ({
        title: config.label,
        kind: DropdownMenuItemKind.BUTTON,
        icon: config.icon,
        onClick: () => createCustomProperty(config.kind, config.label),
      })),
  },
]);

// Default to the first property if no property is selected
watchEffect(() => {
  const propertyDuid = getQueryParam("property");
  const property = propertyDuid ? dataStore.getPropertyByDuid(propertyDuid) : undefined;
  if (!property || NO_SETTINGS_PROPERTY_KIND_SET.has(property.kind)) {
    const propertyKind = (getQueryParam("propertyKind") ?? "").toLowerCase();
    const destProperty =
      defaultUnremovedProperties.value.find((e) => {
        let kind = e.kind.toLowerCase();
        if (kind.startsWith("default: ")) {
          kind = kind.slice(9);
        }
        return kind === propertyKind;
      }) ??
      defaultUnremovedProperties.value.find((e) => !e.hidden) ??
      defaultUnremovedProperties.value[0];
    const newRoute = makeLinkToPropertySettingsRef(destProperty.duid).value;
    if ("propertyKind" in newRoute.query) {
      delete newRoute.query.propertyKind;
    }
    setTimeout(() => {
      router.replace(newRoute);
    });
  }
});

// Remove query param on unmount.
onUnmounted(() => {
  router.replace({ query: { ...router.currentRoute.value.query, property: undefined } });
});
</script>

<template>
  <div class="h-full w-56 overflow-y-auto border-r px-2.5 pb-6 pt-3.5 bg-std border-lt">
    <!-- Default properties -->
    <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Properties</span>
    <div class="mb-6 mt-1 space-y-px">
      <PropertiesSidebarItem v-for="item in defaultUnremovedProperties" :key="item.duid" :property="item" />
    </div>

    <!-- Custom properties -->
    <div class="flex gap-2 pl-2.5" :class="customProperties.length === 0 && 'flex-col'">
      <div class="flex min-h-5 w-full items-center">
        <span class="flex-1 select-none text-xs font-semibold uppercase text-vlt">Custom properties</span>
      </div>

      <div class="-mt-1 w-fit">
        <UpgradeConfirmationDialog
          :disabled="!upgradeRequired"
          :feature-action="`create more than ${getItemCountText(maxCustomProperties, 'custom property', { noSpecial: true, unusualPlural: 'custom properties' })}`">
          <DropdownMenu
            :sections="customPropertiesSections"
            :placement="Placement.RIGHT_TOP"
            :distance="0"
            :width-pixels="180"
            :disabled="upgradeRequired">
            <Tooltip text="Create a new custom property">
              <button
                v-if="customProperties.length > 0"
                type="button"
                class="items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-md">
                <span class="sr-only">Create a new custom property</span>
                <PlusIcon class="text-vlt icon-sm" />
              </button>
              <Button
                v-else
                :btn-style="ButtonStyle.SECONDARY"
                :icon="PlusIcon"
                borderless
                text="New property"
                :size="ButtonSize.SMALL" />
            </Tooltip>
          </DropdownMenu>
        </UpgradeConfirmationDialog>
      </div>
    </div>

    <DragArea
      v-if="customProperties.length > 0"
      group="custom-properties"
      category="custom-properties"
      class="mt-1 !h-auto min-h-5 flex-1 gap-px rounded"
      drop-area-classes="bg-md/50"
      :items="customProperties"
      :component="PropertiesSidebarItem"
      :get-component-props="getCustomPropertyProps"
      @change="moveCustomProperty" />
  </div>
</template>
