<script setup lang="ts">
import { useMediaQuery } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";

import Avatar from "~/components/dumb/Avatar.vue";
import type { Comment, Task } from "~/shared/types";
import { useUserStore } from "~/stores";

import CommentEditor from "../text/CommentEditor.vue";
import Line from "./Line.vue";

const props = defineProps<{
  task: Task;
  threadComment?: Comment;
  addingDefault?: boolean;
}>();

const emit = defineEmits<{
  editLast: [];
  cancelAdd: [];
  afterCreate: [];
}>();

const userStore = useUserStore();

const commentEditor = ref<InstanceType<typeof CommentEditor> | null>(null);
const wrapper = ref<HTMLDivElement | null>(null);

const adding = ref(props.addingDefault);
const inThread = computed(() => !!props.threadComment);

const onAfterCreate = () => {
  adding.value = false;
  emit("afterCreate");
};

const focus = () => {
  adding.value = true;
  nextTick(() => commentEditor.value?.focus());
};

const isPrinting = useMediaQuery("print");

watch(
  () => props.threadComment,
  (newComment) => {
    if (newComment) {
      nextTick(() => {
        if (commentEditor.value) {
          wrapper.value?.scrollIntoView();
        }
      });
    }
  },
  { immediate: true }
);

watch(
  () => props.addingDefault,
  (v) => v && focus(),
  { immediate: true }
);

defineExpose({
  focus,
});
</script>

<template>
  <div v-if="!isPrinting" ref="wrapper" class="flex gap-[3px] pl-2.5 pr-[9px]">
    <div class="flex gap-2">
      <Line v-if="inThread" />
      <Avatar
        :abrev="userStore.abrev"
        circle
        :color-hex="userStore.colorHex"
        :image-url="userStore.imageUrl"
        img-border
        class="mt-1.5 icon-lg" />
    </div>
    <div class="flex flex-1 items-center py-1">
      <CommentEditor
        v-if="!inThread || adding"
        ref="commentEditor"
        :task="task"
        :thread-comment="threadComment"
        @edit-last="emit('editLast')"
        @after-create="onAfterCreate" />
      <button
        v-else
        class="w-full cursor-text rounded border border-transparent p-1 px-1.5 text-start text-sm/6 font-normal text-vlt hover:border-md"
        type="button"
        @click="focus">
        Add a reply
      </button>
    </div>
  </div>
</template>
