<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import { getPropertyConfig, getPropertyValueStr } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import CollapsedColumn from "~/components/visualization/board/CollapsedColumn.vue";
import Column from "~/components/visualization/board/Column.vue";
import PanArea from "~/components/visualization/PanArea.vue";
import { PlusIcon } from "~/icons";
import { makeLinkToPropertySettingsRef, makeLinkToSettingsRef } from "~/router/common";
import { ButtonStyle, IconSize, PropertyKind, TaskSourceType, UserRole } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

type IColumn = InstanceType<typeof Column>;

const USER_PROPERTY_KINDS = new Set([
  PropertyKind.DEFAULT_ASSIGNEES,
  PropertyKind.DEFAULT_CREATED_BY,
  PropertyKind.DEFAULT_UPDATED_BY,
  PropertyKind.USER,
]);
const PROPERTY_KINDS_WITH_MODIFIABLE_COLUMNS = new Set([
  PropertyKind.DEFAULT_STATUS,
  PropertyKind.DEFAULT_TAGS,
  PropertyKind.SELECT,
  PropertyKind.MULTISELECT,
  PropertyKind.STATUS,
  ...USER_PROPERTY_KINDS,
]);

const currentInstance = getCurrentInstance();
const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const columnRefs = ref<Map<string, IColumn>>(new Map());

const setColumnRef = (id: string, el: IColumn | null) => {
  if (!el) {
    columnRefs.value.delete(id);
    return;
  }
  columnRefs.value.set(id, el);
};

const groupByDefinition = computed(() => appStore.groupByDefinition);
const propertyConfig = computed(() => getPropertyConfig(groupByDefinition.value.property.kind));
const columns = computed(() => {
  if (!groupByDefinition.value) {
    return [];
  }

  const collapsedColumnIds = new Set(appStore.collapsedGroups);

  return groupByDefinition.value.groups
    .filter(
      (group) =>
        !appStore.hideEmptyGroups || appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value))?.length
    )
    .map((group) => ({
      ...group,
      collapsed: collapsedColumnIds.has(group.id),
      tasks: appStore.groupByValueToTasksMap.get(getPropertyValueStr(group.value)) ?? [],
    }));
});

const uncollapsedColumns = computed(() => columns.value.filter((e) => !e.collapsed));
const collapsedColumns = computed(() => columns.value.filter((e) => e.collapsed));

const createTask = async (columnId?: string, below: boolean = false) => {
  if (!groupByDefinition.value) {
    return;
  }
  const columnIdNorm = columnId ?? columns.value[0].id;
  const column = columns.value.find((e) => e.id === columnIdNorm);
  const dartboard = appStore.currentDartboardOrDefault;
  if (!column || !dartboard) {
    return;
  }

  const taskAboveDestOrder = below ? column.tasks[column.tasks.length - 1]?.order : column.tasks[0]?.order;

  /* Assign the value of the column to the task */
  const { property } = groupByDefinition.value;
  const partialTask = propertyConfig.value.getPartialTask(property, {}, column.value);

  const task = await dataStore.createTask(
    "",
    dartboard.duid,
    below ? getOrdersBetween(taskAboveDestOrder, undefined)[0] : getOrdersBetween(undefined, taskAboveDestOrder)[0],
    TaskSourceType.APP_BOARD,
    partialTask
  );
  columnRefs.value.get(columnIdNorm)?.editTask(task.duid, below);
};

const startEditingTask = (taskDuid: string) => {
  // TODO this is basically a visualization function. also could be much more efficient if we could find it directly
  const column = columns.value.find((e) => e.tasks.some((task) => task.duid === taskDuid));
  if (!column) {
    return;
  }
  columnRefs.value.get(column.id)?.editTask(taskDuid);
};

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

const property = computed(() => groupByDefinition.value?.property);
const openPropertySettings = () => {
  if (property.value === null) {
    return;
  }
  if (USER_PROPERTY_KINDS.has(property.value.kind)) {
    router.replace(makeLinkToSettingsRef("teammates").value);
    return;
  }
  router.replace(makeLinkToPropertySettingsRef(property.value.duid).value);
};

if (!pageStore.isPublicView) {
  pageStore.pageLoaded = true;
}

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.board = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.board = null;
});

defineExpose({
  createTask,
  startEditingTask,
});
</script>

<template>
  <div class="absolute inset-0 z-auto flex size-full flex-col bg-std">
    <PanArea class="flex gap-5 overflow-y-hidden p-4" @click="deselectAll" @keydown.enter="deselectAll">
      <Column
        v-for="column in uncollapsedColumns"
        :key="column.id"
        :ref="(el) => setColumnRef(column.id, el as IColumn | null)"
        :column="column"
        :tasks="column.tasks"
        @create-task="(below) => createTask(column.id, below)" />
      <div v-if="collapsedColumns.length !== 0" class="flex h-full shrink-0 flex-col gap-5 overflow-y-auto">
        <CollapsedColumn v-for="column in collapsedColumns" :key="column.id" :column="column" />
      </div>
      <Tooltip
        v-if="
          userStore.isRoleGreaterOrEqual(UserRole.ADMIN) && PROPERTY_KINDS_WITH_MODIFIABLE_COLUMNS.has(property.kind)
        "
        :text="`Add ${property.title.toLowerCase()}`"
        class="-ml-2 h-fit">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="PlusIcon"
          :icon-size="IconSize.S"
          borderless
          class="bg-lt hover:bg-md print:hidden"
          :a11y-label="`Add ${property.title.toLowerCase()}`"
          @click="openPropertySettings" />
      </Tooltip>
    </PanArea>
    <PageEmptyState
      v-if="appStore.filteredAndSortedTasksInPage.length === 0"
      :is-filter-mode="appStore.allTasksInPage.length > 0" />
  </div>
</template>
