<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { getPropertyConfig } from "~/common/properties";
import type DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import type MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { NO_DROPDOWN_PROPERTY_KIND_SET } from "~/constants/property";
import { ChevronDownIcon, XIcon } from "~/icons";
import { PropertyKind } from "~/shared/enums";
import type { Dartboard, PropertyValue } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  dartboard: Dartboard;
  propertyDuid: string;
  value: PropertyValue;
}>();

const dataStore = useDataStore();

const editor = ref<InstanceType<typeof MultiselectDropdownMenu> | InstanceType<typeof DropdownMenu> | null>(null);

const property = computed(() => dataStore.getPropertyByDuid(props.propertyDuid));
const propertyConfig = computed(() => getPropertyConfig(property.value?.kind as PropertyKind));

const propertyDefaultConfig = computed(() => propertyConfig.value?.propertyDefault?.());

const removeDefaultProperty = () => {
  const defaultPropertyMap = { ...props.dartboard.defaultPropertyMap };
  delete defaultPropertyMap[props.propertyDuid];
  dataStore.updateDartboard({
    duid: props.dartboard.duid,
    defaultPropertyMap,
  });
};

// Need to do the special null check because the initial value is always null regardless of the property kind
const isValueUnset = (value: PropertyValue) => value === null || propertyDefaultConfig.value?.isValueUnset(value);

const updateDefaultProperty = (value: PropertyValue) => {
  if (isValueUnset(value)) {
    removeDefaultProperty();
    return;
  }

  const defaultPropertyMap = { ...props.dartboard.defaultPropertyMap, [props.propertyDuid]: value };
  dataStore.updateDartboard({
    duid: props.dartboard.duid,
    defaultPropertyMap,
  });
};

const editorConfig = computed(() => {
  if (!property.value || !propertyDefaultConfig.value) {
    return undefined;
  }

  return {
    component: propertyDefaultConfig.value.component,
    values: propertyDefaultConfig.value.getValues(
      property.value,
      props.value ?? null,
      updateDefaultProperty,
      (value) => {
        if (isValueUnset(value)) {
          removeDefaultProperty();
        }
      }
    ),
  };
});

const open = () => {
  if (editor.value && property.value && !NO_DROPDOWN_PROPERTY_KIND_SET.has(property.value.kind)) {
    editor.value.open();
  }
};

onMounted(() => {
  if (isValueUnset(props.value)) {
    open();
  }
});
</script>

<template>
  <div v-if="property" class="flex w-full justify-between" @keydown.stop>
    <div class="flex items-start truncate pt-1.5 text-sm text-md">
      <div class="flex items-center gap-2">
        <component :is="propertyConfig.icon" class="text-lt icon-sm" />
        <span class="select-none truncate">{{ property.title }}</span>
      </div>
    </div>
    <div class="group/property relative w-48">
      <div
        class="relative flex min-h-8 flex-1 cursor-pointer items-center justify-between gap-1 truncate rounded border border-lt break-words hover:bg-lt"
        @click.stop="open"
        @keydown.enter="open">
        <component
          :is="editorConfig?.component"
          v-bind="editorConfig?.values"
          ref="editor"
          :class="!NO_DROPDOWN_PROPERTY_KIND_SET.has(property.kind) && 'max-w-36'" />
        <ChevronDownIcon
          v-if="!NO_DROPDOWN_PROPERTY_KIND_SET.has(property.kind)"
          class="absolute right-2 top-2 text-vlt icon-sm" />
      </div>

      <Tooltip
        text="Delete default"
        class="absolute -right-2 -top-2 opacity-0 text-vlt group-hover/property:opacity-100"
        @click="removeDefaultProperty">
        <span class="cursor-pointer rounded-full p-0.5 hover:bg-lt">
          <XIcon class="icon-xs" />
        </span>
      </Tooltip>
    </div>
  </div>
</template>
