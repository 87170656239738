<script setup lang="ts">
import { computed, ref } from "vue";

import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { CheckboxFieldIcon, CheckedIcon, UncheckedIcon } from "~/icons";
import { DropdownMenuItemKind, EditorMode, Placement } from "~/shared/enums";
import type { PropertyCheckbox, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeUuid } from "~/utils/common";

type Value = PropertyValueForKind<PropertyCheckbox>;

const props = defineProps<{
  property: PropertyCheckbox;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [checked?: Value];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);
const id = ref(`checkbox-${makeUuid()}`);

const isChecked = computed(() => {
  if (props.editorMode === EditorMode.FORM || props.editorMode === EditorMode.PROPERTY_DEFAULT) {
    return props.value ?? false;
  }

  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === true);
});

const isChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);
const isFormOrDefaultPropertyMode = computed(
  () => isFormMode.value || props.editorMode === EditorMode.PROPERTY_DEFAULT
);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);

const updateValue = (value: Value) => {
  if (isFormOrDefaultPropertyMode.value) {
    emit("update", value);
    return;
  }

  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({
      duid: task.duid,
      ...getPropertyPartialTask(props.property, task, value),
    }))
  );

  emit("update");
};

const dropdownSections = computed(() => {
  const allChecked = isFormOrDefaultPropertyMode.value
    ? props.value
    : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === true);
  const allUnchecked = isFormOrDefaultPropertyMode.value
    ? !props.value
    : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === false);

  return [
    {
      title: props.property.title,
      items: [
        {
          title: "Checked",
          kind: DropdownMenuItemKind.BUTTON,
          disabled: allChecked,
          icon: CheckedIcon,
          onClick: () => updateValue(true),
        },
        {
          title: "Unchecked",
          kind: DropdownMenuItemKind.BUTTON,
          disabled: allUnchecked,
          icon: UncheckedIcon,
          onClick: () => updateValue(false),
        },
      ],
    },
  ];
});

const onToggle = (e: Event) => {
  const { checked } = e.target as HTMLInputElement;
  // Unlike all of the dropdown-based editors, this one updates immediately, so there is a race condition with grid selection that requires us to wait for it
  setTimeout(() => updateValue(checked));
};
</script>

<template>
  <DropdownMenu
    v-if="isContextMenuMode"
    :sections="dropdownSections"
    show-on-hover
    :placement="Placement.RIGHT_TOP"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
    <DropdownMenuItemContent :icon="CheckboxFieldIcon" :title="`Change ${property.title.toLowerCase()}`" is-submenu />
  </DropdownMenu>
  <Tooltip
    v-else
    :disabled="isFormOrDefaultPropertyMode"
    :text="`Change ${property.title.toLowerCase()}`"
    :block="isListMode || isFormOrDefaultPropertyMode"
    :height-block="isListMode || isFormOrDefaultPropertyMode"
    :class="isTaskDetailMode && 'w-full'">
    <div
      class="flex size-full max-w-full items-center rounded text-sm text-md"
      :class="{
        'h-[26px] gap-1 border py-0.5 text-sm border-oncolor hover:bg-opposite/10': isChipMode,
        'px-2 hover:bg-lt': isFormOrDefaultPropertyMode,
        'min-h-7 justify-start rounded px-2 hover:bg-lt': isTaskDetailMode,
        'px-1': !isFormOrDefaultPropertyMode,
        'justify-center': isListMode,
      }">
      <label :for="id" class="sr-only">{{ property.title }}</label>
      <input
        :id="id"
        :class="[isChipMode || isFormOrDefaultPropertyMode ? 'icon-sm' : 'icon-xs']"
        class="cursor-pointer rounded border bg-transparent text-primary-base border-hvy focus-ring-none checked:border-primary-base checked:hover-bg-primary hover:bg-md checked:hover:border-primary-hover-light focus:border-hvy dark:checked:border-primary-base dark:checked:hover:border-primary-hover-dark"
        type="checkbox"
        :checked="isChecked"
        @change="onToggle" />
      <span v-if="isChipMode" class="select-none">
        {{ property.title }}
      </span>
    </div>
  </Tooltip>
</template>
