import moment from "moment";
import { type Component } from "vue";

import { UNASSIGNED_ASSIGNEE_LABEL } from "~/components/visualization/constants";
import { PRIORITY_COLOR_MAP, PRIORITY_OPTIONS, UNPRIORITIZED_PRIORITY_LABEL } from "~/constants/priority";
import {
  AI_PROPERTY_PSUEDO_DUID,
  ATTACHMENTS_PROPERTY_PSUEDO_DUID,
  COMMENTS_PROPERTY_PSUEDO_DUID,
  DOCS_PROPERTY_PSUEDO_DUID,
  LINKS_PROPERTY_PSUEDO_DUID,
  RELATIONSHIPS_PROPERTY_PSUEDO_DUID,
  REMIND_AT_PROPERTY_PSUEDO_DUID,
  SUBSCRIBERS_PROPERTY_PSUEDO_DUID,
} from "~/constants/property";
import { ORDERED_RELATIONSHIP_CONFIGURATION_WITH_SUBTASKS } from "~/constants/relationship";
import {
  EXPONENTIAL_SIZE,
  FIBONACCI_SIZE,
  LINEAR_SIZE,
  TSHIRT_SIZE_TO_NUMBER_SIZE,
  TSHIRT_SIZES,
  UNSIZED_SIZE_LABEL,
} from "~/constants/size";
import { DEFAULT_CHIP_COLOR } from "~/constants/style";
import {
  AiFilterIcon,
  AttachmentFieldIcon,
  CheckedIcon,
  CommentIcon,
  DocIcon,
  DueDateFieldIcon,
  LinkFieldIcon,
  MagicWandIcon,
  PriorityFieldIcon,
  PriorityIcon,
  RelationshipsIcon,
  ReminderIcon,
  StartDateFieldIcon,
  UnassignedIcon,
  UncheckedIcon,
  UserFieldIcon,
} from "~/icons";
import {
  FilterApplicability,
  FilterType,
  IconSize,
  LayoutKind,
  PageKind,
  PropertyKind,
  SizeFormat,
  ViewKind,
} from "~/shared/enums";
import type {
  FilterDefinition,
  FilterOption,
  Page,
  Property,
  PropertyConfig,
  PropertyConfigFilter,
} from "~/shared/types";
import type { useAppStore, usePageStore } from "~/stores";
import type { DataStore } from "~/stores/DataStore";
import { getDescendantDuids, getPageDisplayName, getText } from "~/utils/common";

// Initialize stores
type AppStore = ReturnType<typeof useAppStore>;
type PageStore = ReturnType<typeof usePageStore>;

let appStore: AppStore;
let dataStore: DataStore;
let pageStore: PageStore;

let Avatar: Component;
let PageIcon: Component;
let StatusIcon: Component;

export const init = (newDataStore: DataStore, avatar: Component, pageIcon: Component, statusIcon: Component) => {
  dataStore = newDataStore;
  appStore = dataStore.$useAppStore();
  pageStore = dataStore.$usePageStore();

  Avatar = avatar;
  PageIcon = pageIcon;
  StatusIcon = statusIcon;
};

export const CURRENT_USER_PSEUDO_USER_KEY = "@CurrentUser";
export const TRASH_VIEW_KEY = "@Trash";

const APPLICABILITY_REMAP_LIST = [
  [FilterApplicability.IS, FilterApplicability.INCLUDE],
  [FilterApplicability.IS_NOT, FilterApplicability.DO_NOT_INCLUDE],
  [FilterApplicability.IS_NOT_SET, FilterApplicability.ARE_NOT_SET],
  [FilterApplicability.EXISTS, FilterApplicability.EXIST],
  [FilterApplicability.CONTAINS, FilterApplicability.CONTAIN],
] as [FilterApplicability, FilterApplicability][];
export const APPLICABILITY_REMAP = new Map([
  ...APPLICABILITY_REMAP_LIST,
  ...APPLICABILITY_REMAP_LIST.map(([k, v]) => [v, k] as const),
]);

const ALWAYS_SET_APPLICABILITIES = [FilterApplicability.IS, FilterApplicability.IS_NOT];

const DEFAULT_APPLICABILITIES = [...ALWAYS_SET_APPLICABILITIES, FilterApplicability.IS_NOT_SET];

const DEFAULT_ARRAY_APPLICABILITIES = [
  FilterApplicability.INCLUDE,
  FilterApplicability.DO_NOT_INCLUDE,
  FilterApplicability.ARE_NOT_SET,
];

const DEFAULT_DATE_APPLICABILITIES = [
  ...DEFAULT_APPLICABILITIES,
  FilterApplicability.IS_BEFORE,
  FilterApplicability.IS_AFTER,
  FilterApplicability.IS_BETWEEN,
];

const ALWAYS_SET_DATE_APPLICABILITIES = DEFAULT_DATE_APPLICABILITIES.filter(
  (e) => e !== FilterApplicability.IS_NOT_SET
);

export const NO_VALUE_APPLICABILITIES = new Set([
  FilterApplicability.IS_NOT_SET,
  FilterApplicability.ARE_NOT_SET,
  FilterApplicability.EXISTS,
  FilterApplicability.EXIST,
  FilterApplicability.IS_CHECKED,
  FilterApplicability.IS_UNCHECKED,
]);

export const ONE_VALUE_APPLICABILITIES = new Set([
  FilterApplicability.IS_BEFORE,
  FilterApplicability.IS_AFTER,
  FilterApplicability.CONTAINS,
  FilterApplicability.CONTAIN,
]);

export const TWO_VALUE_APPLICABILITIES = new Set([FilterApplicability.IS_BETWEEN]);

export const FIELDS_ALWAYS_AVAILABLE_SET = new Set([
  PropertyKind.DEFAULT_DARTBOARD,
  PropertyKind.DEFAULT_ASSIGNEES,
  PropertyKind.DEFAULT_STATUS,
]);

const dateFilterFunction = (
  value: string | null,
  filterValue: FilterOption["value"],
  applicability: FilterApplicability | null
) => {
  if (!value || !applicability) {
    return false;
  }

  const dates = Array.isArray(filterValue) ? filterValue : [];
  if (dates.length === 0 || typeof dates[0] !== "string") {
    return false;
  }

  switch (applicability) {
    case FilterApplicability.IS:
    case FilterApplicability.IS_NOT: {
      return moment(value).isSame(moment(dates[0]), "day");
    }
    case FilterApplicability.IS_BEFORE: {
      return moment(value).isBefore(moment(dates[0]), "day");
    }
    case FilterApplicability.IS_AFTER: {
      return moment(value).isAfter(moment(dates[0]), "day");
    }
    case FilterApplicability.IS_BETWEEN: {
      return typeof dates[1] === "string" && moment(value).isBetween(moment(dates[0]), moment(dates[1]), "day", "[]");
    }
    default: {
      return false;
    }
  }
};

export const KIND_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_KIND> = (property) => [
  {
    type: FilterType.VALUE,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: ALWAYS_SET_APPLICABILITIES,
    options: dataStore.taskKindList.map((kind) => ({
      value: kind.duid,
      label: kind.title,
      colorHex: kind.colorHex,
      icon: PageIcon,
      iconArgs: { page: kind, size: IconSize.S },
    })),
    test: (kind, filterValue) => kind === filterValue,
  },
];

export const TITLE_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_TITLE> = () => [
  {
    type: FilterType.INPUT,
    available: true,
    singleton: true,
    applicabilities: [FilterApplicability.CONTAINS],
    options: [],
    test: (title, filterValue) =>
      typeof filterValue === "string" && title.toLowerCase().includes(filterValue.toLowerCase()),
  },
];

export const DESCRIPTION_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_DESCRIPTION> = () => [
  {
    type: FilterType.INPUT,
    available: true,
    singleton: true,
    applicabilities: [FilterApplicability.EXISTS, FilterApplicability.CONTAINS],
    options: [],
    test: (description, filterValue) =>
      typeof filterValue === "string" && getText(description).trim().toLowerCase().includes(filterValue.toLowerCase()),
  },
];

export const DARTBOARD_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_DARTBOARD> = () => {
  const pageIsView = appStore.currentPage?.pageKind === PageKind.VIEW;

  return [
    {
      type: FilterType.VALUE,
      available: pageIsView,
      singleton: true,
      applicabilities: ALWAYS_SET_APPLICABILITIES,
      options: dataStore
        .getDartboardList()
        .map((dartboard) => ({
          value: dartboard.duid,
          label: getPageDisplayName(dartboard, dataStore.getSpaceByDuid),
          colorHex: DEFAULT_CHIP_COLOR,
          icon: PageIcon,
          iconArgs: { page: dartboard as Page, size: IconSize.S },
        }))
        .concat([
          {
            value: TRASH_VIEW_KEY,
            label: "Trash",
            colorHex: DEFAULT_CHIP_COLOR,
            icon: PageIcon,
            iconArgs: { page: dataStore.getViewByKind(ViewKind.TRASH), size: IconSize.S },
          },
        ]),
      test: (dartboard, filterValue, task) =>
        filterValue === TRASH_VIEW_KEY ? task.inTrash : filterValue === dartboard,
    },
  ];
};

export const STATUS_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_STATUS | PropertyKind.STATUS> = (property) => [
  {
    type: FilterType.VALUE,
    available: true,
    singleton: true,
    applicabilities: ALWAYS_SET_APPLICABILITIES,
    options: dataStore.getStatusList(property).map((status) => ({
      value: status.duid,
      label: status.title,
      colorHex: status.colorHex,
      icon: StatusIcon,
      iconArgs: { duid: status.duid, isContrast: true },
    })),
    test: (status, filterValue) => status === filterValue,
  },
];

export const USER_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_ASSIGNEES | PropertyKind.USER> = (property) => {
  const pageIsDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;

  return [
    {
      type: FilterType.VALUE,
      available: appStore.isPropertyShown(property),
      singleton: !property.adtl.isMultiple,
      applicabilities: property.adtl.isMultiple ? DEFAULT_ARRAY_APPLICABILITIES : DEFAULT_APPLICABILITIES,
      options: [
        ...(property.kind === PropertyKind.DEFAULT_ASSIGNEES
          ? [
              {
                value: pageIsDashboard ? "@unassigned" : null,
                label: UNASSIGNED_ASSIGNEE_LABEL,
                colorHex: DEFAULT_CHIP_COLOR,
                icon: UnassignedIcon,
                iconArgs: { class: "text-lt" },
              },
            ]
          : []),
        {
          value: CURRENT_USER_PSEUDO_USER_KEY,
          label: "Current user",
          colorHex: DEFAULT_CHIP_COLOR,
          icon: MagicWandIcon,
          iconArgs: { class: "text-primary-base dark:text-primary-base" },
        },
        ...dataStore.getUserList().map((user) => ({
          value: user.duid,
          label: user.name || user.email,
          adtlSearchTerms: [user.email],
          colorHex: user.colorHex,
          icon: Avatar,
          iconArgs: {
            abrev: user.abrev,
            circle: true,
            colorHex: user.colorHex,
            imageUrl: user.imageUrl,
            imgBorder: true,
            isContrast: pageStore.theme === "light",
          },
        })),
      ],
      test: (users, filterValue) => {
        const valueNorm = property.adtl.isMultiple ? users : users.slice(0, 1);
        return filterValue && typeof filterValue === "string"
          ? valueNorm.includes(filterValue)
          : valueNorm.length === 0;
      },
    },
  ];
};

export const OPTION_FILTER: PropertyConfigFilter<
  PropertyKind.DEFAULT_TAGS | PropertyKind.MULTISELECT | PropertyKind.SELECT
> = (property) => {
  const isSingleton = property.kind === PropertyKind.SELECT;

  return [
    {
      type: FilterType.VALUE,
      available: appStore.isPropertyShown(property),
      singleton: isSingleton,
      applicabilities: isSingleton ? DEFAULT_APPLICABILITIES : DEFAULT_ARRAY_APPLICABILITIES,
      options: dataStore.getOptionList(property).map((option) => ({
        value: option.duid,
        label: option.title,
        colorHex: option.colorHex,
      })),
      test: (options, filterValue) => {
        const option = typeof filterValue === "string" && dataStore.getOptionByDuid(filterValue);
        const descendantDuids = new Set(option ? getDescendantDuids(dataStore.getOptionList(property), option) : []);
        if (Array.isArray(options)) {
          return filterValue && typeof filterValue === "string"
            ? options.includes(filterValue) || options.some((e) => descendantDuids.has(e))
            : options.length === 0;
        }
        return options === filterValue || (typeof filterValue === "string" && descendantDuids.has(filterValue));
      },
    },
  ];
};

export const PRIORITY_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_PRIORITY> = (property) => [
  {
    type: FilterType.VALUE,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: DEFAULT_APPLICABILITIES,
    options: PRIORITY_OPTIONS.map((priority) => {
      const hasPriority = priority !== null;
      return {
        value: priority,
        label: hasPriority ? priority.toString() : UNPRIORITIZED_PRIORITY_LABEL,
        adtlSearchTerms: hasPriority ? [priority.toString()] : [],
        colorHex: DEFAULT_CHIP_COLOR,
        icon: hasPriority ? PriorityIcon : PriorityFieldIcon,
        iconArgs: {
          class: "icon-sm",
          style: hasPriority ? { color: PRIORITY_COLOR_MAP.get(priority) } : {},
        },
      };
    }),
    test: (priority, filterValue) => priority === filterValue,
  },
];

export const DATES_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_DATES | PropertyKind.DATES> = (property) => {
  const isAvailable =
    appStore.isPropertyShown(property) || [LayoutKind.ROADMAP, LayoutKind.CALENDAR].includes(appStore.layoutKind);
  const pageIsDashboard = appStore.currentPage?.pageKind === PageKind.DASHBOARD;

  return [
    /* START DATE */
    {
      type: FilterType.DATE,
      available: isAvailable && property.adtl.isRange,
      singleton: true,
      applicabilities: DEFAULT_DATE_APPLICABILITIES,
      options: [],
      test: (value, filterValue, _, applicability) => dateFilterFunction(value[0], filterValue, applicability),
      subId: "@start",
      override: { title: (originalTitle) => `${originalTitle} start`, icon: () => StartDateFieldIcon },
    },
    /* END DATE */
    {
      type: FilterType.DATE,
      available: isAvailable,
      singleton: true,
      applicabilities: DEFAULT_DATE_APPLICABILITIES,
      options: [],
      test: (value, filterValue, _, applicability) => dateFilterFunction(value[1], filterValue, applicability),
      subId: "@end",
      override: {
        title: (originalTitle) =>
          pageIsDashboard ? "Date" : property.adtl.isRange ? `${originalTitle} end` : originalTitle,
        icon: () => DueDateFieldIcon,
      },
    },
  ];
};

export const DATE_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_CREATED_AT | PropertyKind.DEFAULT_UPDATED_AT> = (
  property
) => [
  {
    type: FilterType.DATE,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: ALWAYS_SET_DATE_APPLICABILITIES,
    options: [],
    test: (value, filterValue, _, applicability) => dateFilterFunction(value, filterValue, applicability),
  },
];

export const SIZE_FILTER: PropertyConfigFilter<PropertyKind.DEFAULT_SIZE> = (property) => {
  const getSizeOptions = (sizes: number[]) =>
    sizes.map((size) => ({
      value: size,
      label: size?.toString() ?? UNSIZED_SIZE_LABEL,
      adtlSearchTerms: [size?.toString()],
      colorHex: DEFAULT_CHIP_COLOR,
    }));

  const unsizedOption: FilterOption = {
    value: null,
    label: UNSIZED_SIZE_LABEL,
    adtlSearchTerms: [UNSIZED_SIZE_LABEL],
    colorHex: DEFAULT_CHIP_COLOR,
  };

  let options: FilterOption[] = [];
  switch (property.adtl.format) {
    case SizeFormat.LINEAR: {
      options = [unsizedOption, ...getSizeOptions(LINEAR_SIZE)];
      break;
    }
    case SizeFormat.FIBONACCI: {
      options = [unsizedOption, ...getSizeOptions(FIBONACCI_SIZE)];
      break;
    }
    case SizeFormat.EXPONENTIAL: {
      options = [unsizedOption, ...getSizeOptions(EXPONENTIAL_SIZE)];
      break;
    }
    default: {
      options = TSHIRT_SIZES.map((size) => {
        const value = TSHIRT_SIZE_TO_NUMBER_SIZE.get(size) as number;
        return {
          value,
          label: size?.toString() ?? UNSIZED_SIZE_LABEL,
          adtlSearchTerms: [value?.toString()],
          colorHex: DEFAULT_CHIP_COLOR,
        };
      });
    }
  }

  return [
    {
      type: property.adtl.format === SizeFormat.FREE_ENTRY ? FilterType.NUMBER : FilterType.VALUE,
      available: appStore.isPropertyShown(property),
      singleton: true,
      applicabilities: DEFAULT_APPLICABILITIES,
      options,
      test: (size, filterValue) => (filterValue ? size === parseInt(filterValue.toString(), 10) : size === null),
    },
  ];
};

export const TIME_TRACKING_FILTER: PropertyConfigFilter<
  PropertyKind.DEFAULT_TIME_TRACKING | PropertyKind.TIME_TRACKING
> = (property) => [
  {
    type: FilterType.DATE,
    available: !property, // TODO time tracking switch back to appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: ALWAYS_SET_APPLICABILITIES,
    options: [],
    test: () => true,
  },
];

export const TEXT_FILTER: PropertyConfigFilter<PropertyKind.TEXT> = (property) => [
  {
    type: FilterType.INPUT,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: [FilterApplicability.EXIST, FilterApplicability.CONTAINS],
    options: [],
    test: (value, filterValue) =>
      typeof filterValue === "string" && value.toLowerCase().includes(filterValue.toLowerCase()),
  },
];

export const NUMBER_FILTER: PropertyConfigFilter<PropertyKind.NUMBER> = (property) => [
  {
    type: FilterType.NUMBER,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: DEFAULT_APPLICABILITIES,
    options: [],
    test: (value, filterValue) => (value as number) === (filterValue as number),
  },
];

export const CHECKBOX_FILTER: PropertyConfigFilter<PropertyKind.CHECKBOX> = (property) => [
  {
    type: FilterType.VALUE,
    available: appStore.isPropertyShown(property),
    singleton: true,
    applicabilities: [FilterApplicability.IS_CHECKED, FilterApplicability.IS_UNCHECKED],
    options: [
      {
        value: true,
        label: "Checked",
        adtlSearchTerms: ["true"],
        icon: CheckedIcon,
        iconArgs: { class: "text-lt" },
        colorHex: DEFAULT_CHIP_COLOR,
      },
      {
        value: false,
        label: "Unchecked",
        adtlSearchTerms: ["false"],
        icon: UncheckedIcon,
        iconArgs: { class: "text-lt" },
        colorHex: DEFAULT_CHIP_COLOR,
      },
    ],
    test: () => true,
  },
];

export const getFilterDefinitionList = (propertiesWithConfig: [Property, PropertyConfig][]): FilterDefinition[] => {
  const options: FilterDefinition[] = propertiesWithConfig
    .filter(([, config]) => config.filter !== null)
    .map(([property, config]) =>
      config.filter!(property).map(
        (e) =>
          ({
            propertyDuid: `${property.duid}${e.subId ? `/${e.subId}` : ""}`,
            title: e.override?.title ? e.override.title(property.title) : property.title,
            icon: e.override?.icon ? e.override.icon() : config.icon,
            config: e,
            getValue: (task) => config.getValue(property, task),
          }) satisfies FilterDefinition
      )
    )
    .flat();

  /* AI */
  options.unshift({
    propertyDuid: AI_PROPERTY_PSUEDO_DUID,
    title: "AI filter",
    icon: AiFilterIcon,
    config: {
      type: FilterType.AI,
      available: pageStore.isOnline,
      singleton: true,
      applicabilities: [],
      options: [],
      test: () => true,
    },
    getValue: () => null,
  } satisfies FilterDefinition);

  /* Relationships */
  options.push({
    propertyDuid: RELATIONSHIPS_PROPERTY_PSUEDO_DUID,
    title: "Relationships",
    icon: RelationshipsIcon,
    config: {
      type: FilterType.VALUE,
      available: true,
      singleton: false,
      applicabilities: [FilterApplicability.EXIST, FilterApplicability.INCLUDE, FilterApplicability.DO_NOT_INCLUDE],
      options: Object.values(ORDERED_RELATIONSHIP_CONFIGURATION_WITH_SUBTASKS).map((relationship) => ({
        value: JSON.stringify([dataStore.getRelationshipKindByKind(relationship.kind).duid, relationship.isForward]),
        label: relationship.label,
        colorHex: DEFAULT_CHIP_COLOR,
        icon: relationship.icon,
        iconArgs: { class: "icon-sm text-vlt" },
      })),
      test: (_, filterValue, task) => {
        if (typeof filterValue !== "string") {
          return false;
        }
        const [kindDuid, isForward] = JSON.parse(filterValue);
        return task.relationships.some((e) => e.kindDuid === kindDuid && e.isForward === isForward);
      },
    },
    getValue: () => null,
  } satisfies FilterDefinition);

  /* Docs */
  options.push({
    propertyDuid: DOCS_PROPERTY_PSUEDO_DUID,
    title: "Docs",
    icon: DocIcon,
    config: {
      type: FilterType.VALUE,
      available: true,
      singleton: true,
      applicabilities: [FilterApplicability.EXIST],
      options: [],
      test: (_, __, task) => dataStore.getDocsRelatedToTaskOrdered(task.duid).length !== 0,
    },
    getValue: () => null,
  } satisfies FilterDefinition);

  /* Comments */
  options.push({
    propertyDuid: COMMENTS_PROPERTY_PSUEDO_DUID,
    title: "Comments",
    icon: CommentIcon,
    config: {
      type: FilterType.INPUT,
      available: true,
      singleton: true,
      applicabilities: [FilterApplicability.EXIST, FilterApplicability.CONTAIN],
      options: [],
      test: (_, filterValue, task) => {
        const commentTexts = dataStore
          .getCommentsByTaskDuid(task.duid)
          .map((comment) => getText(comment.text).trim().toLowerCase());
        if (commentTexts.length === 0) {
          return false;
        }
        return typeof filterValue === "string" && commentTexts.some((e) => e.includes(filterValue.toLowerCase()));
      },
    },
    getValue: () => null,
  } satisfies FilterDefinition);

  /* Links */
  options.push({
    propertyDuid: LINKS_PROPERTY_PSUEDO_DUID,
    title: "Links",
    icon: LinkFieldIcon,
    config: {
      type: FilterType.VALUE,
      available: true,
      singleton: true,
      applicabilities: [FilterApplicability.EXIST],
      options: [],
      test: (_, __, task) => task.links.length !== 0,
    },
    getValue: () => null,
  } satisfies FilterDefinition);

  /* Attachments */
  options.push({
    propertyDuid: ATTACHMENTS_PROPERTY_PSUEDO_DUID,
    title: "Attachments",
    icon: AttachmentFieldIcon,
    config: {
      type: FilterType.VALUE,
      available: true,
      singleton: true,
      applicabilities: [FilterApplicability.EXIST],
      options: [],
      test: (_, __, task) => task.attachmentDuids.length !== 0,
    },
    getValue: (task) => task.attachmentDuids,
  } satisfies FilterDefinition);

  /* Subscribers */
  options.push({
    propertyDuid: SUBSCRIBERS_PROPERTY_PSUEDO_DUID,
    title: "Subscribers",
    icon: UserFieldIcon,
    config: {
      type: FilterType.VALUE,
      available: true,
      singleton: false,
      applicabilities: DEFAULT_ARRAY_APPLICABILITIES,
      options: [
        {
          value: CURRENT_USER_PSEUDO_USER_KEY,
          label: "Current user",
          colorHex: DEFAULT_CHIP_COLOR,
          icon: MagicWandIcon,
          iconArgs: { class: "text-primary-base dark:text-primary-base" },
        },
        ...dataStore.getUserList().map((user) => ({
          value: user.duid,
          label: user.name || user.email,
          adtlSearchTerms: [user.email],
          colorHex: user.colorHex,
          icon: Avatar,
          iconArgs: {
            abrev: user.abrev,
            circle: true,
            colorHex: user.colorHex,
            imageUrl: user.imageUrl,
            imgBorder: true,
          },
        })),
      ],
      test: (_, filterValue, task) =>
        filterValue && typeof filterValue === "string"
          ? task.subscriberDuids.includes(filterValue)
          : task.subscriberDuids.length === 0,
    },
    getValue: (task) => task.subscriberDuids,
  } satisfies FilterDefinition);

  /* Reminder */
  options.push({
    propertyDuid: REMIND_AT_PROPERTY_PSUEDO_DUID,
    title: "Reminder",
    icon: ReminderIcon,
    config: {
      type: FilterType.DATE,
      available: true,
      singleton: true,
      applicabilities: DEFAULT_DATE_APPLICABILITIES,
      options: [],
      test: (_, filterValue, task, applicability) => dateFilterFunction(task.remindAt, filterValue, applicability),
    },
    getValue: (task) => task.remindAt,
  } satisfies FilterDefinition);

  return options;
};
