<script setup lang="ts">
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_LOW, PASTE_COMMAND } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";

const emit = defineEmits<{
  pasteMultilineText: [pastedText: string];
}>();

const editor = useLexicalComposer();

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        if (!event.clipboardData?.types.includes("text/plain")) {
          return false;
        }

        const pastedText = event.clipboardData.getData("text");
        if (!pastedText.includes("\n")) {
          return false;
        }

        emit("pasteMultilineText", pastedText);
        return true;
      },
      COMMAND_PRIORITY_LOW
    )
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});
</script>

<template>
  <slot />
</template>
