<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { CheckIcon, ChevronDownIcon } from "~/icons";
import { DropdownMenuItemKind } from "~/shared/enums";

const props = defineProps<{
  options: { title: string; subtitle?: string; selected: boolean; onClick: () => void }[];
  noChevron?: boolean;
  border?: boolean;
  block?: boolean;
  widthPixels?: number;
}>();

const dropdownSections = computed(() => [
  {
    title: "Selections",
    items: props.options.map(({ title, subtitle, selected, onClick }) => ({
      title,
      subtitle,
      kind: DropdownMenuItemKind.BUTTON,
      icon: CheckIcon,
      iconArgs: {
        class: `icon-sm text-lt${selected ? "" : " invisible"}`,
      },
      onClick,
    })),
  },
]);
</script>

<template>
  <DropdownMenu :sections="dropdownSections" :block="block" :distance="2" :width-pixels="widthPixels">
    <div class="flex size-full items-center rounded bg-std text-md hover:bg-lt" :class="border && 'border border-md'">
      <slot />
      <ChevronDownIcon v-if="!noChevron" class="mx-1 text-lt icon-sm print:hidden" />
    </div>
  </DropdownMenu>
</template>
