<script setup lang="ts">
import { computed } from "vue";

import { getPropertyValueStr } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import TaskStatisticsChip from "~/components/dumb/TaskStatisticsChip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { COLUMN_WIDTH, useStatistics } from "~/components/visualization/board/common";
import HeaderTitle from "~/components/visualization/components/HeaderTitle.vue";
import { CollapseIcon, PlusIcon, TaskCreateIcon } from "~/icons";
import { ButtonSize, ButtonStyle, CommandId, DartboardKind, EditorMode, IconSize, PageKind } from "~/shared/enums";
import type { GroupByGroup } from "~/shared/types";
import { useAppStore, usePageStore } from "~/stores";
import { fromHexToHexWithAlpha } from "~/utils/color";

const COLUMN_WIDTH_STYLE = {
  minWidth: COLUMN_WIDTH,
  maxWidth: COLUMN_WIDTH,
};

const props = defineProps<{
  group: GroupByGroup;
  editorMode: EditorMode;
  isEmpty?: boolean;
}>();

const emit = defineEmits<{
  collapse: [];
  createTask: [];
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);

const modeAlphaMod = computed(() => (pageStore.theme === "light" ? 0 : -0.05));
const backgroundColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.2 + modeAlphaMod.value));
const hoverBgColor = computed(() => fromHexToHexWithAlpha(props.group.colorHex, 0.3 + modeAlphaMod.value));

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const hideEmptyGroup = computed(
  () =>
    appStore.hideEmptyGroups &&
    appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value))?.length === 0
);

const statistics = useStatistics(
  () => appStore.groupByValueToTasksMap.get(getPropertyValueStr(props.group.value)) ?? []
);

const collapseColumn = () => {
  const id = isBoardMode.value ? props.group.id : getPropertyValueStr(props.group.value);
  appStore.setGroupCollapsed(id, true);
  emit("collapse");
};

const createTask = () => emit("createTask");
</script>

<template>
  <div
    v-if="!hideEmptyGroup"
    :data-group="group.id"
    class="group/section flex w-full items-center justify-between gap-1"
    :class="{
      'h-[53px] rounded-t-lg px-5': isListMode,
      'p-1': isBoardMode,
      'bg-gradient-to-b from-[--backgroundColor]': isListMode && isEmpty,
      'bg-[--backgroundColor]': isListMode && !isEmpty,
    }"
    :style="{
      '--backgroundColor': backgroundColor,
      '--hoverBgColor': hoverBgColor,
      ...(isBoardMode ? COLUMN_WIDTH_STYLE : {}),
    }">
    <div class="flex h-6 items-center gap-2 truncate" :class="isListMode && 'w-[360px]'">
      <div class="flex items-center gap-2 truncate">
        <component :is="group.icon" v-if="group.icon" class="shrink-0" v-bind="group.iconArgs" />
        <HeaderTitle :title="group.title" />
        <TaskStatisticsChip :statistics="statistics" :tooltip-show-delay="300" on-colored-background />
      </div>

      <Tooltip v-if="isListMode && canAddTasks" :command-id="CommandId.CREATE_TASK">
        <Button
          disable-hover
          :btn-style="ButtonStyle.SECONDARY"
          :size="ButtonSize.CHIP"
          text="New task"
          :icon="TaskCreateIcon"
          borderless
          :icon-size="IconSize.S"
          text-style="text-xs"
          class="hover:bg-opposite/10"
          :class="!isEmpty && 'opacity-0 group-hover/section:opacity-100'"
          @click="createTask" />
      </Tooltip>

      <Tooltip
        v-if="!pageStore.isPublicView"
        :text="`Collapse ${isListMode ? 'section' : 'column'}`"
        class="pointer-events-auto flex justify-end opacity-0 group-hover/section:opacity-100 print:hidden">
        <button
          type="button"
          class="flex items-center justify-center rounded p-0.5 focus-ring-std hover:bg-opposite/10"
          aria-label="Collapse section"
          @click="collapseColumn"
          @keydown.enter="collapseColumn">
          <CollapseIcon class="text-gray-900/30 icon-sm dark:text-white/20" />
        </button>
      </Tooltip>
    </div>
    <Tooltip v-if="canAddTasks && isBoardMode && !isEmpty" :command-id="CommandId.CREATE_TASK">
      <button
        type="button"
        class="flex items-center justify-center rounded p-0.5 opacity-0 focus-ring-std hover:bg-opposite/10 group-hover/section:opacity-100 print:hidden"
        aria-label="Create a task"
        @click="createTask"
        @keydown.enter="createTask">
        <PlusIcon class="text-gray-900/30 icon-sm dark:text-white/20" />
      </button>
    </Tooltip>
  </div>
</template>
