<script setup lang="ts">
import { useEventBus } from "@vueuse/core";
import moment from "moment";
import { DatePicker as DatePickerExternal } from "v-calendar";
import { computed, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import Modal from "~/components/dumb/Modal.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import { CHANGELOG_DESCRIPTION, STANDUP_DESCRIPTION } from "~/constants/report";
import { ArrowRightIcon, CalendarIcon, ChangelogReportIcon, StandupReportIcon, XIcon } from "~/icons";
import { ButtonStyle, IconKind, ModalWidth, ReportKind } from "~/shared/enums";
import type { Doc } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getDocLink, makeDuid } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";
import { getRelativeTimeForDatesDate, isBrowserLocale24h } from "~/utils/time";

import { EVENT_BUS_GENERATE_REPORT } from "./text/const";

const REPORT_KIND_TO_TITLE_MAP: Record<ReportKind, string> = {
  [ReportKind.STANDUP]: "Standup",
  [ReportKind.CHANGELOG]: "Changelog",
};

const DATE_STYLE =
  "flex h-8 flex-1 items-center justify-center text-left overflow-hidden gap-2 rounded border bg-lt border-md pl-2 pr-1 py-1 text-sm text-md";
const UNBOUNDED_DATE = new Date(0);

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const generateReportBus = useEventBus(EVENT_BUS_GENERATE_REPORT);

const datePicker = ref<InstanceType<typeof DatePickerExternal> | null>(null);
const selectedKind = ref<ReportKind | null>(null);

const lastReportDate = computed(() => {
  if (!appStore.spaceInReportCreationModal) {
    return null;
  }
  const reportsFolder = dataStore.reportsFolder(appStore.spaceInReportCreationModal.duid);
  const reports = reportsFolder
    ? dataStore
        .getDocsByFolderDuidOrdered(reportsFolder.duid, { incremental: true })
        .filter((e) => e.reportKind === selectedKind.value)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    : [];
  return reports.length > 0 ? new Date(reports[0].createdAt) : null;
});
const defaultStartDate = computed(() => lastReportDate.value ?? UNBOUNDED_DATE);

const selectedDate = ref<{ start: Date; end: Date }>({
  start: defaultStartDate.value,
  end: new Date(),
});

const startDatePretty = computed(() => {
  const start = moment(selectedDate.value.start);
  if (start.isSame(moment(UNBOUNDED_DATE), "day")) {
    return "All time";
  }
  if (start.isSame(moment(lastReportDate.value), "day")) {
    return "Last report";
  }
  return getRelativeTimeForDatesDate(selectedDate.value.start.toISOString());
});
const endDatePretty = computed(() => getRelativeTimeForDatesDate(selectedDate.value.end.toISOString()));

const canClearStart = computed(
  () => !!selectedDate.value?.start && !moment(selectedDate.value.start).isSame(defaultStartDate.value, "day")
);
const canClearEnd = computed(
  () => !!selectedDate.value?.end && !moment(selectedDate.value.end).isSame(moment(), "day")
);

/* Kind */
const kindOptions = computed(() => [
  {
    title: "Standup",
    description: STANDUP_DESCRIPTION,
    value: ReportKind.STANDUP,
    selected: selectedKind.value === ReportKind.STANDUP,
    icon: StandupReportIcon,
  },
  {
    title: "Changelog",
    description: CHANGELOG_DESCRIPTION,
    value: ReportKind.CHANGELOG,
    selected: selectedKind.value === ReportKind.CHANGELOG,
    icon: ChangelogReportIcon,
  },
]);

watch(
  () => appStore.spaceOpenInReportCreationModalConfig,
  (value) => {
    if (!value) {
      return;
    }
    selectedKind.value = kindOptions.value.find((e) => e.value === value.reportKind)?.value ?? null;
    selectedDate.value = { start: defaultStartDate.value, end: new Date() };
    nextTick(() => {
      datePicker.value?.move(new Date());
    });
  }
);

const createDisabled = computed(() => !selectedDate.value || !selectedKind.value);

const onClose = () => {
  appStore.setSpaceOpenInReportCreationModal(null);
};

const onChangeKind = (kind: ReportKind) => {
  selectedKind.value = kind;
  nextTick(() => {
    selectedDate.value = { ...selectedDate.value, start: defaultStartDate.value };
  });
};

const onStartClear = () => {
  selectedDate.value = { ...selectedDate.value, start: defaultStartDate.value };
};

const onEndClear = () => {
  selectedDate.value = { ...selectedDate.value, end: new Date() };
};

const onCreate = async () => {
  if (!selectedKind.value) {
    return;
  }
  const spaceDuid = appStore.spaceInReportCreationModal?.duid;
  if (!spaceDuid) {
    return;
  }

  /* Create doc and open it */
  const startDate = selectedDate.value.start;
  const endDate = selectedDate.value.end;

  const startDateFull = moment(startDate).isSame(moment(UNBOUNDED_DATE), "day")
    ? "All time"
    : moment(startDate).format("MMM D, YYYY");
  const datesFull = moment(startDate).add(1, "day").isSameOrAfter(moment(endDate), "day")
    ? moment(endDate).format("ddd MMM D, YYYY")
    : `${startDateFull} → ${moment(endDate).format("MMM D, YYYY")}`;
  const title = `${REPORT_KIND_TO_TITLE_MAP[selectedKind.value]}: ${datesFull}`;

  const reportsFolder = dataStore.reportsFolder(spaceDuid);
  const docs = dataStore.getDocsByFolderDuidOrdered(reportsFolder.duid, { incremental: true });

  const docDuid = makeDuid();
  const topDocOrder = docs[0]?.order ?? "";

  /* Open doc before backend */
  nextTick(() => {
    router.push(getDocLink({ folderDuid: reportsFolder.duid, duid: docDuid, title: "" } as Doc));
  });

  onClose();
  await dataStore.createDoc(
    title,
    reportsFolder.duid,
    getOrdersBetween(undefined, topDocOrder)[0],
    { duid: docDuid, reportKind: selectedKind.value, iconNameOrEmoji: "📊", iconKind: IconKind.EMOJI },
    { awaitBackend: true }
  );

  /* Generate report and close */
  generateReportBus.emit({
    docDuid,
    reportKind: selectedKind.value,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
};
</script>

<template>
  <Modal
    :entity="appStore.spaceInReportCreationModal"
    :title="(space) => `Generate a report${space ? ` about ${space.title}` : ''}`"
    :width="ModalWidth.M"
    description="Use AI to create a report that summarizes updates in the space"
    @close="onClose">
    <template #default>
      <div class="mt-4 flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <div class="font-medium text-md">Type</div>
          <RadioCardGroup :items="kindOptions" @select="onChangeKind" />
        </div>

        <div class="flex flex-col gap-2">
          <div class="font-medium text-md">Date range</div>
          <div class="mb-1 flex items-center gap-2">
            <div :class="DATE_STYLE">
              <CalendarIcon class="text-primary-base icon-sm" />
              <span class="flex-1 truncate">{{ startDatePretty }}</span>
              <div
                v-if="canClearStart"
                class="cursor-pointer rounded p-0.5 hover:bg-hvy/80"
                @click="onStartClear"
                @keydown.enter="onStartClear">
                <XIcon class="text-lt icon-xs" />
              </div>
            </div>
            <ArrowRightIcon class="size-3 shrink-0 text-lt" />
            <div :class="DATE_STYLE">
              <CalendarIcon class="text-primary-base icon-sm" />
              <span class="flex-1 truncate">{{ endDatePretty }}</span>
              <div
                v-if="canClearEnd"
                class="cursor-pointer rounded p-0.5 hover:bg-hvy/80"
                @click="onEndClear"
                @keydown.enter="onEndClear">
                <XIcon class="text-lt icon-xs" />
              </div>
            </div>
          </div>
          <DatePickerExternal
            ref="datePicker"
            v-model="selectedDate"
            mode="date"
            color="indigo"
            expanded
            :attributes="[{ key: 'today', highlight: { color: 'indigo', fillMode: 'outline' }, dates: new Date() }]"
            is-range
            :is-dark="pageStore.theme === 'dark'"
            :max-date="new Date()"
            :is24hr="isBrowserLocale24h()"
            :first-day-of-week="(userStore.firstDayOfWeek ?? 1) + 1"
            :style="{ fontFamily: 'Inter var' }" />
        </div>
      </div>
    </template>

    <template #actions>
      <div class="flex gap-2">
        <Button :btn-style="ButtonStyle.SECONDARY" text="Cancel" is-contrast @click="onClose" />
        <Button
          :btn-style="ButtonStyle.RECOMMENDATION"
          text="Generate report"
          is-contrast
          :disabled="createDisabled"
          @click="onCreate" />
      </div>
    </template>
  </Modal>
</template>
