import { PropertyKind, UserRole } from "~/shared/enums";
import type { Dartboard, Task, User, UserUpdate } from "~/shared/types";
import { unionOfAllSets } from "~/utils/common";
import { userComparator } from "~/utils/comparator";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

const SPECIALIST_ROLES = new Set([UserRole.TECHNICAL_ADMIN, UserRole.FINANCIAL_ADMIN]);

export type Getters = {
  getUserList: (options?: { includeSpecialists?: boolean }) => User[];
  getUserByDuid: (duid: string) => User | undefined;
  getUsersByDuidsOrdered: (duids: string[]) => User[];
  getUserByEmail: (email: string) => User | undefined;
  getRelevantUsersByTasks: (tasks: Task[]) => User[];
  getRelevantUsersByDartboards: (dartboards: Dartboard[]) => User[];
};

export type Actions = {
  updateUser: (update: UserUpdate, options?: { awaitBackend?: boolean }) => Promise<User | undefined>;
  removeUser: (user: User, options?: { awaitBackend?: boolean }) => Promise<void>;
  $sortUsers: () => void;
  $setUsers: (users: User[]) => void;
  $createOrUpdateUser: (user: User) => void;
  $deleteUser: (user: User) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  getUserList() {
    return (options = {}) => {
      const { includeSpecialists = false } = options;
      return [...this._duidsToUsers.values()].filter((e) => includeSpecialists || !SPECIALIST_ROLES.has(e.role));
    };
  },
  getUserByDuid() {
    return (duid) => this._duidsToUsers.get(duid);
  },
  getUsersByDuidsOrdered() {
    return (duids) => this.getUserList().filter((e) => duids.includes(e.duid));
  },
  getUserByEmail() {
    return (email) => this.getUserList().find((m) => m.email === email);
  },
  getRelevantUsersByTasks() {
    return (tasks) =>
      this.getRelevantUsersByDartboards(
        this.getDartboardsByDuidsOrdered([...new Set(tasks.map((e) => e.dartboardDuid))])
      );
  },
  getRelevantUsersByDartboards() {
    return (dartboards) => {
      const spaceDuids = [...new Set(dartboards.map((e) => e.spaceDuid))];
      const spaces = this.getSpacesByDuidsOrdered(spaceDuids);
      return spaces.some((e) => e.accessibleByTeam)
        ? this.getUserList()
        : this.getUsersByDuidsOrdered([
            ...unionOfAllSets(spaces.map((e) => e.accessibleByUserDuids).map((e) => new Set(e))),
          ]);
    };
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async updateUser(update, options = {}) {
    const { awaitBackend = false } = options;
    const user = this.getUserByDuid(update.duid);
    if (!user) {
      return undefined;
    }

    Object.assign(user, update);
    this.$sortUsers();

    const backendAction = this.$backend.user.update(update);
    if (awaitBackend) {
      await backendAction;
    }
    return user;
  },
  async removeUser(user, options = {}) {
    const { awaitBackend = false } = options;

    const userPropertyDuids = this.getPropertyByKind(PropertyKind.USER)
      .map((e) => e.duid)
      .concat([this.defaultAssigneesProperty.duid, this.defaultUpdatedByProperty.duid]);
    this.$updateLayoutsRemoveEntityDuidOrFiltersNoBackend([user.duid], userPropertyDuids, { includeBackend: true });
    this.$deleteUser(user);

    const backendAction = this.$backendOld.workspace.removeUsers([user.duid]);
    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortUsers() {
    this._duidsToUsers = new Map([...this._duidsToUsers].sort((a, b) => userComparator(a[1], b[1])));
  },
  $setUsers(users) {
    this._duidsToUsers = new Map(users.map((e) => [e.duid, e]));
    this.$sortUsers();
  },
  $createOrUpdateUser(user) {
    const userStore = this.$useUserStore();

    const currentUser = this.getUserByDuid(user.duid);

    if (!currentUser) {
      this._duidsToUsers.set(user.duid, user);
    } else {
      Object.assign(currentUser, user);
    }

    if (user.duid === userStore.duid) {
      userStore.setUser(user);
    }

    this.$sortUsers();
  },
  $deleteUser(user) {
    const userStore = this.$useUserStore();

    if (user.duid === userStore.duid) {
      userStore.forceLogout();
      return;
    }

    this._duidsToUsers.delete(user.duid);
  },
};

export { actions, getters };
