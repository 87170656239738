import { PomodoroBackgroundKind } from "~/shared/enums";
import type { PomodoroBackground } from "~/shared/types";

const colorHexes = ["#C07373", "#3B527D", "#793C4E", "#306151", "#8376C5", "#1E1E21"];

const gradientFiles = [
  "gradient_1.svg",
  "gradient_2.svg",
  "gradient_3.svg",
  "gradient_4.svg",
  "gradient_5.svg",
  "gradient_6.svg",
];

const imageFiles = ["1.jpeg", "2.jpeg", "3.jpeg", "4.jpg", "5.jpg", "6.jpeg", "7.jpg", "8.jpg"];

export const COLOR_BACKGROUNDS: PomodoroBackground[] = colorHexes.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.COLOR,
  fileNameOrColorHex,
}));

export const GRADIENT_BACKGROUNDS: PomodoroBackground[] = gradientFiles.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.GRADIENT,
  fileNameOrColorHex,
}));

export const IMAGE_BACKGROUNDS: PomodoroBackground[] = imageFiles.map((fileNameOrColorHex) => ({
  kind: PomodoroBackgroundKind.IMAGE,
  fileNameOrColorHex,
}));
