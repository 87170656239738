<script setup lang="ts">
import { useEnvironmentStore } from "~/stores";

defineProps<{
  absolute?: boolean;
}>();

const environmentStore = useEnvironmentStore();
</script>

<template>
  <div class="pointer-events-none flex w-full items-center justify-center" :class="[absolute && 'absolute inset-0']">
    <img
      :src="`${environmentStore.isLocal ? '' : '/static'}/img/loading.webp`"
      alt="Loading"
      class="size-8 animate-spin text-vlt" />
  </div>
</template>
