<!-- eslint-disable vue/define-emits-declaration -->
<script setup lang="ts">
import { useResizeObserver, watchDebounced } from "@vueuse/core";
import { Dropdown, Menu } from "floating-vue";
import { type Component, computed, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";

import Animated from "~/components/dumb/Animated.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import LoadingSpinner from "~/components/dumb/LoadingSpinner.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { AlertIcon, PlusCircleIcon, RecommendWithAiIcon } from "~/icons";
import { Placement, UserRole } from "~/shared/enums";
import type { MultiselectDropdownMenuItem, ValidationFunctionResult } from "~/shared/types";
import { usePageStore, useUserStore } from "~/stores";
import { makeUuid } from "~/utils/common";

const props = defineProps<{
  container?: string;
  disabled?: boolean;
  items: MultiselectDropdownMenuItem[];
  placement?: Placement;
  placeholder?: string;
  newEntryIcon?: Component;
  newEntryIconArgs?: object;
  makeNewEntryText?: (search: string) => string;
  allowMembersToMakeNewEntries?: boolean;
  inline?: boolean;
  block?: boolean;
  heightBlock?: boolean;
  distance?: number;
  skidding?: number;
  cover?: boolean;
  widthPixels?: number;
  maxItems?: number;
  closeOnSelect?: boolean;
  showOnHover?: boolean;
  propagateClickClasses?: string[];
  showRecommendationButton?: boolean;
  infiniteList?: boolean;
  loadingMore?: boolean;
  validate?: (value: string) => ValidationFunctionResult;
  onKeydown?: (event: KeyboardEvent, search: string) => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
}>();

const emit = defineEmits<{
  (e: "add", value: string | boolean | number | null): void;
  (e: "add", value: string): void;
  (e: "create", value: string | boolean | number | null): void;
  (e: "create", value: string): void;
  (e: "remove", value: string | boolean | number | null): void;
  (e: "remove", value: string): void;
  (e: "replace", value: string | boolean | number | null): void;
  (e: "replace", value: string): void;
  (e: "recommend"): void;
  (e: "search", value: string): void;
}>();

const currentInstance = getCurrentInstance();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const menu = ref<{ $el: HTMLDivElement } | null>(null);
const search = ref("");
const searchInput = ref<HTMLInputElement | null>(null);
const scrollContainer = computed<HTMLDivElement | null>(() => menu.value && menu.value.$el);

const itemRefs = new Map();
let indexOfFocusedFilteredItem = -1;

const newEntryValidationResult = computed(() => props.validate?.(search.value));
const newEntryIsValid = computed(() => newEntryValidationResult.value?.isValid);
const newEntryError = computed(() =>
  newEntryValidationResult.value && !newEntryValidationResult.value.isValid
    ? newEntryValidationResult.value.error
    : undefined
);

const createItemItem = ref<HTMLDivElement | null>(null);

const placementDefinite = computed(() => props.placement ?? Placement.BOTTOM_LEFT);
const defaultDistance = computed(() => props.distance ?? (props.cover ? 0 : -8));
const calcDistance = ref(defaultDistance.value);
const calcWidthPx = computed(() => props.widthPixels ?? 224);

const clearSearch = () => {
  search.value = "";
};

const selectedItems = computed(() => props.items.filter((item) => item.selected));
const filteredItems = computed(() => {
  const searchLower = search.value.toLowerCase();
  const unselectedItems = props.items.filter((item) => !item.selected || item.alwaysShowInList);
  const allResults = search.value
    ? unselectedItems.filter(
        (item) =>
          item.label.toLowerCase().includes(searchLower) ||
          item.adtlSearchTerms?.some((term) => term && term.toLowerCase().includes(searchLower))
      )
    : unselectedItems;
  return allResults.slice(0, Number(props.maxItems ?? allResults.length)).map((item, index) => ({ ...item, index }));
});

const allItemsSelected = computed(() => props.items.every((item) => item.selected));

const newEntryAllowed = computed(() =>
  userStore.isRoleGreaterOrEqual(props.allowMembersToMakeNewEntries ? UserRole.MEMBER : UserRole.ADMIN)
);

const assignFilteredItemRef = (index: number, elem: { $el: HTMLDivElement } | null) => {
  if (!elem || !elem.$el) {
    return;
  }

  itemRefs.set(index, elem.$el);
};

const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
const wrapperRef = ref<HTMLDivElement | null>(null);
const recalculateDistance = () => {
  calcDistance.value = defaultDistance.value;
  if (!wrapperRef.value || !props.cover) {
    return;
  }
  calcDistance.value -= wrapperRef.value.getBoundingClientRect().height;
};
const assignWrapperRef = (elem: HTMLDivElement | null) => {
  wrapperRef.value = elem;
  recalculateDistance();
};
useResizeObserver(wrapperRef, recalculateDistance);
watch(() => props.cover, recalculateDistance);
watch(defaultDistance, recalculateDistance);

const onSelect = (item: MultiselectDropdownMenuItem) => {
  if (item.disabled) {
    return;
  }

  emit("add", item.value);
  clearSearch();
  searchInput.value?.focus();
  indexOfFocusedFilteredItem = -1;
  if (props.closeOnSelect) {
    dropdownRef.value?.hide();
  }
};

const onCreate = () => {
  if (!newEntryIsValid.value) {
    return;
  }

  emit("create", search.value);
  clearSearch();
  searchInput.value?.focus();
  indexOfFocusedFilteredItem = -1;
  if (props.closeOnSelect) {
    dropdownRef.value?.hide();
  }
};

// Emit remove event when user hits backspace with no search value
const onBackspace = (event: KeyboardEvent) => {
  if (search.value !== "") {
    return;
  }

  const lastSelectedItem = selectedItems.value[selectedItems.value.length - 1];
  if (!lastSelectedItem) {
    return;
  }

  emit("remove", lastSelectedItem.value);
  event.stopPropagation();
};

const moveUpOrDown = (isUp: boolean): boolean => {
  const itemsCount = filteredItems.value.length;
  const upperLimit = itemsCount - (newEntryIsValid.value ? 0 : 1);
  if ((isUp && indexOfFocusedFilteredItem === -1) || (!isUp && indexOfFocusedFilteredItem === upperLimit)) {
    return false;
  }
  indexOfFocusedFilteredItem += isUp ? -1 : 1;
  if (indexOfFocusedFilteredItem === -1) {
    searchInput.value?.focus();
  } else if (indexOfFocusedFilteredItem === itemsCount) {
    createItemItem.value?.focus();
  } else {
    itemRefs.get(indexOfFocusedFilteredItem)?.focus();
  }
  return true;
};

const isOpen = ref(false);

const onKeydown = (event: KeyboardEvent) => {
  if (!isOpen.value) {
    return;
  }
  const { key } = event;

  props.onKeydown?.(event, search.value);

  switch (key) {
    case "Shift":
    case "Meta":
    case "Control":
    case "Option":
    case "Alt": {
      break;
    }
    case "Enter": {
      if (filteredItems.value.length >= 1) {
        onSelect(filteredItems.value[0]);
      }
      onCreate();
      break;
    }
    case "Escape": {
      event.preventDefault();
      dropdownRef.value?.hide();
      break;
    }
    case "ArrowUp":
    case "ArrowDown": {
      const success = moveUpOrDown(key === "ArrowUp");
      if (success) {
        event.preventDefault();
      }
      break;
    }
    case "Tab": {
      event.preventDefault();
      moveUpOrDown(event.shiftKey);
      break;
    }
    default: {
      if (document.activeElement?.tagName === "INPUT") {
        break;
      }
      indexOfFocusedFilteredItem = -1;
      searchInput.value?.focus();
      break;
    }
  }
};

watchDebounced(search, (value) => props.infiniteList && emit("search", value), { debounce: 500, maxWait: 1000 });

const onShow = () => {
  isOpen.value = true;
  search.value = "";
  indexOfFocusedFilteredItem = -1;
  props.onAfterOpen?.();

  nextTick(() => searchInput.value?.focus());
};

const onHide = () => {
  isOpen.value = false;
  props.onAfterClose?.();
};

const onMouseDown = (event: MouseEvent) => {
  if (!props.showOnHover) {
    return;
  }
  event.preventDefault();
};

const onClickRecommendationButton = () => {
  emit("recommend");
  dropdownRef.value?.hide();
};

const open = () => dropdownRef.value?.show();

const close = () => dropdownRef.value?.hide();

const closeAndPropagateMaybe = (event: MouseEvent) => {
  close();
  const { clientX, clientY } = event;
  props.propagateClickClasses
    ?.flatMap((e) => Array.from(document.getElementsByClassName(e)))
    .filter((e): e is HTMLElement => e instanceof HTMLElement)
    .forEach((elem) => {
      const { left, top, width, height } = elem.getBoundingClientRect();
      if (!(clientX >= left && clientX <= left + width && clientY >= top && clientY <= top + height)) {
        return;
      }
      elem.click();
    });
};

const id = ref(`multiselect-search-${makeUuid()}`);

onMounted(() => {
  if (!currentInstance) {
    return;
  }
  const instanceElem = currentInstance.subTree.el;
  if (!instanceElem?.children) {
    return;
  }

  assignWrapperRef(instanceElem.children[0] as HTMLDivElement);
});

defineExpose({
  open,
  close,
  isOpen,
  scrollContainer,
});
</script>

<template>
  <Dropdown
    ref="dropdownRef"
    :container="container"
    :disabled="disabled"
    :triggers="showOnHover ? ['hover', 'focus'] : undefined"
    :popper-triggers="showOnHover ? ['hover', 'focus'] : undefined"
    :placement="placementDefinite"
    :distance="calcDistance"
    :skidding="skidding"
    :auto-size="block || undefined"
    no-auto-focus
    :theme="`dropdown-${pageStore.theme}`"
    :class="{
      flex: !inline,
      inline: !!inline,
      'max-w-full grow': block,
      'h-full': heightBlock,
      'cursor-pointer': !disabled,
    }"
    @apply-show="onShow"
    @apply-hide="onHide">
    <template #default>
      <slot />
    </template>
    <template #popper>
      <Menu no-auto-focus @mousedown="onMouseDown">
        <DynamicScroller
          ref="menu"
          key-field="index"
          class="max-h-96 overflow-y-auto rounded-t border app-drag-none bg-lt border-md focus-ring-none"
          :items="filteredItems"
          :min-item-size="1"
          :class="block && 'w-full min-w-[224px]'"
          :style="{
            '--background': colors.borderVlt,
            '--highlight': colors.borderMd,
            ...(block
              ? {}
              : {
                  width: `${calcWidthPx}px`,
                  'max-width': `${calcWidthPx}px`,
                }),
          }"
          @keydown.stop="onKeydown">
          <template #before>
            <Animated
              v-if="selectedItems.length"
              class="flex cursor-text flex-wrap items-center gap-1 px-3 pt-3"
              @click="searchInput?.focus()"
              @keydown.enter="searchInput?.focus()">
              <div v-for="item in selectedItems" :key="item.value?.toString()" class="cursor-default overflow-hidden">
                <component
                  :is="item.component"
                  v-bind="item.componentArgs"
                  :label="item.label"
                  :selected="item.selected"
                  :disabled="item.disabled"
                  is-chip
                  @remove="emit('remove', item.value)" />
              </div>
            </Animated>

            <form class="relative mb-1 flex items-center" autocomplete="off" @submit.prevent>
              <label :for="id" class="sr-only">Search</label>
              <input
                :id="id"
                ref="searchInput"
                v-model="search"
                type="text"
                class="block w-full border-x-0 border-b border-t-0 bg-transparent text-sm text-hvy border-md focus-ring-none placeholder:text-vlt focus-visible:ring-gray-200 focus-visible:border-md dark:focus-visible:ring-zinc-700"
                :class="items.length === selectedItems.length && 'border-none'"
                :placeholder="placeholder"
                @focus="indexOfFocusedFilteredItem = -1"
                @keydown.backspace="onBackspace"
                @keydown.delete="onBackspace" />
              <div class="absolute right-0 flex items-center pr-4" />
            </form>
          </template>

          <template #default="{ item, index, active }">
            <DynamicScrollerItem
              :ref="(elem: never) => assignFilteredItemRef(index, elem)"
              :item="item"
              :active="active"
              :size-dependencies="[]"
              tabindex="0"
              :disabled="item.disabled"
              class="flex cursor-pointer py-1 pl-3 pr-2 focus-ring-none focus:bg-md focus-visible:bg-md"
              :class="item.disabled ? 'cursor-not-allowed' : 'hover:bg-md'"
              @click.prevent="onSelect(item)"
              @keydown.enter.prevent.stop="onSelect(item)">
              <component
                :is="item.disabled && item.disabledReason ? Tooltip : Template"
                :placement="Placement.LEFT"
                :text="item.disabledReason"
                block
                :show-delay="200">
                <div v-if="item.disabled" class="absolute inset-0" />
                <component
                  :is="item.component"
                  v-bind="item.componentArgs"
                  :label="item.label"
                  :selected="item.selected"
                  :disabled="item.disabled"
                  @select="onSelect"
                  @replace="emit('replace', item.value)" />
              </component>
            </DynamicScrollerItem>
          </template>
          <template #after>
            <LoadingSpinner v-if="infiniteList && loadingMore" />
            <Tooltip
              v-if="search.length > 0 && validate && newEntryAllowed"
              ref="createItemItem"
              :disabled="newEntryIsValid"
              :text="newEntryError"
              block
              :placement="Placement.TOP">
              <div
                tabindex="0"
                class="flex w-full select-none items-center gap-2 py-1 pl-3 pr-2 text-sm"
                :class="
                  newEntryIsValid
                    ? 'cursor-pointer text-md focus-ring-none hover:bg-md focus-visible:bg-md'
                    : 'cursor-not-allowed text-vlt'
                "
                @click.prevent="onCreate"
                @keydown.enter.prevent.stop="onCreate">
                <component
                  v-bind="newEntryIconArgs"
                  :is="newEntryIcon ?? PlusCircleIcon"
                  class="text-vlt icon-sm"
                  :class="!newEntryIsValid && 'opacity-50'" />
                {{ makeNewEntryText?.(search) ?? `Create ${search}` }}
              </div>
            </Tooltip>
            <div
              v-else-if="filteredItems.length === 0"
              tabindex="0"
              class="flex cursor-not-allowed select-none items-center gap-2 py-1 pl-3 pr-2 text-sm opacity-50 text-vlt">
              <component
                :is="
                  search.length > 0 || (allItemsSelected && !validate) || !newEntryAllowed ? AlertIcon : PlusCircleIcon
                "
                class="text-vlt icon-md" />
              <span>
                {{
                  (allItemsSelected && !validate) || !newEntryAllowed
                    ? `No${items.length > 0 ? " more" : ""} options`
                    : search.length > 0
                      ? "No results, search again"
                      : "Type to add an option"
                }}
              </span>
            </div>
            <div class="mt-1" />
          </template>
        </DynamicScroller>
        <div
          v-if="showRecommendationButton && pageStore.isOnline"
          class="w-full rounded-b border-x border-b py-1 app-drag-none bg-lt border-md">
          <DropdownMenuItemContent
            class="cursor-pointer select-none text-sm bg-lt text-md hover:bg-md"
            :icon="RecommendWithAiIcon"
            :icon-args="{
              class: 'icon-sm text-recommendation-base dark:text-recommendation-base',
            }"
            title="Fill out with AI"
            @click="onClickRecommendationButton" />
        </div>
        <Teleport v-if="isOpen" to="body">
          <div class="absolute inset-0" @click="closeAndPropagateMaybe" @keydown.escape="close" />
        </Teleport>
      </Menu>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
