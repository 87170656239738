/* eslint-disable no-console */
import { useWindowSize } from "@vueuse/core";
import moment from "moment";
import { defineStore } from "pinia";
import type { IResult as ParsedUserAgent } from "ua-parser-js";
import { UAParser } from "ua-parser-js";

import { Theme } from "~/shared/enums";
import { getCookieValue, getHasTouchCapabilities, isString, makeDuid } from "~/utils/common";
import { HAS_TOUCH_OVERRIDE_KEY, lsGet, lsSet, SHOW_DEBUG_INFO_KEY } from "~/utils/localStorageManager";

const THEMES = ["light", "dark"] as const;
const ANDROID_OSES = new Set(["Android", "Android-x86"]);
const MAC_PLATFORM_NAMES = new Set(["Mac OS", "macOS"]);

const origDebug = console.debug;
const origLog = console.log;
const origWarn = console.warn;
const origError = console.error;

type ConsoleDatum = {
  datetime: Date;
  kind: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any[];
};

type State = {
  testing: boolean;
  csrftoken?: string;
  consoleData: ConsoleDatum[];
  manualConsoleLogging: boolean;
  duid: string;
  adminHidden: boolean;
  showDebugInfo: boolean;
  hasTouch: boolean;
  isMac: boolean; // true if Dart is running on a desktop with MacOS (not iOS). Could be desktop app or browser.
  isWindows: boolean; // true if Dart is running on a desktop with Windows. Could be desktop app or browser.
  isUbuntu: boolean; // true if Dart is running on a desktop with Ubuntu. Could be desktop app or browser.
  isDesktop: boolean; // true if Dart is running on any desktop device. Could be the desktop app or desktop browser.
  isDesktopApp: boolean; // true if Dart is running on any desktop device as a desktop app.
  isIos: boolean; // true if Dart is running on an iPhone or iPad with iOS. Could be the iOS app or mobile browser.
  isAndroid: boolean; // true if Dart is running on a phone etc. with Android. Could be the Android app or mobile browser.
  isMobile: boolean; // true if Dart is running on any mobile device. Could be the iOS app, Android app, or mobile browser.
  isMobileApp: boolean; // true if Dart is running on any mobile device as a mobile app. Could be the iOS app or Android app.
  isNativeApp: boolean; // true if Dart is running on any platform as a native app. Could be desktop app, iOS app, or Android app.
  isChrome: boolean; // true if Dart is running on Chrome.
  isChromium: boolean; // true if Dart is running on Chromium.
  isSafari: boolean; // true if Dart is running on Safari.
  isFirefox: boolean; // true if Dart is running on Firefox.
  isEdge: boolean; // true if Dart is running on Edge.
  isOpera: boolean; // true if Dart is running on Opera.
  version: string; // A semver version if Dart is running as a native app (desktop, iOS, or Android).
  isLoggedIn: boolean;
  isOnline: boolean;
  pageLoaded: boolean;
  onlyLoadedDartboardDuid?: string | null;
  pageTitle: string;
  parsedUserAgent: ParsedUserAgent;
  runTrackingTools: boolean;
  theme: (typeof THEMES)[number];
  userDataLoaded: boolean;
  redirectedToNative: boolean;
  renderedElementsGoal: number;
  renderedElementsCounter: number;
  renderingStartTime: number;
  loadingStartDt: string;
};

const parseVersion = (userAgent: string) => {
  const defaultRes = "0.0.0";
  const uaSplit = userAgent.split(" ");
  const suffix = uaSplit[uaSplit.length - 1];
  if (!suffix.startsWith("Dart/")) {
    return defaultRes;
  }
  const suffixSplit = suffix.split("/");
  if (suffixSplit.length !== 3) {
    return defaultRes;
  }
  return suffixSplit[2];
};

const { width: windowWidth } = useWindowSize();

const usePageStore = defineStore("PageStore", {
  state: (): State => {
    const duid = makeDuid();

    const hasTouchOverride = lsGet(HAS_TOUCH_OVERRIDE_KEY);
    const hasTouch = hasTouchOverride === null ? getHasTouchCapabilities() : hasTouchOverride === "true";
    const parsedUserAgent = UAParser(navigator.userAgent);
    const normPlatformName = parsedUserAgent.os.name ?? "";
    const isMac = MAC_PLATFORM_NAMES.has(normPlatformName);
    const isWindows = normPlatformName === "Windows";
    const isUbuntu = normPlatformName === "Ubuntu";
    const isDesktop = isMac || isWindows || isUbuntu;
    const isDesktopApp = parsedUserAgent.browser.name === "Electron";

    const isIos = normPlatformName === "iOS";
    const isAndroid = ANDROID_OSES.has(normPlatformName);
    const isMobile = isIos || isAndroid;
    const isMobileApp = // TODO might eventually be more reliable to calculate this based on user agent, will need to do that anyway for version
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as unknown as { standalone: boolean }).standalone ||
      document.referrer.includes("android-app://");

    const isNativeApp = isDesktopApp || isMobileApp;

    const isEdge = navigator.userAgent.indexOf("Edg") > -1;
    const isChromium = navigator.userAgent.indexOf("Chromium") > -1;
    const isChrome = !isEdge && !isChromium && navigator.userAgent.indexOf("Chrome") > -1;
    const isSafari = !isChromium && !isChrome && navigator.userAgent.indexOf("Safari") > -1;
    const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;
    const isOpera = navigator.userAgent.indexOf("OPR") > -1 || navigator.userAgent.indexOf("Opera") > -1;

    return {
      testing: false,
      csrftoken: undefined,
      consoleData: [],
      manualConsoleLogging: false,
      duid,
      adminHidden: false,
      showDebugInfo: lsGet(SHOW_DEBUG_INFO_KEY) === "true",
      hasTouch,
      isMac,
      isWindows,
      isUbuntu,
      isDesktop,
      isIos,
      isAndroid,
      isMobile,
      isMobileApp,
      isDesktopApp,
      isNativeApp,
      isChrome,
      isChromium,
      isSafari,
      isFirefox,
      isEdge,
      isOpera,
      version: parseVersion(parsedUserAgent.ua),
      isLoggedIn: false,
      isOnline: navigator.onLine,
      pageLoaded: false,
      onlyLoadedDartboardDuid: null,
      pageTitle: "",
      parsedUserAgent,
      runTrackingTools: true,
      theme: THEMES[0],
      userDataLoaded: false,
      redirectedToNative: false,
      renderedElementsGoal: 0,
      renderedElementsCounter: 0,
      renderingStartTime: 0,
      loadingStartDt: moment().toISOString(),
    } as State;
  },
  getters: {
    isVersionGreaterOrEqual() {
      return (version: string) =>
        version.localeCompare(this.version, undefined, { numeric: true, sensitivity: "base" }) <= 0;
    },
    showAlpha(): boolean {
      const tenantStore = this.$useTenantStore();
      return tenantStore.isDart && !this.adminHidden;
    },
    isPublicView(): boolean {
      return this.$router.currentRoute.value.name === "public_view";
    },
    isPublicForm(): boolean {
      return this.$router.currentRoute.value.name === "public_form";
    },
    isPublic(): boolean {
      return this.isPublicView || this.isPublicForm;
    },
    isDartboardLoaded() {
      return (duid?: string) => this.onlyLoadedDartboardDuid === undefined || duid === this.onlyLoadedDartboardDuid;
    },
    isEverythingLoaded() {
      return () => this.onlyLoadedDartboardDuid === undefined;
    },
    isNarrowScreen() {
      return windowWidth.value < 640;
    },
    agentToDict(state) {
      return {
        userAgent: state.parsedUserAgent.ua,
        hasTouch: state.hasTouch,
        isMac: state.isMac,
        isWindows: state.isWindows,
        isUbuntu: state.isUbuntu,
        isDesktop: state.isDesktop,
        isIos: state.isIos,
        isAndroid: state.isAndroid,
        isMobile: state.isMobile,
        isMobileApp: state.isMobileApp,
        isDesktopApp: state.isDesktopApp,
        isNativeApp: state.isNativeApp,
        isChrome: state.isChrome,
        isChromium: state.isChromium,
        isSafari: state.isSafari,
        isFirefox: state.isFirefox,
        isEdge: state.isEdge,
        isOpera: state.isOpera,
        version: state.version,
      };
    },
  },
  actions: {
    logTime(message: string, start: number) {
      if (!this.showDebugInfo) {
        return;
      }

      const diffMs = (performance.now() - start).toString();
      console.log(`${message.padEnd(60)} ${diffMs.padStart(20)}ms`);
    },
    startCellRendering(goal: number) {
      this.renderedElementsGoal = goal;
      this.renderingStartTime = performance.now();
    },
    incrementCellRenderCount() {
      if (!this.showDebugInfo || this.renderedElementsCounter >= this.renderedElementsGoal) {
        return;
      }

      this.renderedElementsCounter += 1;

      if (this.renderedElementsCounter !== this.renderedElementsGoal) {
        return;
      }
      const diff = performance.now() - this.renderingStartTime;
      console.log(
        `rendered ${this.renderedElementsGoal} cells in ${diff}ms: ${diff / this.renderedElementsGoal}ms/cell`
      );
    },
    setManualConsoleLogging(newManualConsoleLogging: boolean) {
      if (this.manualConsoleLogging === newManualConsoleLogging) {
        return;
      }
      this.manualConsoleLogging = newManualConsoleLogging;

      if (!this.manualConsoleLogging) {
        console.debug = origDebug;
        console.log = origLog;
        console.warn = origWarn;
        console.error = origError;
        return;
      }

      const api = this.$backendOld;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const setupFunc = this.$useEnvironmentStore().isLocal
        ? (type: string, origFunc: (content: any) => void) =>
            (...args: any) => {
              api.log(false, type, args.map((e: any) => (isString(e) ? e : JSON.stringify(e))).join(" "));
              origFunc.apply(console, args);
            }
        : (type: string, origFunc: (content: any) => void) =>
            (...args: any) => {
              this.consoleData.push({
                datetime: new Date(),
                kind: type,
                content: Array.from(args),
              });
              origFunc.apply(console, args);
            };
      /* eslint-enable @typescript-eslint/no-explicit-any */

      console.debug = setupFunc("debug", origDebug);
      console.log = setupFunc("log", origLog);
      console.warn = setupFunc("warn", origWarn);
      console.error = setupFunc("error", origError);
    },
    setShowTiming(newShowDebugInfo: boolean) {
      if (this.showDebugInfo === newShowDebugInfo) {
        return;
      }
      this.showDebugInfo = newShowDebugInfo;
      lsSet(SHOW_DEBUG_INFO_KEY, this.showDebugInfo.toString());
    },
    setIsTouch(newIsTouch: boolean) {
      if (this.hasTouch === newIsTouch) {
        return;
      }
      this.hasTouch = newIsTouch;
      lsSet(HAS_TOUCH_OVERRIDE_KEY, this.hasTouch.toString());
    },
    updateCsrf() {
      const csrftoken = getCookieValue("csrftoken");
      if (csrftoken) {
        this.csrftoken = csrftoken;
      }
    },
    updateTheme(ignoreUser = false, themeOverride = Theme.SYSTEM_DEFAULT) {
      const userStore = this.$useUserStore();
      let darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (!ignoreUser && userStore.theme && userStore.theme !== Theme.SYSTEM_DEFAULT) {
        darkMode = userStore.theme === Theme.DARK;
      }

      if (themeOverride === Theme.SYSTEM_DEFAULT) {
        this.theme = darkMode ? "dark" : "light";
      } else {
        this.theme = themeOverride === Theme.DARK ? "dark" : "light";
      }

      THEMES.forEach((e) => document.documentElement.classList.remove(e));
      document.documentElement.classList.add(this.theme);
      if (window.CommandBar) {
        window.CommandBar.setTheme(this.theme);
      }
    },
  },
});

export default usePageStore;
