<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import { useEnvironmentStore } from "~/stores";
import { isNumber } from "~/utils/common";

const PR_REGEX = /#(\d+)/g;
const GH_REPO = "https://github.com/its-dart/dart";

const environmentStore = useEnvironmentStore();

const commitMessageFrags = computed(() => {
  const message = environmentStore.backendEnvironment?.commitMessage ?? "";

  const res = [];
  let lastIndex = 0;
  let match = PR_REGEX.exec(message);
  while (match !== null) {
    if (match.index > lastIndex) {
      res.push(message.slice(lastIndex, match.index));
    }
    res.push(parseInt(match[1], 10));
    lastIndex = match.index + match[0].length;
    match = PR_REGEX.exec(message);
  }

  if (lastIndex < message.length) {
    res.push(message.slice(lastIndex));
  }
  return res;
});

const started = computed(() =>
  environmentStore.backendEnvironment ? moment(environmentStore.backendEnvironment.startedAt) : moment()
);
const backendAge = ref("");

const updateBackendAge = () => {
  backendAge.value = started.value.fromNow();
};

updateBackendAge();

const updateInterval = setInterval(updateBackendAge, 1000);

onUnmounted(() => clearInterval(updateInterval));
</script>

<template>
  <div v-if="environmentStore.backendEnvironment" class="flex flex-col py-1 pl-3 pr-2">
    <div class="flex flex-col gap-1 py-1">
      <p class="text-xs text-md">Backend</p>
      <p class="text-xs text-lt">
        {{ environmentStore.backendEnvironment.name }}
      </p>
      <p class="text-xs text-lt">{{ started.toISOString() }}</p>
      <p class="text-xs text-lt">{{ backendAge }}</p>
    </div>
    <div class="flex flex-col gap-1 py-1">
      <p class="text-xs text-md">Version</p>
      <a
        v-if="environmentStore.backendEnvironment.prNumber !== null"
        :href="`${GH_REPO}/pull/${environmentStore.backendEnvironment.prNumber}`"
        target="_blank"
        rel="noopener noreferrer"
        class="font-mono text-xs text-lt hover:underline">
        {{ environmentStore.backendEnvironment.prTitle }}
      </a>
      <p class="text-xs text-lt">
        <a
          :href="`${GH_REPO}/tree/${environmentStore.backendEnvironment.branch}`"
          target="_blank"
          rel="noopener noreferrer"
          class="font-mono hover:underline">
          {{ environmentStore.backendEnvironment.branch }}
        </a>
        {{ environmentStore.backendEnvironment.changes ? "(changes)" : "" }}
      </p>
      <a
        v-if="environmentStore.backendEnvironment.hash"
        :href="`${GH_REPO}/commit/${environmentStore.backendEnvironment.hash}`"
        target="_blank"
        rel="noopener noreferrer"
        class="font-mono text-xs text-lt hover:underline">
        {{ environmentStore.backendEnvironment.hash?.substring(0, 7) }}
      </a>
      <p v-if="commitMessageFrags" class="text-xs text-lt">
        <template v-for="(frag, index) in commitMessageFrags" :key="index">
          <!-- prettier-ignore -->
          <a v-if="isNumber(frag)" :href="`${GH_REPO}/pull/${frag}`" target="_blank" rel="noopener noreferrer" class="font-mono hover:underline">#{{ frag }}</a>
          <template v-else>{{ frag }}</template>
        </template>
      </p>
    </div>
  </div>
</template>
