<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import { DartboardKind, PageKind } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

import { useVirtualized } from "./common";
import { TASK_ROW_HEIGHT } from "./constants";
import NewOrSetTaskRow from "./NewOrSetTaskRow.vue";
import OffscreenArrow from "./OffscreenArrow.vue";
import RoadmapTaskCard from "./RoadmapTaskCard.vue";
import type { PreviewRange, RoadmapConfig, RoadmapValues } from "./shared";

const appStore = useAppStore();
const dataStore = useDataStore();

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  scrollContainer: HTMLElement | null;
}>();

const emit = defineEmits<{
  activeChange: [activeDuid: string | null];
  scrollTo: [x: number, y?: number];
  updatePreviewRange: [previewRange: PreviewRange];
}>();

const taskRefs = ref<InstanceType<typeof RoadmapTaskCard>[]>([]);

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

const onChange = (
  task: TaskAbsenteeMaybe,
  index: number,
  startAt: string | null,
  dueAt: string | null,
  ended: boolean
) => {
  const { tasks } = props.roadmapValues;

  const itemAboveDest = index === 0 ? undefined : tasks[index - 1]?.order;
  const itemBelowDest = index < tasks.length - 1 ? tasks[index + 1]?.order : undefined;
  const newOrder = getOrdersBetween(itemAboveDest, itemBelowDest)[0];

  dataStore.updateTasks([{ duid: task.duid, order: newOrder, startAt, dueAt }], { noBackend: !ended });
};

/* Focus new task */
const onTaskCreated = () => {
  nextTick(() => {
    const lastTask = taskRefs.value[taskRefs.value.length - 1];
    lastTask.startEditingTitle();
  });
};

const scrollContainer = computed<HTMLElement | null>(() => props.scrollContainer);
const tasksWithHeight = computed(() => props.roadmapValues.tasks.map((e) => ({ ...e, height: TASK_ROW_HEIGHT })));
const { virtualSize: virtualHeightTasks, virtualList: virtualListTasks } = useVirtualized(
  scrollContainer,
  tasksWithHeight,
  "vertical"
);

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);
</script>

<template>
  <div
    class="absolute inset-0 size-full"
    :style="{
      minHeight: `${virtualHeightTasks}px`,
      maxHeight: `${virtualHeightTasks}px`,
    }"
    @click="deselectAll"
    @keydown.enter="deselectAll">
    <!-- Tasks -->
    <RoadmapTaskCard
      v-for="(task, index) in virtualListTasks"
      :key="task.duid"
      ref="taskRefs"
      :roadmap-config="roadmapConfig"
      :roadmap-values="roadmapValues"
      :task="task"
      :index="index"
      :start="task.start"
      @active-change="(e) => emit('activeChange', e)"
      @change="(i, startAt, dueAt, ended) => onChange(task, i, startAt, dueAt, ended)"
      @update-preview-range="(e) => emit('updatePreviewRange', e)"
      @scroll-to="(x, y) => emit('scrollTo', x, y)" />

    <!-- Offscreen arrows -->
    <div class="pointer-events-none absolute inset-0 flex size-full">
      <OffscreenArrow
        v-for="task in virtualListTasks"
        :key="task.duid"
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        :task="task"
        :start="task.start"
        @scroll-to="(x, y) => emit('scrollTo', x, y)" />
    </div>

    <!-- Add new task -->
    <NewOrSetTaskRow
      v-if="canAddTasks"
      :roadmap-config="roadmapConfig"
      :roadmap-values="roadmapValues"
      :start="roadmapValues.tasks.length * TASK_ROW_HEIGHT"
      @task-created="onTaskCreated"
      @update-preview-range="(e) => emit('updatePreviewRange', e)" />
  </div>
</template>
