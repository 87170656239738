<script setup lang="ts">
import { PomodoroBackgroundKind } from "~/shared/enums";
import type { PomodoroBackground } from "~/shared/types";

defineProps<{
  bg: PomodoroBackground;
  selected?: boolean;
  disableHover?: boolean;
}>();

const emit = defineEmits<{
  select: [PomodoroBackground];
}>();
</script>

<template>
  <div
    class="group/pomodoro relative bg-cover"
    :class="[
      selected && 'outline outline-offset-2 outline-indigo-500',
      disableHover ? 'cursor-default' : 'cursor-pointer',
    ]"
    :style="{
      backgroundImage:
        bg.kind !== PomodoroBackgroundKind.COLOR ? `url(/static/img/pomodoro/${bg.fileNameOrColorHex})` : undefined,
      backgroundColor: bg.kind === PomodoroBackgroundKind.COLOR ? bg.fileNameOrColorHex : undefined,
      outlineWidth: '3px',
    }"
    @click="emit('select', bg)"
    @keydown.enter="emit('select', bg)">
    <div
      v-if="!disableHover"
      class="absolute inset-0 size-full rounded-lg bg-white/20 opacity-0 group-hover/pomodoro:opacity-100" />
    <slot />
  </div>
</template>
